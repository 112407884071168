import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const elbasic = props => {
    const goToAgenda = ()=>{
        props.onSetPre(7)
        props.onChange(0)
    }
    const goToContent = ()=>{
        // props.onSetPre(7)
        // props.onChange(1)
    }

    const goToContent2 = ()=>{
        props.onSetPre(7)
        props.settype1("TOLT010")
        props.onChange(25)
    }

    const goToContent3 = ()=>{
        props.onSetPre(7)
        props.settype1("TOLT020")
        props.onChange(26)
    }

    const goToContent4 = ()=>{
        props.onSetPre(7)
        props.settype1("TOLT030")
        props.onChange(27)
    }

    const goToContent5 = ()=>{
        props.onSetPre(7)
        props.settype1("TOLT040")
        props.onChange(28)
    }

    const goToContent6 = ()=>{
        props.onSetPre(7)
        props.settype1("TOLT100")
        props.onChange(29)
    }

    const goToBack = ()=>{
        props.onSetPre(7)
        props.onChange(2)
        //props.onChange(props.backNumber)
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>TOLT EL 기초논리</Text>
            <TouchableOpacity onPress={goToContent2}><Text style={{ marginTop:10, fontSize:13}}>TOLT 010</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent3}><Text style={{ marginTop:5, fontSize:13}}>TOLT 020</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent4}><Text style={{ marginTop:5, fontSize:13}}>TOLT 030</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent5}><Text style={{ marginTop:5, fontSize:13}}>TOLT 040</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent6}><Text style={{ marginTop:5, fontSize:13}}>TOLT 100</Text></TouchableOpacity>
            
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default elbasic;