import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const UserInfo = props => {
    const goToAgenda = ()=>{
        props.onSetPre(2)
        props.onChange(0)
    }
    // const goToContent = ()=>{
    //     props.onSetPre(2)
    //     props.onChange(1)
    // }

    // const goToContent2 = ()=>{
    //     props.onSetPre(2)
    //     props.onChange(6)
    // }

    const goToTest = ()=>{
        props.onSetPre(2)
        props.onChange(6)
    }

    const goToScore = ()=>{
        props.onSetPre(2)
        props.onChange(13)
    }
    return(
        <View style={styles.container}>
            <View style={styles.top3}>
            
            <Text style={{ marginTop:15,fontSize:20, fontWeight: 'bold'}}>사용자 정보</Text>
            <TouchableOpacity ><Text style={{ marginTop:10, fontSize:20}}>사용자 ID: {props.userID}</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5, fontSize:20}}>사용자 등급: {props.role}</Text></TouchableOpacity>
            <View style={{ marginTop:50,fontSize:20, fontWeight: 'bold'}}></View>
            <TouchableOpacity onPress={goToTest}><Text style={{fontWeight: 'bold', marginTop:5, fontSize:20}}>내 시험 이어서 보기</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToScore}><Text style={{fontWeight: 'bold', marginTop:5, fontSize:20}}>점수 리스트</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToAgenda}><Text style={{fontWeight: 'bold', marginTop:5, fontSize:20}}>처음으로</Text></TouchableOpacity>
            </View>
            
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'80%',
      
      paddingBottom:10
    },
    top3:{
        justifyContent:'center',
        alignItems:'flex-start',
        marginTop:5,
        marginLeft:40,
      },
  })
  
  export default UserInfo;