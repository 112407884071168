import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const medium = props => {
    const goToAgenda = ()=>{
        props.onSetPre(3)
        props.onChange(0)
    }
    const goToContent = ()=>{
        props.onSetPre(3)
        props.onChange(1)
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToAgenda}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>중급 논리</Text>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:10, fontSize:13}}>1 단계</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>2 단계</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>3 단계</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>4 단계</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>5 단계</Text></TouchableOpacity>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
  export default medium;