import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const basic = props => {
    const goToAgenda = ()=>{
        props.onSetPre(2)
        props.onChange(0)
    }
    const goToContent = ()=>{
        // props.onSetPre(2)
        // props.onChange(1)
    }

    const goToContent2 = ()=>{
        props.onSetPre(2)
        props.onChange(6)
    }

    const goToEL = ()=>{
        props.onSetPre(2)
        props.onChange(7)
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToAgenda}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>TOLT 논리 테스트</Text>
            <Text style={{ marginTop:20}}>“TOLT”는 “Test of Logical Thinking”을 줄인 말입니다. 논리로 생각하는 힘을 키우고 그 힘을 재는 시험입니다. TOLT는 여러 영역으로 이뤄졌습니다.</Text>
            <Text>TOLT EL 기초논리: “EL”은 “Elementary Logic”을 줄인 말입니다. 논리의 기본 개념과 추론 규칙 을 훈련하고 기본 사고능력을 검사합니다.</Text>
            <Text>TOLT RC 언어이해: “RC”는 “Logical Reading and Comprehension”을 줄인 말입니다. 독해 곧 텍 스트 정보의 이해 능력을 검사합니다.</Text>
            <Text>TOLT CA 논증비판: “CA”는 “Critical Argumentation”을 줄인 말입니다. 논리와 이해를 바탕으로 논증을 설계하고 비판하는 능력을 검사합니다.</Text>
            <TouchableOpacity onPress={goToEL}><Text style={{ marginTop:10, fontSize:13}}>TOLT EL 기초논리</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>TOLT RC 언어이해</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>TOLT CA 논증비판</Text></TouchableOpacity>
            
            
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
  export default basic;

/*
<TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>RC 언어이해(서비스 예정)</Text></TouchableOpacity>
  */