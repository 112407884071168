import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image, TextInput } from 'react-native';

/*
{
     "Number":1,
     "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
     "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
     "example1":"ㄱ. 이 문장 모임은 네 문장으로 이뤄졌다.",
     "example2":"ㄴ. 이 문장 모임은 추론이다. ",
     "1":"없음",
     "2":"ㄱ",
     "3":"ㄴ",
     "4":"ㄱ,ㄴ",
     "answer":"",
     "correct":"3",
     "note1":
     {
         "":"답을 선택하지 않았어요!!",
         "1":"➀ ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. ‘추론’이 무엇인지 두보계 100 제001절을 읽으며 다시 살펴보세요.",
         "2":"➁ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 안타깝게도 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 두보계 100 제001절을 읽으며 ‘추론’이 무엇인지 다시 살펴보세요.",
         "4":"➃ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다."
     },
     "note2":"ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 이 문장 모임은 세 문장으로 이뤄졌습니다. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 이 문장 모임에는 “따라서”가 들어 있습니다. 이 때문에 이 문장 모임은 추론입니다. “따라서” 뒤에 나오는 문장은 이 문장 모임의 결론입니다. 결론이 있다는 것은 전제가 있음을 말해줍니다. 전제와 결론이 있는 문장 모임은 추론입니다. 정답은 ➂입니다."
   }
*/
const Upload805 = props => {
    const [problem, setproblem] = useState("");
    const [text, settext] = useState("");
    const [example1, setexample1] = useState("");
    const [example2, setexample2] = useState("");
    const [correct, setcorrect] = useState("");
    const [note1_1, setnote1_1] = useState("");
    const [note1_2, setnote1_2] = useState("");
    const [note1_3, setnote1_3] = useState("");
    const [note1_4, setnote1_4] = useState("");
    const [note1_5, setnote1_5] = useState("");
    const [note1_6, setnote1_6] = useState("");
    const [note1_7, setnote1_7] = useState("");
    const [note1_1_detail, setnote1_1_detail] = useState("");
    const [note1_2_detail, setnote1_2_detail] = useState("");
    const [note1_3_detail, setnote1_3_detail] = useState("");
    const [note1_4_detail, setnote1_4_detail] = useState("");
    const [note1_5_detail, setnote1_5_detail] = useState("");
    const [note1_6_detail, setnote1_6_detail] = useState("");
    const [note1_7_detail, setnote1_7_detail] = useState("");
    const [note2, setnote2] = useState("");
    const [one, setone] = useState("");
    const [two, settwo] = useState("");
    const [three, setthree] = useState("");
    const [four, setfour] = useState("");   
    const [five, setfive] = useState("");
    const [six, setsix] = useState(""); 
    const [seven, setseven] = useState(""); 
    const [eight, seteight] = useState("");  

    const prev = ()=>{
        if(props.number == 1){
            return;
        }
        props.setScreen(props.number - 1)
        props.store(props.number, problem, text, example1, example2,correct,note1_1,note1_1_detail,
            note1_2,note1_2_detail,note1_3,note1_3_detail,note1_4,note1_4_detail,note1_5,note1_5_detail,note1_6,note1_6_detail,note1_7,note1_7_detail, note2,one, two, three, four, five, six, seven, eight)
    }

    const s_store = ()=>{
        // if(props.number == 1){
        //     return;
        // }
        // props.setScreen(props.number - 1)
        props.store(props.number, problem, text, example1, example2,correct,note1_1,note1_1_detail,
            note1_2,note1_2_detail,note1_3,note1_3_detail,note1_4,note1_4_detail,note1_5,note1_5_detail,note1_6,note1_6_detail,note1_7,note1_7_detail, note2,one, two, three, four, five, six, seven, eight)
    }

    const next = ()=>{

        //props.onChange(9)
        if(props.number == 5){
            return;
        }
        props.setScreen(props.number + 1)
        props.store(props.number, problem, text, example1, example2,correct,note1_1,note1_1_detail,
            note1_2,note1_2_detail,note1_3,note1_3_detail,note1_4,note1_4_detail,note1_5,note1_5_detail,note1_6,note1_6_detail,note1_7,note1_7_detail, note2,one, two, three, four, five, six, seven, eight)
    }

    return(
        <View style={styles.container}>
            <View style={{flexDirection:'row',width:300,justifyContent: 'space-between'}}>
            <TouchableOpacity onPress={prev}><Text style={{fontSize:16, fontWeight: 'bold'}}>prev</Text></TouchableOpacity>
            <TouchableOpacity onPress={s_store}><Text style={{fontSize:16, fontWeight: 'bold'}}>저장</Text></TouchableOpacity>
            <TouchableOpacity onPress={next}><Text style={{fontSize:16, fontWeight: 'bold'}}>next</Text></TouchableOpacity>
          </View>
           <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>Data Upload</Text>

           <Text style={{ marginTop:10,fontSize:16}}>Number: {props.number}</Text>
           <Text>Problem</Text>
           <TextInput  onChangeText={text => setproblem(text)}
          value={problem} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>Text</Text>
           <TextInput  onChangeText={v => settext(v)}
          value={text} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>Example1</Text>
           <TextInput  onChangeText={v => setexample1(v)}
          value={example1} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>Example2</Text>
           <TextInput  onChangeText={v => setexample2(v)}
          value={example2} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>One</Text>
           <TextInput  onChangeText={v => setone(v)}
          value={one} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           
           <Text>Two</Text>
           <TextInput  onChangeText={v => settwo(v)}
          value={two} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           
           <Text>Three</Text>
           <TextInput  onChangeText={v => setthree(v)}
          value={three} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           
           <Text>Four</Text>
           <TextInput  onChangeText={v => setfour(v)}
          value={four} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           
           <Text>Five</Text>
           <TextInput  onChangeText={v => setfive(v)}
          value={five} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           
           <Text>Six</Text>
           <TextInput  onChangeText={v => setsix(v)}
          value={six} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           
           <Text>Seven</Text>
           <TextInput  onChangeText={v => setseven(v)}
          value={seven} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           
           <Text>Eight</Text>
           <TextInput  onChangeText={v => seteight(v)}
          value={eight} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           

           <Text>correct</Text>
           <TextInput  onChangeText={v => setcorrect(v)}
          value={correct} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>오답 하나</Text>
           <TextInput  onChangeText={v => setnote1_1(v)}
          value={note1_1} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>오답 하나 설명</Text>
           <TextInput  onChangeText={v => setnote1_1_detail(v)}
          value={note1_1_detail} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>오답 둘</Text>
           <TextInput  onChangeText={v => setnote1_2(v)}
          value={note1_2} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>오답 둘 설명</Text>
           <TextInput  onChangeText={v => setnote1_2_detail(v)}
          value={note1_2_detail} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
            <Text>오답 셋</Text>
           <TextInput  onChangeText={v => setnote1_3(v)}
          value={note1_3} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>오답 셋 설명</Text>
           <TextInput  onChangeText={v => setnote1_3_detail(v)}
          value={note1_3_detail} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
            
            <Text>오답 넷</Text>
           <TextInput  onChangeText={v => setnote1_4(v)}
          value={note1_4} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>오답 넷 설명</Text>
           <TextInput  onChangeText={v => setnote1_4_detail(v)}
          value={note1_4_detail} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
            
            <Text>오답 다섯</Text>
           <TextInput  onChangeText={v => setnote1_5(v)}
          value={note1_5} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>오답 다섯 설명</Text>
           <TextInput  onChangeText={v => setnote1_5_detail(v)}
          value={note1_5_detail} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
            
            <Text>오답 여섯</Text>
           <TextInput  onChangeText={v => setnote1_6(v)}
          value={note1_6} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>오답 여섯 설명</Text>
           <TextInput  onChangeText={v => setnote1_6_detail(v)}
          value={note1_6_detail} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
            
            <Text>오답 일곱</Text>
           <TextInput  onChangeText={v => setnote1_7(v)}
          value={note1_7} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>오답 일곱 설명</Text>
           <TextInput  onChangeText={v => setnote1_7_detail(v)}
          value={note1_7_detail} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
            


            <Text>정답 설명</Text>
           <TextInput  onChangeText={v => setnote2(v)}
          value={note2} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
 
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        alignItems: 'center',
        justifyContent: 'center',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
  export default Upload805;