import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const Tolt040data = props => {
    const goToAgenda = ()=>{
        props.onSetPre(7)
        props.onChange(0)
    }
    const goToContent1 = ()=>{
        props.onSetPre(33)
        props.setP_name("Q031 아마도 추론")
        //props.onChange(17)
        props.settype2("Q031")
        props.onChange(8)
    }

    const goToContent2 = ()=>{
        props.onSetPre(33)
        props.setP_name("Q032 믿음직함, 거짓 양성")
        //props.onChange(17)
        props.settype2("Q032")
        props.onChange(8)
    }

    const goToContent3 = ()=>{
        props.onSetPre(33)
        props.setP_name("Q033 과학추론")
        //props.onChange(17)
        props.settype2("Q033")
        props.onChange(8)
    }

    const goToContent4 = ()=>{
        props.onSetPre(33)
        props.setP_name("Q034 가설, 입증, 설명, 인과")
        //props.onChange(17)
        props.settype2("Q034")
        props.onChange(8)
    }

    const goToContent5 = ()=>{
        props.onSetPre(33)
        props.setP_name("Q035 귀납, 믿음, 과학")
        //props.onChange(17)
        props.settype2("Q035")
        props.onChange(8)
    }

    const goToContent6 = ()=>{
        props.onSetPre(33)
        props.setP_name("Q036 논증그림,논증분석")
        //props.onChange(17)
        props.settype2("Q036")
        props.onChange(8)
    }

    const goToContent7 = ()=>{
        props.onSetPre(33)
        props.setP_name("Q037 논증 설계, 보완, 강화, 약화, 오류")
       //props.onChange(17)
       props.settype2("Q037")
       props.onChange(8)
    }

    const goToContent8 = ()=>{
        props.onSetPre(33)
        props.setP_name("Q038 행위와 전략")
        //props.onChange(17)
        props.settype2("Q038")
        props.onChange(8)
    }

    const goToContent9 = ()=>{
        props.onSetPre(33)
        props.setP_name("Q039 역설과 수수께끼")
        //props.onChange(17)
        props.settype2("Q039")
        props.onChange(8)
    }

    const goToContent10 = ()=>{
        props.onSetPre(33)
        props.setP_name("Q040 비판과 전략")
        //props.onChange(17)
        props.settype2("Q040")
        props.onChange(8)
    }

    const goToBack = ()=>{
        props.onSetPre(33)
        props.onChange(16)
        //props.onChange(props.backNumber)
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>TOLT 040(데이터 관리)</Text>
            <Text>TOLT 040은 문장 논리의 비판과 전략을 배우고 배운 바를 점검하는 시험입니다. TOLT 040는 10개의 시험 세트로 이뤄졌습니다.</Text>
            <TouchableOpacity onPress={goToContent1}><Text style={{ marginTop:10, fontSize:13}}>Q031 아마도 추론</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent2}><Text style={{ marginTop:5, fontSize:13}}>Q032 믿음직함, 거짓 양성</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent3}><Text style={{ marginTop:5, fontSize:13}}>Q033 과학추론</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent4}><Text style={{ marginTop:5, fontSize:13}}>Q034 가설, 입증, 설명, 인과</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent5}><Text style={{ marginTop:5, fontSize:13}}>Q035 귀납, 믿음, 과학</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent6}><Text style={{ marginTop:5, fontSize:13}}>Q036 논증그림,논증분석</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent7}><Text style={{ marginTop:5, fontSize:13}}>Q037 논증 설계, 보완, 강화, 약화, 오류</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent8}><Text style={{ marginTop:5, fontSize:13}}>Q038 행위와 전략</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent9}><Text style={{ marginTop:5, fontSize:13}}>Q039 역설과 수수께끼</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent10}><Text style={{ marginTop:5, fontSize:13}}>Q040 비판과 전략</Text></TouchableOpacity>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default Tolt040data;