import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image, TextInput } from 'react-native';

import { getDatabase, ref, set,push,onValue } from "firebase/database";


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP1D3r7nd_Ni5B0U47PtTbWG84wYlHxW0",
  authDomain: "logic-93c13.firebaseapp.com",
  databaseURL: "https://logic-93c13-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logic-93c13",
  storageBucket: "logic-93c13.appspot.com",
  messagingSenderId: "875422042858",
  appId: "1:875422042858:web:59118c540c88cdf989e593",
  measurementId: "G-T3BRD4NLWP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const Modify = props => {
    // const [problem, setproblem] = useState(props.problem);
    // const [text, settext] = useState(props.text);
    // const [example1, setexample1] = useState(props.example1);
    // const [example2, setexample2] = useState(props.example2);
    // const [correct, setcorrect] = useState(props.correct);
    // const [note1_1, setnote1_1] = useState(props.note1_1);
    // const [note1_2, setnote1_2] = useState(props.note1_2);
    // const [note1_3, setnote1_3] = useState(props.note1_3);
    // const [note1_1_detail, setnote1_1_detail] = useState(props.note1_1_detail);
    // const [note1_2_detail, setnote1_2_detail] = useState(props.note1_2_detail);
    // const [note1_3_detail, setnote1_3_detail] = useState(props.note1_3_detail);
    // const [note2, setnote2] = useState(props.note2);

    const [name, setname] = useState(props.Name);
    /*
    {
     "Number":1,
     "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
     "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
     "example1":"ㄱ. 이 문장 모임은 네 문장으로 이뤄졌다.",
     "example2":"ㄴ. 이 문장 모임은 추론이다. ",
     "1":"없음",
     "2":"ㄱ",
     "3":"ㄴ",
     "4":"ㄱ,ㄴ",
     "answer":"",
     "correct":"3",
     "note1":
     {
         "":"답을 선택하지 않았어요!!",
         "1":"➀ ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. ‘추론’이 무엇인지 두보계 100 제001절을 읽으며 다시 살펴보세요.",
         "2":"➁ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 안타깝게도 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 두보계 100 제001절을 읽으며 ‘추론’이 무엇인지 다시 살펴보세요.",
         "4":"➃ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다."
     },
     "note2":"ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 이 문장 모임은 세 문장으로 이뤄졌습니다. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 이 문장 모임에는 “따라서”가 들어 있습니다. 이 때문에 이 문장 모임은 추론입니다. “따라서” 뒤에 나오는 문장은 이 문장 모임의 결론입니다. 결론이 있다는 것은 전제가 있음을 말해줍니다. 전제와 결론이 있는 문장 모임은 추론입니다. 정답은 ➂입니다."
   },
    */
    const s_store = ()=>{
        // const new_problem = {
        //     "Number":props.number,
        //     "problem":problem,
        //     "text":text,
        //     "example1":example1,
        //     "example2":example2,
        //     "1":"없음",
        //     "2":"ㄱ",
        //     "3":"ㄴ",
        //     "4":"ㄱ,ㄴ",
        //     "answer":"-1",
        //     "correct":correct,
        //     "note1":
        //     {
        //         // "-1":"답을 선택하지 않았어요!!",
        //         // note1_1:note1_1_detail,
        //         // note1_2:note1_2_detail,
        //         // note1_3:note1_3_detail
        //     },
        //     "note2":note2
        
        // }

        // let new_test = props.test_all;
        // console.log("note1_1 >",note1_1);
        // console.log("note1_2 >",note1_2);
        // console.log("note1_3 >",note1_3);
        // new_problem["note1"]["-1"] = "답을 선택하지 않았어요!!";
        // new_problem["note1"][note1_1] = note1_1_detail;
        // new_problem["note1"][note1_2] = note1_2_detail;
        // new_problem["note1"][note1_3] = note1_3_detail;
        // console.log("new problem >>",new_problem)

        // new_test[Number(props.number) - 1] = new_problem;

        const db = getDatabase();
        let v = props.id;
        // set(ref(db, 'TOLT_EL1/'+v), {
        //     test: new_test,
        //     name: "test_"+v,
        //     id: v
        // });
        //////////
        let final_test_data = props.test_data.EL;
        //let tmp_list = [];
        for(let i = 0 ; i < final_test_data.length; i++){
          if(final_test_data[i].id == v){
            //tmp_list.push(final_test_data[i])
            final_test_data[i].name=name;
          }
        }
        // let count = final_test_data.length+1;
        // const add_data = {
        //     type1:props.type1,
        //     type2:props.type2,
        //     type3:props.type3,
        //     test: final_test,
        //     name:test_name, 
        //     id:count
        // }
        // final_test_data.push(add_data);
        let tmp_final_data = props.test_data;
        tmp_final_data["EL"] = final_test_data;
        props.settest_data(tmp_final_data);
        set(ref(db, 'DATA'),tmp_final_data);
        ///////////
        //props.setData(new_test);
        props.setName(name);
        props.onChange(10)
        // if(props.number == 1){
        //     return;
        // }
        // props.setScreen(props.number - 1)
        // props.store(props.number, problem, text, example1, example2,correct,note1_1,note1_1_detail,
        //     note1_2,note1_2_detail,note1_3,note1_3_detail,note2)
    }

    const goToBack = ()=>{

        props.onChange(10)
    }

    return(
        <View style={styles.container}>
            <View style={{flexDirection:'row',width:300,justifyContent: 'space-between'}}>
            <TouchableOpacity onPress={goToBack}><Text style={{fontSize:16, fontWeight: 'bold'}}>Back</Text></TouchableOpacity>
            <TouchableOpacity onPress={s_store}><Text style={{fontSize:16, fontWeight: 'bold'}}>저장</Text></TouchableOpacity>
            
          </View>
           <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>Test Name Modify</Text>

           <Text style={{ marginTop:10,fontSize:16}}>Eenter New Test Name</Text>
           
           <TextInput  onChangeText={text => setname(text)}
          value={name} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        alignItems: 'center',
        justifyContent: 'center',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
  export default Modify;