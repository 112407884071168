import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';



const Agenda = props => {
    const goToContent = ()=>{
        props.onSetPre(0)
        props.onChange(1)
    }

    const goToBasic = ()=>{
        props.onSetPre(0)
        props.onChange(2)
    }

    const goToMedium = ()=>{
        props.onSetPre(0)
        props.onChange(3)
    }

    const goToHigh = ()=>{
        props.onSetPre(0)
        props.onChange(4)
    }

    const goToTop = ()=>{
        props.onSetPre(0)
        props.onChange(5)
    }

    const goToDataList = ()=>{
        //props.onSetPre(0)
        //props.onChange(8)
    }

    const goToDataManager = ()=>{
        props.onSetPre(0)
        props.onChange(14)
    }

    const goToLoggout = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)

        props.logout();
    }

    const goToUserInfo = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        props.onChange(12)
        //props.logout();
    }

    const goToTest = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        props.onChange(35)
        //props.logout();
    }

    const goToPretest = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        props.onChange(36)
        //props.logout();
    }

    const goToPQR = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        props.onChange(37)
        //props.logout();
    }

    return(
        <View style={styles.container}>
           <Text style={{ marginTop:0,fontSize:16, fontWeight: 'bold'}}>논리 아카데미</Text>
           
           <TouchableOpacity onPress={goToDataList}><Text style={{ marginTop:10, fontSize:13}}>데이터 관리</Text></TouchableOpacity>
           <TouchableOpacity onPress={goToDataManager}><Text style={{ marginTop:10, fontSize:13}}>데이터 관리(final)</Text></TouchableOpacity>
           
           <TouchableOpacity onPress={goToLoggout}><Text style={{ marginTop:10, fontSize:13}}>로그 아웃</Text></TouchableOpacity>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
      justifyContent: 'flex-start',
      alignItems:'center',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
  export default Agenda;


/*
<TouchableOpacity onPress={goToUserInfo}><Text style={{ marginTop:10, fontSize:13}}>사용자 정보</Text></TouchableOpacity>
<TouchableOpacity onPress={goToBasic}><Text style={{ marginTop:20, fontSize:13}}>TOLT 논리 테스트</Text></TouchableOpacity> 
           <TouchableOpacity onPress={goToTest}><Text style={{ marginTop:10, fontSize:13}}>기출 문제 풀이</Text></TouchableOpacity>
           <TouchableOpacity onPress={goToPretest}><Text style={{ marginTop:10, fontSize:13}}>모의 평가</Text></TouchableOpacity>        
           <TouchableOpacity onPress={goToTop}><Text style={{ marginTop:10, fontSize:13}}>두뇌보완계획</Text></TouchableOpacity>
           <TouchableOpacity onPress={goToPQR}><Text style={{ marginTop:10, fontSize:13}}>PQR 뉴스</Text></TouchableOpacity>
<TouchableOpacity onPress={goToMedium}><Text style={{ marginTop:5, fontSize:13}}>중급 논리</Text></TouchableOpacity>
           <TouchableOpacity onPress={goToHigh}><Text style={{ marginTop:5, fontSize:13}}>상급 논리</Text></TouchableOpacity>
  */