import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList } from 'react-native';

const DataItem = props =>{
    // const goToDataView = ()=>{
    //     props.setData(props.test);
    //     props.setID(props.id);
    //     props.onChange(10);
    // }
    return(
        <View>
            <Text style={{ marginTop:10, fontSize:13}}>순서: {props.index}</Text>
            <TouchableOpacity><Text style={{ marginTop:2, fontSize:13}}>측정 시간: {props.time}</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:2, fontSize:13}}>시험 이름: {props.name}</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:2, fontSize:13}}>시험 점수: {props.score}</Text></TouchableOpacity>
            
        </View>
    )
 }

 const ScoreList = props => {
    const [redraw, setredraw] = useState("0");
    const [test_all, setT] = useState([]);

    const goToBack = ()=>{

        props.onChange(12)
    }

    // const goToUpload = ()=>{

    //     props.onChange(9)
    // }

    // if(redraw == "0"){
    //     const db = getDatabase();
        
    //     const starCountRef = ref(db, 'TOLT_EL1/');
    //     onValue(starCountRef, (snapshot) => {
    //       const data = snapshot.val();
    //       //test_all=data.slice(1,5);
    //       console.log(" data len >", data.length);
    //       console.log("=====>",data.slice(1,data.length));
    //       //setT(data.slice(1,5));
    //       let data2 = data.slice(1,data.length);
    //       for(let i = 0; i < data2.length; i++){
    //           if(data2[i]){
    //             test_all.push(data2[i])
    //           }
            
    //       }
    //       // test_all.push(data.slice(1,5)["0"])
    //       // test_all.push(data.slice(1,5)["1"])
    //       // test_all.push(data.slice(1,5)["2"])
    //       // test_all.push(data.slice(1,5)["3"])
    //       setT(test_all);
    //       setredraw("1");
    //       console.log("test all ===>",test_all);
    //       //props.setdatalength(test_all.length)
    //       //updateStarCount(postElement, data);
    //     });
    //   }
    let score_list = [];
    const id = props.userInfo.user.replace(".", "A");
    if(props.score_history != null){
        if(props.score_history[id].score != null){

            score_list = props.score_history[id].score
            // props.score_history[id].score.push({name:props.name, score:correct, time:new Date().toString()})
            // set(ref(db, 'Score_History/'+id), {
            //     score: props.score_history[id].score,
                
            //   });
        }else{
            //props.score_history[id].score = [];
        }
    }else{
        //props.score_history[id]=[];
        // let score_list = [];
        // score_list.push({name:props.name, score:correct, time:new Date().toString()})
        // set(ref(db, 'Score_History/'+id), {
        //     score: score_list,
            
        //   });
        // let tmp_score_history={};
        // tmp_score_history[id]={score:score_list};

        // props.setScoreHistory(tmp_score_history);

    }
    return(
        <View style={styles.container}>
            
            <View style={{flexDirection:'row',width:300,justifyContent: 'center'}}>
            <TouchableOpacity onPress={goToBack}><Text style={{fontSize:16, fontWeight: 'bold'}}>Back</Text></TouchableOpacity>
            
          </View>
           <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>Score History List</Text>

           

           <FlatList
               
                    
                    data={score_list}
                    height={600}
                    
                    renderItem={({ item,index }) => <DataItem index={index} time={item['time']} name={item['name']} score={item["score"]}  />
                    
                        
                    
                    }
                    keyExtractor={(item, index) => index.toString()}
                        />
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        alignItems: 'center',
        justifyContent: 'center',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
  export default ScoreList;