import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';
import OneProblem from './oneProblem';
import OneText from './oneText';

const OneSlide = props => {
    return(
        <View style={styles.container}>
                <FlatList
               
                    ListHeaderComponent={
      <>
         <OneText theme={props.theme} text1={props.text1} text2={props.text2} text3={props.text3} text4={props.text4} text5={props.text5} text6={props.text6}/>
      </>}
                    data={props.test}
                    height={600}
                    
                    renderItem={({ item }) => <OneProblem number={item["Number"]} problem={item["problem"]} one={item["1"]} two={item["2"]} three={item["3"]} four={item["4"]} five={item["5"]}  />
                    
                        
                    
                    }
                    keyExtractor={(item, index) => index.toString()}
                        />
           
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      flex:1,
      justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default OneSlide;