import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const DataManager = props => {
    const goToAgenda = ()=>{
        props.onSetPre(14)
        props.onChange(0)
    }
    const goToBasic = ()=>{
        props.onSetPre(14)
        props.onChange(15)
    }

    const goToTop = ()=>{
        props.onSetPre(14)
        props.onChange(18)
    }

    // const goToEL = ()=>{
    //     props.onSetPre(2)
    //     props.onChange(7)
    // }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToAgenda}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>데이터 관리</Text>
            <TouchableOpacity onPress={goToBasic}><Text style={{ marginTop:20, fontSize:13}}>TOLT 논리 테스트</Text></TouchableOpacity>         
            <TouchableOpacity onPress={goToTop}><Text style={{ marginTop:10, fontSize:13}}>두뇌보완계획</Text></TouchableOpacity>
          
           
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
  export default DataManager;
/*
 <TouchableOpacity onPress={goToEL}><Text style={{ marginTop:10, fontSize:13}}>EL 기초논리</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>IR 추리추론(서비스 예정)</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>AC 논증비판(서비스 예정)</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>RC 언어이해(서비스 예정)</Text></TouchableOpacity>
            
  */