import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text,TextInput, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView,Linking } from 'react-native';
import { getAuth, createUserWithEmailAndPassword,signInWithEmailAndPassword } from "firebase/auth";
import Alert from "react-native-awesome-alerts";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RadioGroup, RadioButton } from "./radio";
const auth = getAuth();

import { getDatabase, ref, set,push,onValue } from "firebase/database";


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP1D3r7nd_Ni5B0U47PtTbWG84wYlHxW0",
  authDomain: "logic-93c13.firebaseapp.com",
  databaseURL: "https://logic-93c13-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logic-93c13",
  storageBucket: "logic-93c13.appspot.com",
  messagingSenderId: "875422042858",
  appId: "1:875422042858:web:59118c540c88cdf989e593",
  measurementId: "G-T3BRD4NLWP"
};

// Initialize Firebase
/*
<Text style={{ marginLeft:50}}>: </Text>
            <Text style={{ marginLeft:50}}>: </Text>
            <Text style={{ marginLeft:50}}>: </Text>
const options = [
      { value: "test", label: "test" },
      { value: "data", label: "data" },
      
    ];

    <RadioGroup
          value={mode}
          onChange={setmode}
          labelID={labelID}
          style={styles.pizzaCrust}
        >
          {options.map(option => (
            <RadioButton
              key={option.value}
              {...option}
              containerStyle={styles.radioBtn}
            />
          ))}
        </RadioGroup>
    */
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const Login = props => {
    const [redraw, setredraw] = useState("0");
    const [user_list, setUserlist] = useState({});
    const [value, setText]=useState("");
    const [value2, setText2]=useState("");
    const [msg, setMsg]=useState("");
    const [mode, setmode] = useState("test");
    const [showAlert, setShowAlert]=useState(false);
    const options = [
      { value: "data", label: "    Data Mode" },
      
    ];
    const labelID = "Select Mode";
    let login_info={
        user:"",
        pw:"",
        nick:"",
        id:"",
        state:"",
        role:"",
        mode:""
        
     };

    const loginProcess = ()=>{
        // props.onSetPre(4)
        // props.onChange(1)

    
    signInWithEmailAndPassword(auth, value, value2)
    .then( async () => {
      console.log('User account created & signed in!');
      login_info.user = value;
      login_info.pw = value2;
      //login_info.token = responseJson.token;
      login_info.state = "login";
      login_info.mode = mode;

      const id = value.replace(".", "A");
      login_info.role = user_list[id].role;

      if(mode == "data" && user_list[id].role != "admin")
      {
        setShowAlert(true)
        setMsg("당신은 데이터를 관리할 권한이 없어요 !!")
        return;
      }
      // for(let i = 0; i < user_list.length; i++){
      //   if(user_list[i].id == id){
      //     login_info.role = user_list[i].role;
      //     //user_list.push(data2[i])
      //   }
      
      // }

      console.log("login_info.role >",login_info.role);


      const jsonValue = JSON.stringify(login_info)
      await AsyncStorage.setItem('login', jsonValue)
      
      props.setUserInfo(login_info);
      props.setmode(mode);
      props.onChange(2);

      //setLoading(false);
    })
    .catch(error => {
      if (error.code === 'auth/email-already-in-use') {
        console.log('That email address is already in use!');
        Alert.alert(
          "That email address is already in use!"
        )
        setShowAlert(true)
        setMsg("That email address is already in use!")
        return;
      }
  
      if (error.code === 'auth/invalid-email') {
        console.log('That email address is invalid!');
        setShowAlert(true)
        setMsg("That email address is invalid!")
        /*
        Alert.alert(
          "That email address is invalid!"
        )*/
        return;
      }

      if (error.code === 'auth/wrong-password') {
        console.log('That password is invalid!');
        setShowAlert(true)
        setMsg("That password is invalid!")
        /*
        Alert.alert(
          "That password is invalid!"
        )*/
        return;
      }
  
      //console.error(error);
    //   Alert.alert(
    //     "login error: "+error.code
    //   )
      setShowAlert(true)
      setMsg("login error: "+error.code)
    });


    }

    const goToSign = ()=>{
        // props.onSetPre(4)
        props.onChange(1);
    }

    const goToForgot = ()=>{
        // props.onSetPre(4)
        // props.onChange(1)
    }

    if(redraw == "0"){
      const db = getDatabase();
      
      const starCountRef = ref(db, 'UserInfo/');
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        setUserlist(data);
        console.log("========>data ", data)
        //test_all=data.slice(1,5);
        // console.log(" data len >", data.length);
        // console.log("=====>",data.slice(1,data.length));
        // //setT(data.slice(1,5));
        // let data2 = data.slice(1,data.length);
        // for(let i = 0; i < data2.length; i++){
        //     if(data2[i]){
        //       user_list.push(data2[i])
        //     }
          
        // }
        // // test_all.push(data.slice(1,5)["0"])
        // // test_all.push(data.slice(1,5)["1"])
        // // test_all.push(data.slice(1,5)["2"])
        // // test_all.push(data.slice(1,5)["3"])
        // setUserlist(user_list);
        setredraw("1");
        console.log("test all ===>",user_list);
        //props.setdatalength(test_all.length)
        //updateStarCount(postElement, data);
      });
    }
    return(
        <View style={styles.container}>
            <View style={{ marginTop:0}}></View>
            <View style={styles.top5}>
              
              <View style={styles.top3}>
              <Text style={{fontSize:100,marginTop:0,marginBottom: 10,textAlign:'left', color:'#263757'}}>PQR</Text>
              <TouchableOpacity ><Text style={{ marginTop:20,fontWeight: 'bold', fontSize:20}}>TOLT</Text></TouchableOpacity> 
              
              <Text style={{ marginTop:10,marginLeft:20}}>“TOLT”는 “Test of Logical Thinking”을 줄인 말입니다. 
              논리로 생각하는 힘을 키우고 그 힘을 재는 시험입니다. 
              TOLT는 TOLT EL 기초논리,TOLT RC 언어이해,TOLT CA 논증비판으로 이뤄졌습니다.
              “EL”은 “Elementary Logic”을 줄인 말인데,논리의 기본 개념과 추론 규칙 을 훈련하고 기본 사고능력을 검사합니다.
              “RC”는 “Logical Reading and Comprehension”을 줄인 말인데,독해 곧 텍 스트 정보의 이해 능력을 검사합니다.
              “CA”는 “Critical Argumentation”을 줄인 말인데,논리와 이해를 바탕으로 논증을 설계하고 비판하는 능력을 검사합니다.</Text>
            
            <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold',marginLeft:20, fontSize:16}}>TOLT EL 기초논리</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginLeft:20, fontSize:16}}>TOLT RC 언어이해</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 20,marginLeft:20, fontSize:16}}>TOLT CA 논증비판</Text></TouchableOpacity>
           
           <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold', fontSize:20}}>기출문제 풀이</Text></TouchableOpacity>
           <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold',marginLeft:20, fontSize:16}}>언어논리</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginLeft:20, fontSize:16}}>언어이해</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 20,marginLeft:20, fontSize:16}}>추리논증</Text></TouchableOpacity>

           <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold', fontSize:20}}>모의평가</Text></TouchableOpacity>  
           <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginLeft:20, fontSize:16}}>언어논리</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>언어이해</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 20,marginLeft:20, fontSize:16}}>추리논증</Text></TouchableOpacity>

           <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold', fontSize:20}}>두뇌보완계획</Text></TouchableOpacity>
           <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginLeft:20, fontSize:16}}>100</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>100 에센스</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>200</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 20,marginLeft:20, fontSize:16}}>300</Text></TouchableOpacity>

           <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold',marginBottom:0, fontSize:20}}>PQR 뉴스</Text></TouchableOpacity>
           <TouchableOpacity><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>Philosophical Questions Review</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>Physical Questions Review</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>Political Questions Review</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>Public Quiz Review</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 20,marginLeft:20, fontSize:16}}>Personal Query Review</Text></TouchableOpacity>

            <TouchableOpacity ><Text onPress={ ()=> Linking.openURL('https://ithink.kr/PQR_Notice') } style={{ marginTop:10,fontWeight: 'bold',marginBottom:100, fontSize:20}}>PQR 소개 및 등업 신청</Text></TouchableOpacity>
            
           </View>
           
           


            <View style={styles.row}>
            <Image style={{width: 16, height: 19,marginLeft:0, marginTop:0}}
                       source={require('./img/ic_id.png')}/>
            <TextInput  onChangeText={text => setText(text)}
          value={value} autoCapitalize = 'none'
           placeholder = "Enter email ID" placeholderTextColor = "#7989a8" style={{ marginLeft:20,marginTop:0,height: 30,
             fontSize:14,textAlign: 'left',width:230,color:'black',paddingRight:0, borderColor: '#ffffff',backgroundColor:'#ffffff', borderWidth: 1 }}></TextInput>
                                
            </View>
            

            <View style={styles.top2}>
            <View style={{width: 280, height: 0.5,backgroundColor:'#c9d3e5'}}></View>
            </View>
            <View style={{ marginTop:20}}></View>
            <View style={styles.top2}>
            <View style={styles.row}>
            <Image style={{width: 16, height: 19,marginLeft:0, marginTop:0}}
                       source={require('./img/ic_password.png')}/>
            <TextInput  onChangeText={text => setText2(text)}
          value={value2} secureTextEntry={true} autoCapitalize = 'none'
           placeholder = "Password" placeholderTextColor = "#7989a8" style={{ marginLeft:20,marginTop:0,height: 30,
             fontSize:14,textAlign: 'left',width:230,color:'black',paddingRight:0, borderColor: '#ffffff',backgroundColor:'#ffffff', borderWidth: 1 }}></TextInput>
                                
            </View></View>
            <View style={styles.top2}>
            <View style={{width: 280, height: 0.5,backgroundColor:'#c9d3e5'}}></View>
            </View>
            <View style={styles.top6}>
            <RadioGroup
          value={mode}
          onChange={setmode}
          labelID={labelID}
          style={styles.pizzaCrust}
        >
          {options.map(option => (
            <RadioButton
              key={option.value}
              {...option}
              containerStyle={styles.radioBtn}
            />
          ))}
        </RadioGroup></View>

            <View style={{ marginTop:10}}></View>

            <View style={styles.top2}>
                  <TouchableOpacity onPress={loginProcess}>
                 <View style={styles.card3}>
                     <Text style={{fontSize:15,marginTop:0,textAlign:'center', color:'#263757'}}>Login</Text>
                 </View></TouchableOpacity>
                 </View>

                 
            <View style={{ marginTop:10}}></View>
            <View style={styles.top2}>
            <View style={styles.row3}>
               
               <TouchableOpacity onPress={goToSign}>
               <Text style={styles.t1}>Sign Up</Text></TouchableOpacity>
               <View style={{ marginTop:0,backgroundColor:'#c9d3e5',width:1,height:10}}></View>
               <TouchableOpacity onPress={goToForgot}>
               <Text style={styles.t1}>Forgot Password</Text></TouchableOpacity>
               
            </View></View></View>

            
            <Alert
          show={showAlert}
          message={msg}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No, cancel"
          confirmText="Yes, delete it"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowAlert(false);
          }}
          onConfirmPressed={() => {
            setShowAlert(false);
          }}
        />    

        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        width:'80%',
        //backgroundColor: '#263757',
        
        justifyContent:'flex-start',
        
        flex:1,
      }, 

      top:{
        
        justifyContent:'center',
        alignItems:'center',
        marginTop:50,
    },
    pizzaCrust: {
      flexDirection: "row",
      marginTop: 8,
      marginBottom: 16,
    },
    radioBtn: {
      marginRight: 16
    },
    top2:{
        justifyContent:'center',
        alignItems:'center',
        marginTop:5,
    },
    top3:{
      justifyContent:'center',
      alignItems:'flex-start',
      marginTop:5,
      marginLeft:40,
    },
    top4:{
      justifyContent:'center',
      alignItems:'flex-start',
      marginTop:5,
      marginLeft:80,
    },
    top5:{
      justifyContent:'center',
      alignItems:'flex-start',
      marginTop:5,
      marginLeft:0,
    },
    top6:{
      justifyContent:'center',
      alignItems:'flex-start',
      marginTop:5,
      marginLeft:20,
    },
    row:{
        marginLeft:20,
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center'
    },
    row3:{
      
      flexDirection:'row',
      justifyContent:'flex-start',
      alignItems:'center',
      
    },
    row2:{
       flexDirection:'row',
       justifyContent:'space-evenly',
       alignItems:'center',
       width:200
    },
    row3:{
      flexDirection:'row',
      justifyContent:'space-evenly',
      alignItems:'center',
      width:200,
      marginLeft:40
   },
    card3:{
        marginTop:3,
      marginBottom:10,
      borderWidth:2,
      borderColor:'#ffd052',
      borderBottomStartRadius:27,
      borderBottomRightRadius:27,
      borderBottomLeftRadius:27,
      borderBottomEndRadius:27,
      borderTopLeftRadius:27,
      borderTopRightRadius:27,
      width:280,
      height:50,
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      padding:0,
      backgroundColor:'#ffd052'
    },
    t1:{
        fontSize:12,
        color:'black'
    },
    t2:{
      fontSize:14,
      color:'black'
    }
  })
  
  export default Login;

/*
<View style={styles.row}>
            
            <Text>Enter Mode: ></Text>

            
            <TextInput  onChangeText={text => setmode(text)}
          value={mode} autoCapitalize = 'none'
           placeholder = "Enter Mode: test or data" placeholderTextColor = "#7989a8" style={{ marginLeft:20,marginTop:0,height: 30,
             fontSize:14,textAlign: 'left',width:230,color:'black',paddingRight:0, borderColor: '#ffffff',backgroundColor:'#ffffff', borderWidth: 1 }}></TextInput>
                                
            </View>
  */