import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const Tolt030 = props => {
    const goToAgenda = ()=>{
        props.onSetPre(7)
        props.onChange(0)
    }
    const goToContent1 = ()=>{
        // props.onSetPre(7)
        // props.onChange(1)
        props.onSetPre(27)
        props.settype2("Q021")
        props.onChange(38)
    }

    const goToContent2 = ()=>{
        props.onSetPre(27)
        props.settype2("Q022")
        props.onChange(38)
    }

    const goToContent3 = ()=>{
        props.onSetPre(27)
        props.settype2("Q023")
        props.onChange(38)
    }

    const goToContent4 = ()=>{
        props.onSetPre(27)
        props.settype2("Q024")
        props.onChange(38)
    }

    const goToContent5 = ()=>{
        props.onSetPre(27)
        props.settype2("Q025")
        props.onChange(38)
    }

    const goToContent6 = ()=>{
        props.onSetPre(27)
        props.settype2("Q026")
        props.onChange(38)
    }

    const goToContent7 = ()=>{
        props.onSetPre(27)
        props.settype2("Q027")
        props.onChange(38)
    }

    const goToContent8 = ()=>{
        props.onSetPre(27)
        props.settype2("Q028")
        props.onChange(38)
    }

    const goToContent9 = ()=>{
        props.onSetPre(27)
        props.settype2("Q029")
        props.onChange(38)
    }

    const goToContent10 = ()=>{
        props.onSetPre(27)
        props.settype2("Q030")
        props.onChange(38)
    }

    const goToBack = ()=>{
        props.onSetPre(27)
        props.onChange(0)
        //props.onChange(props.backNumber)
    }
    return(
        <View style={styles.container}>
            
            <View style={styles.top3}>
            <Text style={{ marginTop:15,marginBottom:30 ,fontSize:40, fontWeight: 'bold'}}>TOLT 030</Text>
            
            <TouchableOpacity onPress={goToContent1}><Text style={{ marginTop:10, fontSize:20}}>Q021 모든 몇몇 달리쓰기</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent2}><Text style={{ marginTop:5, fontSize:20}}>Q022 문장사이관계심화</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent3}><Text style={{ marginTop:5, fontSize:20}}>Q023 벤그림추론</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent4}><Text style={{ marginTop:5, fontSize:20}}>Q024 모든몇몇추론</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent5}><Text style={{ marginTop:5, fontSize:20}}>Q025 양화 논리</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent6}><Text style={{ marginTop:5, fontSize:20}}>Q026 참말 놀이</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent7}><Text style={{ marginTop:5, fontSize:20}}>Q027 거짓말 놀이</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent8}><Text style={{ marginTop:5, fontSize:20}}>Q028 줄 세우기, 짝짓기, 갈래짓기</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent9}><Text style={{ marginTop:5, fontSize:20}}>Q029 그밖에 논리 퍼즐</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent10}><Text style={{ marginTop:5, fontSize:20}}>Q030 논리 퍼즐</Text></TouchableOpacity>
            
            <TouchableOpacity onPress={goToBack}><Text style={{ marginTop:100,fontWeight: 'bold', fontSize:20}}>처음으로</Text></TouchableOpacity>
            </View>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
    top3:{
        justifyContent:'center',
        alignItems:'flex-start',
        marginTop:5,
        marginLeft:40,
      },
  })
  
  export default Tolt030;
/*
<TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
<Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>TOLT 030</Text>
            <Text>TOLT 030은 문장 논리의 논리 퍼즐을 배우고 배운 바를 점검하는 시험입니다. TOLT 030는 10개의 시험 세트로 이뤄졌습니다.</Text>
  */