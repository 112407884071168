import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList } from 'react-native';

//import { initializeApp } from "firebase/app";
//import { getDatabase } from "firebase/database";
import { getDatabase, ref, set,push,onValue } from "firebase/database";
//import database from "firebase/compat/database";
//import database from '@react-native-firebase/database';
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB0KZSGiW5MSuseDACnXhv3fBjLjYH6g0A",
//   authDomain: "emailverification-c32c5.firebaseapp.com",
//   databaseURL: "https://emailverification-c32c5.firebaseio.com",
//   projectId: "emailverification-c32c5",
//   storageBucket: "emailverification-c32c5.appspot.com",
//   messagingSenderId: "940289013912",
//   appId: "1:940289013912:web:5547b11a7c64cfe52ef8ed",
//   measurementId: "G-S0VPE37BPP"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP1D3r7nd_Ni5B0U47PtTbWG84wYlHxW0",
  authDomain: "logic-93c13.firebaseapp.com",
  databaseURL: "https://logic-93c13-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logic-93c13",
  storageBucket: "logic-93c13.appspot.com",
  messagingSenderId: "875422042858",
  appId: "1:875422042858:web:59118c540c88cdf989e593",
  measurementId: "G-T3BRD4NLWP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const DataItem = props =>{
    const goToModify = ()=>{

    // const db = getDatabase();
    // let v =props.id;// test_size + 1;
    // set(ref(db, 'TOLT_EL1/'+v), null);

     var i = 0;
     for (var key in props.note1) {
       console.log("key>>",key);
       console.log("value>>",props.note1[key]);
       if(key == "-1"){
         continue;
       }
       if(i == 0){
        props.setnote1_1(key);
        
        props.setnote1_1_detail(props.note1[key]);
        
       }
       if(i == 1){
        
        props.setnote1_2(key);
        
        props.setnote1_2_detail(props.note1[key]);
        
       }
       if(i == 2){
       
        props.setnote1_3(key);
       
        props.setnote1_3_detail(props.note1[key]);
       }
        i = i +1;
      }
      // console.log(props.note1_1)
      // console.log(props.note1_2)
      // console.log(props.note1_3)
      // console.log(props.note1_1_detail)
      // console.log(props.note1_2_detail)
      // console.log(props.note1_3_detail)
      props.setnumber(props.number); 
      props.setproblem(props.problem);
      props.settext(props.text);
      props.setexample1(props.example1);
      props.setexample2(props.example2);
      props.setcorrect(props.correct);
      props.setone(props.one);
      props.settwo(props.two);
      props.setthree(props.three);
      props.setfour(props.four);
      // props.setnote1_1(props.note1_1);
      // props.setnote1_2(props.note1_2);
      // props.setnote1_3(props.note1_3);
      // props.setnote1_1_detail(props.note1_1_detail);
      // props.setnote1_2_detail(props.note1_2_detail);
      // props.setnote1_3_detail(props.note1_3_detail);
      props.setnote2(props.note2);
    props.onChange(11)
    }

    let o_1, o_detail_1;
    let o_2, o_detail_2;
    let o_3, o_detail_3;
    var i = 0;
     for (var key in props.note1) {
       console.log("key>>",key);
       console.log("value>>",props.note1[key]);
       if(key == "-1"){
         continue;
       }
       if(i == 0){
        o_1 = key;
        o_detail_1 = props.note1[key];
        // props.setnote1_1(key);
        
        // props.setnote1_1_detail(props.note1[key]);
        
       }
       if(i == 1){
        
        //props.setnote1_2(key);
        
        //props.setnote1_2_detail(props.note1[key]);

        o_2 = key;
        o_detail_2 = props.note1[key];
        
       }
       if(i == 2){
       
        //props.setnote1_3(key);
       
        //props.setnote1_3_detail(props.note1[key]);

        o_3 = key;
        o_detail_3 = props.note1[key];
       }
        i = i +1;
      }
      // console.log(props.note1_1)
      // console.log(props.note1_2)
      // console.log(props.note1_3)
      // console.log(props.note1_1_detail)
      // console.log(props.note1_2_detail)
      // console.log(props.note1_3_detail)
    //   props.setnumber(props.number); 
    //   props.setproblem(props.problem);
    //   props.settext(props.text);
    //   props.setexample1(props.example1);
    //   props.setexample2(props.example2);
    //   props.setcorrect(props.correct);
    //   props.setone(props.one);
    //   props.settwo(props.two);
    //   props.setthree(props.three);
    //   props.setfour(props.four);
    //   // props.setnote1_1(props.note1_1);
    //   // props.setnote1_2(props.note1_2);
    //   // props.setnote1_3(props.note1_3);
    //   // props.setnote1_1_detail(props.note1_1_detail);
    //   // props.setnote1_2_detail(props.note1_2_detail);
    //   // props.setnote1_3_detail(props.note1_3_detail);
    //   props.setnote2(props.note2);
    // props.onChange(11)
    
    return(
        <View style={{ marginTop:15}}>
            
            <TouchableOpacity onPress={goToModify}><Text style={{fontSize:16, marginBottom:5, fontWeight: 'bold'}}>수정</Text></TouchableOpacity>
            <Text>{props.number} {props.problem}</Text>
          
          
          <View style={{ marginTop:10, marginBottom:30}}>
           <View style={styles.container2}>
           <Text style={{ marginTop:10, fontSize:13}}>{props.text}</Text>
           <Text style={{ marginTop:10,fontSize:13}}>{props.example1}</Text>
           <Text style={{ marginTop:2,fontSize:13}}>{props.example2}</Text>
          </View>
          </View>
         <Text> ① {props.one}</Text>
         <Text> ② {props.two}</Text>
         <Text> ③ {props.three}</Text>
         <Text> ④ {props.four}</Text>
         <View style={{ marginTop:10, marginBottom:10}}></View>
         <Text>정답: {props.correct}</Text>
         <Text>오답 노트</Text>
         <Text>{o_1}</Text>
         <Text>{o_detail_1}</Text>
         <Text>{o_2}</Text>
         <Text>{o_detail_2}</Text>
         <Text>{o_3}</Text>
         <Text>{o_detail_3}</Text>

         <Text>정답해설</Text>
         <Text>{props.note2}</Text>
        </View>
    )
 }

 /*
{
     "Number":1,
     "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
     "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
     "example1":"ㄱ. 이 문장 모임은 네 문장으로 이뤄졌다.",
     "example2":"ㄴ. 이 문장 모임은 추론이다. ",
     "1":"없음",
     "2":"ㄱ",
     "3":"ㄴ",
     "4":"ㄱ,ㄴ",
     "answer":"",
     "correct":"3",
     "note1":
     {
         "":"답을 선택하지 않았어요!!",
         "1":"➀ ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. ‘추론’이 무엇인지 두보계 100 제001절을 읽으며 다시 살펴보세요.",
         "2":"➁ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 안타깝게도 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 두보계 100 제001절을 읽으며 ‘추론’이 무엇인지 다시 살펴보세요.",
         "4":"➃ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다."
     },
     "note2":"ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 이 문장 모임은 세 문장으로 이뤄졌습니다. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 이 문장 모임에는 “따라서”가 들어 있습니다. 이 때문에 이 문장 모임은 추론입니다. “따라서” 뒤에 나오는 문장은 이 문장 모임의 결론입니다. 결론이 있다는 것은 전제가 있음을 말해줍니다. 전제와 결론이 있는 문장 모임은 추론입니다. 정답은 ➂입니다."
   }
*/
const DataView = props => {
    const goToBack = ()=>{

        props.onChange(8)
    }

    const goToModifyTestName = ()=>{

      props.onChange(38)
    }

    const goToAddData = ()=>{

      props.onChange(39)
    }

    const dataRemove = ()=>{

        const db = getDatabase();
        let v =props.id;// test_size + 1;
        console.log("-----> id >",v)
        //set(ref(db, 'TOLT_EL1/'+v), null);
        ////////////
        let final_test_data = props.test_data.EL;
        let tmp_list = [];
        for(let i = 0 ; i < final_test_data.length; i++){
          if(final_test_data[i].id != v){
            tmp_list.push(final_test_data[i])
          }
        }
        // let count = final_test_data.length+1;
        // const add_data = {
        //     type1:props.type1,
        //     type2:props.type2,
        //     type3:props.type3,
        //     test: final_test,
        //     name:test_name, 
        //     id:count
        // }
        // final_test_data.push(add_data);
        let tmp_final_data = props.test_data;
        tmp_final_data["EL"] = tmp_list;
        props.settest_data(tmp_final_data);
        set(ref(db, 'DATA'),tmp_final_data);
        ////////////
        props.onChange(8)
    }

    let top_button;
    if(props.test_all.length < 10){
      top_button = <TouchableOpacity onPress={goToAddData}><Text style={{fontSize:16, fontWeight: 'bold'}}>문제 추가</Text></TouchableOpacity>
    }else{
      top_button = <Text>10개 문항입니다.</Text>
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToBack}><Text style={{fontSize:16,marginBottom:10, fontWeight: 'bold'}}>Back</Text></TouchableOpacity>
            
            {top_button}
            <TouchableOpacity onPress={goToModifyTestName}><Text style={{fontSize:16,marginTop:20, marginBottom:5, fontWeight: 'bold'}}> 테스트 이름 수정</Text></TouchableOpacity>
            <Text>Test Name: {props.Name}</Text>
            <FlatList
               
                    
               data={props.test_all}
               height={600}
               
               renderItem={({ item }) => <DataItem
               setnumber={props.setnumber} setproblem={props.setproblem}
                settext={props.settext}
                setexample1={props.setexample1}
                setexample2={props.setexample2}
                setcorrect={props.setcorrect}
                setnote1_1={props.setnote1_1}
                setnote1_2={props.setnote1_2}
                setnote1_3={props.setnote1_3}
                setnote1_1_detail={props.setnote1_1_detail}
                setnote1_2_detail={props.setnote1_2_detail}
                setnote1_3_detail={props.setnote1_3_detail}
                setnote2={props.setnote2}
                correct={item['correct']}
                setone={props.setone} settwo={props.settwo} setthree={props.setthree} setfour={props.setfour}
              //  note1_1={item["note1"]['note1_1']}
              //  note1_2={item['note1_2']}
              //  note1_3={item['note1_3']}
              //  note1_1_detail={item['note1_1_detail']}
              //  note1_2_detail={item['note1_2_detail']}
              //  note1_3_detail={item['note1_3_detail']}
               note1 = {item["note1"]}
               note2={item['note2']} id={props.id} test={props.test_all} onChange={props.onChange} number={item["Number"]} problem={item["problem"]} text={item['text']} example1={item['example1']} example2={item['example2']} one={item["1"]} two={item["2"]} three={item["3"]} four={item["4"]}  />
               
                   
               
               }
               keyExtractor={(item, index) => index.toString()}
                   />

         <TouchableOpacity onPress={dataRemove}><Text style={{fontSize:16, fontWeight: 'bold'}}>삭제</Text></TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        alignItems: 'center',
        justifyContent: 'center',
      
      width:'80%',
      
      paddingBottom:10,

    },
  })
  
export default DataView;

