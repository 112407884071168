import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const UploadManager = props => {
    const goToAgenda = ()=>{
        props.onSetPre(17)
        props.onChange(0)
    }
    const goToContent = ()=>{
        // props.onSetPre(17)
        // props.onChange(1)
    }

    const goToContent2 = ()=>{
        // props.onSetPre(17)
        // props.onChange(6)
    }

    const goToBack = ()=>{
        props.onSetPre(17)
        //props.onChange()
        props.onChange(props.backNumber)
    }

    const goTo410 = ()=>{
        //props.onSetPre(17)
        props.onChange(19)
    }

    const goTo405 = ()=>{
        //props.onSetPre(17)
        //props.onChange(20)
    }

    const goTo510 = ()=>{
        //props.onSetPre(17)
        //props.onChange(21)
    }
    const goTo505 = ()=>{
        //props.onSetPre(17)
        //props.onChange(22)
    }
    const goTo810 = ()=>{
        //props.onSetPre(17)
        //props.onChange(23)
    }

    const goTo805 = ()=>{
        //props.onSetPre(17)
        //props.onChange(24)
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>{props.p_name}</Text>
            <Text style={{ marginTop:5,fontSize:16, fontWeight: 'bold'}}>Upload Problem Type</Text>
            <TouchableOpacity onPress={goTo410}><Text style={{ marginTop:10, fontSize:13}}>A410</Text></TouchableOpacity>
            <TouchableOpacity onPress={goTo405}><Text style={{ marginTop:5, fontSize:13}}>A405</Text></TouchableOpacity>
            <TouchableOpacity onPress={goTo510}><Text style={{ marginTop:5, fontSize:13}}>A510</Text></TouchableOpacity>
            <TouchableOpacity onPress={goTo505}><Text style={{ marginTop:5, fontSize:13}}>A505</Text></TouchableOpacity>
            <TouchableOpacity onPress={goTo810}><Text style={{ marginTop:5, fontSize:13}}>A810</Text></TouchableOpacity>
            <TouchableOpacity onPress={goTo805}><Text style={{ marginTop:5, fontSize:13}}>A805</Text></TouchableOpacity>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default UploadManager;