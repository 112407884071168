import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView,Linking } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';



const Agenda = props => {
    const goToContent = ()=>{
        props.onSetPre(0)
        props.onChange(1)
    }

    const goToBasic = ()=>{
        props.onSetPre(0)
        props.onChange(2)
    }

    const goToMedium = ()=>{
        props.onSetPre(0)
        props.onChange(3)
    }

    const goToHigh = ()=>{
        props.onSetPre(0)
        props.onChange(4)
    }

    const goToTop = ()=>{
        props.onSetPre(0)
        props.onChange(5)
    }

    const goToDataList = ()=>{
        props.onSetPre(0)
        props.onChange(8)
    }

    const goToDataManager = ()=>{
        props.onSetPre(0)
        props.onChange(14)
    }

    const goToLoggout = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)

        props.logout();
    }

    const goToUserInfo = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        props.onChange(12)
        //props.logout();
    }

    const goToTest = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        props.onChange(35)
        //props.logout();
    }

    const goToPretest = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        props.onChange(36)
        //props.logout();
    }

    const goToPQR = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        props.onChange(37)
        //props.logout();
    }

    const goTo010 = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        props.onChange(37)
        props.settype1("TOLT010")
        props.onChange(25)
        //props.logout();
    }

    const goTo020 = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        //props.onChange(37)
        props.settype1("TOLT020")
        props.onChange(26)
        //props.logout();
    }

    const goTo030 = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        //props.onChange(37)
        props.settype1("TOLT030")
        props.onChange(27)
        //props.logout();
    }

    const goTo040 = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        //props.onChange(37)
        props.settype1("TOLT040")
        props.onChange(28)
        //props.logout();
    }

    const goTo100 = ()=>{
        // props.onSetPre(0)
        // props.onChange(8)
        props.onSetPre(0)
        //props.onChange(37)
        props.settype1("TOLT100")
        props.onChange(29)
        //props.logout();
    }

    return(
        <View style={styles.container}>
           
           

           <View style={styles.top3}>
              <Text style={{fontSize:100,marginTop:0,marginBottom: 10,textAlign:'left', color:'#263757'}}>PQR</Text>
              <TouchableOpacity ><Text style={{ marginTop:20,fontWeight: 'bold', fontSize:20}}>TOLT</Text></TouchableOpacity> 
              
              
            
            <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold',marginLeft:20, fontSize:16}}>TOLT EL</Text></TouchableOpacity>
              <TouchableOpacity onPress={goTo010}><Text style={{ marginTop:5,marginLeft:20, fontSize:16}}>TOLT 010</Text></TouchableOpacity>  
              <TouchableOpacity onPress={goTo020}><Text style={{ marginTop:5,marginLeft:20, fontSize:16}}>TOLT 020</Text></TouchableOpacity>
              <TouchableOpacity onPress={goTo030}><Text style={{ marginTop:5,marginBottom: 0,marginLeft:20, fontSize:16}}>TOLT 030</Text></TouchableOpacity>
              <TouchableOpacity onPress={goTo040}><Text style={{ marginTop:5,marginLeft:20, fontSize:16}}>TOLT 040</Text></TouchableOpacity>
              <TouchableOpacity onPress={goTo100}><Text style={{ marginTop:5,marginBottom: 0,marginLeft:20, fontSize:16}}>TOLT 100</Text></TouchableOpacity>

            <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold',marginLeft:20, fontSize:16}}>TOLT RC</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 20,marginLeft:20, fontSize:16}}>TOLT CA</Text></TouchableOpacity>
           
           <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold', fontSize:20}}>기출문제 풀이</Text></TouchableOpacity>
           <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold',marginLeft:20, fontSize:16}}>언어논리</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginLeft:20, fontSize:16}}>언어이해</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 20,marginLeft:20, fontSize:16}}>추리논증</Text></TouchableOpacity>

           <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold', fontSize:20}}>모의평가</Text></TouchableOpacity>  
           <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginLeft:20, fontSize:16}}>언어논리</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>언어이해</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 20,marginLeft:20, fontSize:16}}>추리논증</Text></TouchableOpacity>

           <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold', fontSize:20}}>두뇌보완계획</Text></TouchableOpacity>
           <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginLeft:20, fontSize:16}}>100</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>100 에센스</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>200</Text></TouchableOpacity>
            <TouchableOpacity ><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 20,marginLeft:20, fontSize:16}}>300</Text></TouchableOpacity>

           <TouchableOpacity ><Text style={{ marginTop:10,fontWeight: 'bold',marginBottom:0, fontSize:20}}>PQR 뉴스</Text></TouchableOpacity>
           <TouchableOpacity><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>Philosophical Questions Review</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>Physical Questions Review</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>Political Questions Review</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 0,marginLeft:20, fontSize:16}}>Public Quiz Review</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5,fontWeight: 'bold',marginBottom: 20,marginLeft:20, fontSize:16}}>Personal Query Review</Text></TouchableOpacity>
            <TouchableOpacity ><Text onPress={ ()=> Linking.openURL('https://ithink.kr/PQR_Notice') } style={{ marginTop:10,fontWeight: 'bold',marginBottom:100, fontSize:20}}>PQR 소개 및 등업 신청</Text></TouchableOpacity>

            <TouchableOpacity onPress={goToUserInfo}><Text style={{ marginTop:10,fontWeight: 'bold',marginBottom: 0,marginLeft:0, fontSize:16}}>사용자 정보</Text></TouchableOpacity>
           <TouchableOpacity onPress={goToLoggout}><Text style={{ marginTop:10, fontWeight: 'bold',marginBottom: 0,marginLeft:0, fontSize:16}}>로그아웃</Text></TouchableOpacity>
           </View>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
      justifyContent: 'flex-start',
      alignItems:'center',
      
      width:'80%',
      
      paddingBottom:10
    },
    top3:{
        justifyContent:'center',
        alignItems:'flex-start',
        marginTop:5,
        marginLeft:40,
      },
  })
  
  export default Agenda;


/*
<Text style={{ marginTop:10,marginLeft:20}}>“TOLT”는 “Test of Logical Thinking”을 줄인 말입니다. 
              논리로 생각하는 힘을 키우고 그 힘을 재는 시험입니다. 
              TOLT는 TOLT EL 기초논리,TOLT RC 언어이해,TOLT CA 논증비판으로 이뤄졌습니다.
              “EL”은 “Elementary Logic”을 줄인 말인데,논리의 기본 개념과 추론 규칙 을 훈련하고 기본 사고능력을 검사합니다.
              “RC”는 “Logical Reading and Comprehension”을 줄인 말인데,독해 곧 텍 스트 정보의 이해 능력을 검사합니다.
              “CA”는 “Critical Argumentation”을 줄인 말인데,논리와 이해를 바탕으로 논증을 설계하고 비판하는 능력을 검사합니다.</Text>
<Text style={{ marginTop:0,fontSize:16, fontWeight: 'bold'}}>논리 아카데미</Text>
           <TouchableOpacity onPress={goToBasic}><Text style={{ marginTop:20, fontSize:13}}>TOLT 논리 테스트</Text></TouchableOpacity> 
           <TouchableOpacity onPress={goToTest}><Text style={{ marginTop:10, fontSize:13}}>기출 문제 풀이</Text></TouchableOpacity>
           <TouchableOpacity onPress={goToPretest}><Text style={{ marginTop:10, fontSize:13}}>모의 평가</Text></TouchableOpacity>        
           <TouchableOpacity onPress={goToTop}><Text style={{ marginTop:10, fontSize:13}}>두뇌보완계획</Text></TouchableOpacity>
           <TouchableOpacity onPress={goToPQR}><Text style={{ marginTop:10, fontSize:13}}>PQR 뉴스</Text></TouchableOpacity>
           
<TouchableOpacity onPress={goToMedium}><Text style={{ marginTop:5, fontSize:13}}>중급 논리</Text></TouchableOpacity>
           <TouchableOpacity onPress={goToHigh}><Text style={{ marginTop:5, fontSize:13}}>상급 논리</Text></TouchableOpacity>
 <TouchableOpacity onPress={goToDataList}><Text style={{ marginTop:10, fontSize:13}}>데이터 관리</Text></TouchableOpacity>
           <TouchableOpacity onPress={goToDataManager}><Text style={{ marginTop:10, fontSize:13}}>데이터 관리(final)</Text></TouchableOpacity>          
  */