import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image } from 'react-native';


const OneProblem = props => {
    const [answer, setanswer] = useState("");

    const set1 = ()=>{
        setanswer("1");
        
    }

    const set2 = ()=>{
        setanswer("2");
        
    }

    const set3 = ()=>{
        setanswer("3");
        
    }

    const set4 = ()=>{
        setanswer("4");
        
    }

    const set5 = ()=>{
        setanswer("5");
        
    }

    return(
      <View style={styles.container}>
         <Text>({props.number}) {props.problem} [{answer}]</Text>
         <TouchableOpacity onPress={set1}><Text> 1) {props.one}</Text></TouchableOpacity>
         <TouchableOpacity onPress={set2}><Text> 2) {props.two}</Text></TouchableOpacity>
         <TouchableOpacity onPress={set3}><Text> 3) {props.three}</Text></TouchableOpacity>
         <TouchableOpacity onPress={set4}><Text> 4) {props.four}</Text></TouchableOpacity>
         <TouchableOpacity onPress={set5}><Text> 5) {props.five}</Text></TouchableOpacity>
      </View>
    )
  }
  
  const styles = StyleSheet.create({
    container: {
        
     
      
      justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default OneProblem;