import { StatusBar } from 'expo-status-bar';
import { useState } from 'react';
import { StyleSheet, Text, View, FlatList,ScrollView,TouchableOpacity, ImagePropTypes } from 'react-native';
import OneProblem2 from './oneProblem2';
import Result from './result';
import Note1 from './note1';
import Note2 from './note2';

import { getDatabase, ref, set,push,onValue } from "firebase/database";


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP1D3r7nd_Ni5B0U47PtTbWG84wYlHxW0",
  authDomain: "logic-93c13.firebaseapp.com",
  databaseURL: "https://logic-93c13-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logic-93c13",
  storageBucket: "logic-93c13.appspot.com",
  messagingSenderId: "875422042858",
  appId: "1:875422042858:web:59118c540c88cdf989e593",
  measurementId: "G-T3BRD4NLWP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// 3, 2, 4, 4,4,2, 3,3,4,2
// const test_list = [
//     {
//       "Number":1,
//       "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
//       "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
//       "example1":"ㄱ. 이 문장 모임은 네 문장으로 이뤄졌다.",
//       "example2":"ㄴ. 이 문장 모임은 추론이다. ",
//       "1":"없음",
//       "2":"ㄱ",
//       "3":"ㄴ",
//       "4":"ㄱ,ㄴ",
//       "answer":"",
//       "correct":"3",
//       "note1":
//       {
//           "":"답을 선택하지 않았어요!!",
//           "1":"➀ ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. ‘추론’이 무엇인지 두보계 100 제001절을 읽으며 다시 살펴보세요.",
//           "2":"➁ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 안타깝게도 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 두보계 100 제001절을 읽으며 ‘추론’이 무엇인지 다시 살펴보세요.",
//           "4":"➃ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다."
//       },
//       "note2":"ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 이 문장 모임은 세 문장으로 이뤄졌습니다. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 이 문장 모임에는 “따라서”가 들어 있습니다. 이 때문에 이 문장 모임은 추론입니다. “따라서” 뒤에 나오는 문장은 이 문장 모임의 결론입니다. 결론이 있다는 것은 전제가 있음을 말해줍니다. 전제와 결론이 있는 문장 모임은 추론입니다. 정답은 ➂입니다."
//     },
//     {
//       "Number":2,
//       "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
//       "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
//       "example1":"ㄱ. 이 추론의 전제는 2개다.",
//       "example2":"ㄴ. 이 추론의 결론은 2개다.",
//       "1":"없음",
//       "2":"ㄱ",
//       "3":"ㄴ",
//       "4":"ㄱ,ㄴ",
//       "answer":"",
//       "correct":"2",
//       "note1":{
//         "":"답을 선택하지 않았어요!!",
//           "1":"➀ ㄱ은 이 문장 모임에 관해 옳게 말한 진술입니다. 전제가 무엇이고 결론이 무엇인지 가릴 수 없었나요? 추론에서 전제와 결론을 가리는 방법은 두보계 100 제001절을 읽어보세요.",
//           "3":"➂ ㄱ은 이 문장 모임에 관해 옳게 말한 진술입니다. 추론에서 전제가 무엇이고 결론이 무엇인지 가릴 수 없었나요? ㄴ은 이 문장 모임에 관해 그르게 말한 진술입니다. 추론에서 전제와 결론을 찾는 방법은 두보계 100 제001절을 읽어보세요.",
//           "4":"➃ ㄴ은 이 문장 모임에 관해 그르게 말한 진술입니다. 추론에서 전제와 결론을 찾는 방법은 두보계 100 제001절을 읽어보세요."
//       },
//       "note2":"ㄱ은 이 문장 모임에 관해 옳게 말한 진술입니다. 이 문장 모임은 세 문장인데 세 문장 가운데 하나는 결론이고 나머지 둘은 전제입니다. ㄴ은 이 문장 모임에 관해 그르게 말한 진술입니다. 이 문장 모임에는 추론 하나가 들어 있습니다. 한 추론에서 결론은 하나입니다. 정답은 ➁입니다."
//     },
//     {
//       "Number":3,
//       "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
//       "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
//       "example1":"ㄱ. 이 추론의 결론은 “우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다”다.",
//       "example2":"ㄴ. “우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다”는 이 추론의 전제다.",
//       "1":"없음",
//       "2":"ㄱ",
//       "3":"ㄴ",
//       "4":"ㄱ,ㄴ",
//       "answer":"",
//       "correct":"4",
//       "note1":{
//         "":"답을 선택하지 않았어요!!",
//           "1":"➀ ㄱ과 ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 추론에서 전제가 무엇이고 결론이 무엇인지 가릴 수 없었나요? 추론에서 전제와 결론을 찾는 방법은 두보계 100 제001절을 읽어보세요.",
//           "2":"➁ ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 추론에서 전제가 무엇인지 가릴 수 없었나요? 추론에서 전제를 찾는 방법은 두보계 100 제001절을 읽어보세요.",
//           "3":"➂ ㄱ은 이 문장 모임에 관해 옳게 말한 진술입니다. 추론에서 결론이 무엇인지 가릴 수 없었나요? 추론에서 결론을 찾는 방법은 두보계 100 제001절을 읽어보세요."
//       },
//       "note2":"ㄱ은 이 문장 모임에 관해 옳게 말한 진술입니다. “따라서” 뒤에 나오는 문장은 이 추론의 결론입니다. “따라서” 뒤에 “우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다”가 나오네요. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 추론에서 결론을 뺀 나머지 문장은 전제입니다. 이 추론에서 전제는 두 개입니다. 하나는 “우리 세계는 실현된 세계며 생각할 수 있는 세계다”입니다. 다른 하나는 “우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다”입니다. 그래서 “‘우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다’는 이 추론의 전제다”는 옳은 진술입니다. 정답은 ➃입니다."
//     },
//     {
//       "Number":4,
//       "problem":"다음 문장 짝들 가운데 같은 뜻으로 묶인 짝을 모두 모으면?",
//       "text":"",
//       "example1":"ㄱ. 알코올은 물보다 빨리 끓는다. “‘알코올은 물보다 빨리 끓는다’는 참이다”는 참이다.",
//       "example2":"ㄴ. “샛별은 행성이다”는 참이다는 참이다는 참이다. 샛별은 행성이다.",
//       "1":"없음",
//       "2":"ㄱ",
//       "3":"ㄴ",
//       "4":"ㄱ,ㄴ",
//       "answer":"",
//       "correct":"4",
//       "note1":{
//         "":"답을 선택하지 않았어요!!",
//           "1":"➀ ㄱ의 두 문장은 서로 뜻이 같습니다. ㄴ의 두 문장도 서로 뜻이 같습니다. 문장 “X는 참이다”와 문장 X가 뜻이 같다는 점을 몰랐나요? 두보계 100 제002절을 다시 읽어보세요.",
//           "2":"➁ ㄴ의 두 문장은 서로 뜻이 같습니다. 문장 “X는 참이다”와 문장 X가 뜻이 같다는 점을 몰랐나요? 두보계 100 제002절을 다시 읽어보세요.",
//           "3":"➂ ㄱ의 두 문장은 서로 뜻이 같습니다. 문장 “X는 참이다”와 문장 X가 뜻이 같다는 점을 몰랐나요? 두보계 100 제002절을 다시 읽어보세요."
//       },
//       "note2":"ㄱ의 두 문장은 서로 뜻이 같습니다. 문장 “X는 참이다는 참이다”는 문장 “X는 참이다”와 뜻이 같고, 문장 “X는 참이다”는 문장 X와 뜻이 같습니다. ㄴ의 두 문장은 서로 뜻이 같습니다. 문장 “X는 참이다는 참이다는 참이다”는 문장 “X는 참이다는 참이다”와 뜻이 같습니다. 나아가 문장 “X는 참이다는 참이다”는 문장 X와 뜻이 같습니다. 정답은 ➃입니다."
//     },
//     {
//       "Number":5,
//       "problem":"다음 문장 짝들 가운데 같은 뜻으로 묶인 짝을 모두 모으면?",
//       "text":"",
//       "example1":"ㄱ. “우주는 무한하다”는 거짓이다. “‘우주는 무한하다’는 거짓이다”는 참이다.",
//       "example2":"ㄴ. “명왕성은 태양계의 행성이다”는 거짓이다는 참이다. “명왕성은 태양계의 행성이다”는 참이다는 거짓이다.",
//       "1":"없음",
//       "2":"ㄱ",
//       "3":"ㄴ",
//       "4":"ㄱ,ㄴ",
//       "answer":"",
//       "correct":"4",
//       "note1":{
//         "":"답을 선택하지 않았어요!!",
//          "1":"➀ ㄱ의 두 문장은 서로 뜻이 같습니다. ㄴ의 두 문장도 서로 뜻이 같습니다. 한 자리 문장 바꾸개 “는 참이다”를 문장에서 없애거나 넣어도 문장의 뜻은 달라지지 않습니다. 두보계 100 제002절을 다시 읽어보세요.",
//          "2":"➁ ㄴ의 두 문장은 서로 뜻이 같습니다. 한 자리 문장 바꾸개 “는 참이다”를 문장에서 없애거나 넣어도 문장의 뜻은 달라지지 않습니다. 두보계 100 제002절을 다시 읽어보세요.",
//          "3":"➂ ㄱ의 두 문장은 서로 뜻이 같습니다. 한 자리 문장 바꾸개 “는 참이다”를 문장에서 없애거나 넣어도 문장의 뜻은 달라지지 않습니다. 두보계 100 제002절을 다시 읽어보세요."
//       },
//       "note2":"ㄱ의 두 문장은 서로 뜻이 같습니다. 한 자리 문장 바꾸개 “는 참이다”를 문장에서 없애도 뜻이 달라지지 않습니다. 왜냐하면 문장 “X는 참이다”는 문장 X와 뜻이 같기 때문입니다. “우주는 무한하다는 거짓이다는 참이다”에서 “는 참이다”를 없애도 되는데 이것은 “우주는 무한하다는 거짓이다”와 뜻이 같습니다. ㄴ의 두 문장은 서로 뜻이 같습니다. “명왕성은 태양계의 행성이다는 거짓이다는 참이다”는 “명왕성은 태양계의 행성이다는 거짓이다”와 뜻이 같습니다. “명왕성은 태양계의 행성이다는 거짓이다”는 “명왕성은 태양계의 행성이다는 참이다는 거짓이다”와 뜻이 같습니다. 한 자리 문장 바꾸개 “는 참이다”를 문장에 넣어도 뜻이 달라지지 않습니다. 왜냐하면 문장 X는 문장 “X는 참이다”와 뜻이 같기 때문입니다. 정답은 ➃입니다."
//     },
//     {
//         "Number":6,
//         "problem":"다음 문장 짝들 가운데 같은 뜻으로 묶인 짝을 모두 모으면?",
//         "text":"",
//         "example1":"ㄱ. “세종은 언어학자거나 음악학자다”는 거짓이다는 거짓이다. 세종은 언어학자거나 음악학자다.",
//         "example2":"ㄴ. “모든 예술품은 상품이 아니다”는 거짓이다. 모든 예술품은 상품이다.",
//         "1":"없음",
//         "2":"ㄱ",
//         "3":"ㄴ",
//         "4":"ㄱ,ㄴ",
//         "answer":"",
//         "correct":"2",
//         "note1":{
//             "":"답을 선택하지 않았어요!!",
//             "1":"➀ ㄱ의 두 문장은 서로 뜻이 같습니다. “X는 거짓이다는 거짓이다”와 문장 X가 뜻이 같음을 몰랐나요? 두보계 100 제003절을 다시 읽어보세요.",
//             "3":"➂ ㄱ의 두 문장은 서로 뜻이 같습니다. “X는 거짓이다는 거짓이다”와 문장 X가 뜻이 같음을 몰랐나요? 두보계 100 제003절을 다시 읽어보세요. ㄴ의 두 문장은 뜻이 다릅니다. “모든”, “어느”, “어떠한”, “어떤”, “몇몇” 따위가 들어간 문장에서 “아니다”는 “거짓이다”와 뜻이 다릅니다. 이는 나중에 두보계 100 제054절 이후에 배웁니다.",
//             "4":"➃ ㄴ의 두 문장은 뜻이 다릅니다. “모든”, “어느”, “어떠한”, “어떤”, “몇몇” 따위가 들어간 문장에서 “아니다”는 “거짓이다”와 뜻이 다릅니다. 이는 나중에 두보계 100 제054절 이후에 배웁니다."
//         },
//         "note2":"ㄱ의 두 문장은 서로 뜻이 같습니다. 한 자리 문장 바꾸개 “는 거짓이다”가 곧이어 두 번 나오면 그것은 “는 참이다”와 같게 됩니다. 다시 말해 문장 “X는 거짓이다는 거짓이다”는 문장 “X는 참이다”와 뜻이 같고 이는 다시 문장 X와 뜻이 같습니다. ㄴ의 두 문장은 뜻이 다릅니다. “아니다”와 “거짓이다”는 뜻이 같을 때가 많지만 다를 때도 있습니다. “모든 예술품은 상품이 아니다”는 “‘모든 예술품은 상품이다’는 거짓이다”와 뜻이 다릅니다. “‘모든 예술품은 상품이다’는 거짓이다는 거짓이다”는 “모든 예술품은 상품이다”와 뜻이 같습니다. 하지만 “‘모든 예술품은 상품이 아니다’는 거짓이다”와 “모든 예술품은 상품이다”는 뜻이 다릅니다. “모든”, “어느”, “어떠한”, “어떤”, “몇몇” 따위가 들어간 문장에서 “아니다”는 “거짓이다”와 뜻이 다르니 조심해야 합니다. 정답은 ➁입니다."
//       },
//       {
//         "Number":7,
//         "problem":"다음 주장들 가운데 참인 것들만 모두 모으면?",
//         "text":"",
//         "example1":"ㄱ. “우리 세계는 있고 다른 세계는 생각될 수 있다”는 두 개의 문장이다.",
//         "example2":"ㄴ. “우리 세계는 있고 다른 세계는 생각될 수 있다”는 이고문장이다.",
//         "1":"없음",
//         "2":"ㄱ",
//         "3":"ㄴ",
//         "4":"ㄱ,ㄴ",
//         "answer":"",
//         "correct":"3",
//         "note1":{
//             "":"답을 선택하지 않았어요!!",
//             "1":"➀ ㄴ은 참입니다. “이고문장”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
//             "2":"➁ ㄱ은 거짓입니다. 문장의 개수를 어떻게 헤아려야 하는지 다시 생각해 보세요. ㄴ은 참입니다. “이고문장”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
//             "4":"➃ ㄱ은 거짓입니다. 문장의 개수를 어떻게 헤아려야 하는지 다시 생각해 보세요."
//         },
//         "note2":"ㄱ은 거짓입니다. “우리 세계는 있고 다른 세계는 생각될 수 있다”는 한 문장입니다. ㄴ은 참입니다. “우리 세계는 있고 다른 세계는 생각될 수 있다”는 이고문장입니다. 여기서 “이고문장”은 다른 말로 “연언문” 또는 “연언문장”입니다. 정답은 ➂입니다."
//       },
//       {
//         "Number":8,
//         "problem":"다음 주장들 가운데 참인 것들만 모두 모으면?",
//         "text":"",
//         "example1":"ㄱ. “오리너구리는 알을 낳고 젖을 먹인다”에서 이고 뒷말은 “젖을 먹인다”다.",
//         "example2":"ㄴ. “실현된 세계는 하나밖에 없거나 우리 세계는 유일한 실현 세계다”에서 이거나 앞말은 “실현된 세계는 하나밖에 없다”다.",
//         "1":"없음",
//         "2":"ㄱ",
//         "3":"ㄴ",
//         "4":"ㄱ,ㄴ",
//         "answer":"",
//         "correct":"3",
//         "note1":{
//             "":"답을 선택하지 않았어요!!",
//             "1":"➀ ㄴ은 참입니다. “이거나 앞말”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
//             "2":"➁ ㄱ은 거짓입니다. 이고 뒷말의 임자말이 이고 앞말의 임자말과 같을 때 이고 뒷말의 임자말을 안 쓸 때가 많습니다. 보기를 들어 “칸트는 똑똑하고 칸트는 착하다”를 짧게 “칸트는 똑똑하고 착하다”로 씁니다. ㄴ은 참입니다. “이고 뒷말”이나 “이거나 앞말”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
//             "4":"➃ ㄱ은 거짓입니다. 이고 뒷말의 임자말이 이고 앞말의 임자말과 같을 때 이고 뒷말의 임자말을 안 쓸 때가 많습니다. 보기를 들어 “칸트는 똑똑하고 칸트는 착하다”를 짧게 “칸트는 똑똑하고 착하다”로 씁니다. “이고 뒷말”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요."
//         },
//         "note2":"ㄱ은 거짓입니다. “오리너구리는 알을 낳고 젖을 먹인다”는 “오리너구리는 알을 낳고 오리너구리는 젖을 먹인다”를 짧게 쓴 문장입니다. “오리너구리는 알을 낳고 젖을 먹인다”는 이고문장이고 이 문장에서 이고 뒷말은 “오리너구리는 젖을 먹인다”입니다. 여기서 “이고문장”은 다른 말로 “연언문” 또는 “연언문장”입니다. ㄴ은 참입니다. “실현된 세계는 하나밖에 없거나 우리 세계는 유일한 실현 세계다”는 이거나문장이고 이 문장에서 이거나 앞말은 “실현된 세계는 하나밖에 없다”입니다. 여기서 “이거나문장”은 다른 말로 “선언문” 또는 “선언문장”입니다. 정답은 ➂입니다."
//       },  

//       {
//         "Number":9,
//         "problem":"다음 주장들 가운데 참인 것들만 모두 모으면?",
//         "text":"",
//         "example1":"ㄱ. “철수나 영희는 착하다”는 두 문장이 “이거나”로 이어진 이거나문장이다.",
//         "example2":"ㄴ. “만일 내가 다른 세계들을 생각할 수 있다면 가능 세계는 여럿이다”에 쓰인 문장 바꾸개는 “이면”이다.",
//         "1":"없음",
//         "2":"ㄱ",
//         "3":"ㄴ",
//         "4":"ㄱ,ㄴ",
//         "answer":"",
//         "correct":"4",
//         "note1":{
//             "":"답을 선택하지 않았어요!!",
//             "1":"➀ ㄱ은 참입니다. 이거나 앞말과 이거나 뒷말의 풀이말이 같다면 두 임자말 사이에 “이거나”를 넣기도 합니다. ㄴ은 참입니다. “이거나문장”의 뜻을 몰랐나요? “문장 바꾸개”의 뜻을 몰랐나요? 두보계 100 제004절을 다시 읽어보세요.",
//             "2":"➁ ㄴ은 참입니다. “문장 바꾸개”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
//             "3":"➂ ㄱ은 참입니다. 이거나 앞말과 이거나 뒷말의 풀이말이 같다면 두 임자말 사이에 “이거나”를 넣기도 합니다. “이거나문장”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요."
//         },
//         "note2":"ㄱ은 참입니다. “철수나 영희는 착하다”는 “철수는 착하거나 영희는 착하다”를 짧게 쓴 문장입니다. 이 문장은 “이거나”로 이어진 이거나문장입니다. 여기서 “이거나문장”은 다른 말로 “선언문” 또는 “선언문장”입니다. ㄴ은 참입니다. “만일 내가 다른 세계들을 생각할 수 있다면 가능 세계는 여럿이다”는 이면문장입니다. 이 문장에 쓰인 문장 이음씨는 “이면”입니다. “이면”은 두 자리 문장 바꾸개입니다. 여기서 “이면문장”은 다른 말로 “조건문” 또는 “조건문장”입니다. “문장 이음씨”는 다른 말로 “문장연결사”고 “문장 바꾸개”는 다른 말로 “문장연산자”입니다. 정답은 ➃입니다."
//       },
//       {
//         "Number":10,
//         "problem":"다음 주장들 가운데 참인 것들만 모두 모으면?",
//         "text":"",
//         "example1":"ㄱ. “내가 다른 세계들을 생각할 수 있다면 가능 세계는 여럿이다”의 이면 앞말과 뒷말을 바꾸어 만든 문장은 이면문장이다.",
//         "example2":"ㄴ. “‘실현된 세계는 하나밖에 없다’는 거짓이다”에서 “는 거짓이다”는 두 자리 문장 바꾸개다.",
//         "1":"없음",
//         "2":"ㄱ",
//         "3":"ㄴ",
//         "4":"ㄱ,ㄴ",
//         "answer":"",
//         "correct":"2",
//         "note1":{
//             "":"답을 선택하지 않았어요!!",
//             "1":"➀ ㄱ은 참입니다. “이고 앞말”, “이고 뒷말”, “이고문장”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
//             "3":"➂ ㄱ은 참입니다. “이고 앞말”, “이고 뒷말”, “이고문장”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요. ㄴ은 거짓입니다. “두 자리 문장 바꾸개”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
//             "4":"➃ ㄴ은 거짓입니다. “두 자리 문장 바꾸개”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요."
//         },
//         "note2":"ㄱ은 참입니다. “내가 다른 세계들을 생각할 수 있다면 가능 세계는 여럿이다”의 이면 앞말과 뒷말을 바꾸어 만든 문장은 “가능 세계는 여럿이면 나는 다른 세계들을 생각할 수 있다”입니다. 이 문장은 이면문장입니다. 여기서 “이면문장”은 다른 말로 “조건문” 또는 “조건문장”입니다. ㄴ은 거짓입니다. “는 거짓이다”는 한 자리 문장 바꾸개입니다. 여기서 “문장 바꾸개”는 다른 말로 “문장연산자”입니다. 정답은 ➁입니다."
//       },
// ]

const Content2 = props => {
    const [screen, setScreen] = useState(props.problem_number);
    const [redraw, setredraw] = useState("0");
    const [grade, setgrade] = useState(0);
    const [error, seterror] = useState([]);
    const [error_mode, seterrormode] = useState("0");
    const [num_of_problem, setnumber] = useState(props.test_list.length);
    
    //const [test_list,setTestList] = useState(props.test)

    //const [pa, setpa] = useState([]);
    // const [pa1, setpa] = useState(0);
    // const [pa1, setpa] = useState(0);
    // const [pa1, setpa] = useState(0);
    // const [pa1, setpa] = useState(0);
    // const [pa1, setpa] = useState(0);
    // const [pa1, setpa] = useState(0);
    // const [pa1, setpa] = useState(0);
    // const [pa1, setpa] = useState(0);
    // const [pa1, setpa] = useState(0);
    //let number = 0;
    const set_answer = (i,val)=>{
        props.test_list[i]["answer"] = val;
        console.log(i,val);
        console.log(props.test_list[i]["answer"]);
    }
    const goToBack = ()=>{

        //props.onChange(props.backNumber)
        props.onChange(38)
    }
    const setPrev = ()=>{
        if(props.test_list.length == 0){
            return;
        }
        if(screen > 1){
        //number = number - 1;
        const db = getDatabase();
        const id = props.userInfo.user.replace(".", "A");
        set(ref(db, 'User_History/'+id), {
            test: props.test_list,
            name: props.name,
            test_id: props.test_id,
            type1: props.type1,
            type2: props.type2, 
            type3: props.type3, 
            number:screen-1
          });
        setScreen(screen-1);
        
        }
        //setScreen(number);
        
    }

    const setNext = ()=>{
        if(props.test_list.length == 0){
            return;
        }
        if(screen < num_of_problem){
        //number = number + 1
        const db = getDatabase();
        const id = props.userInfo.user.replace(".", "A");
        set(ref(db, 'User_History/'+id), {
            test: props.test_list,
            name: props.name,
            test_id: props.test_id,
            type1: props.type1,
            type2: props.type2, 
            type3: props.type3, 
            number:screen+1
          });
        setScreen(screen+1);
        }
        //setScreen(screen);
        
    }
    const retry = () =>{
        seterrormode("0");
        setScreen(1);
        setnumber(props.test_list.length)
    }

    const next = () =>{

        // if(props.test_id < 3){
        //     props.setTestId(props.test_id + 1);
        //     props.next_id_store(props.test_id + 1)
        //     // const db = getDatabase();
        //     // const id = props.userInfo.user.replace(".", "A");
        //     // const num = props.test_id + 1
        //     // set(ref(db, 'User_History/'+id), {
        //     //     test: props.test_list,
        //     //     name: "test_"+num,
        //     //     test_id: num,
        //     //     number:1
        //     // });
        // }else{
        //     props.setTestId(1);
        // }
        props.next_id_store(props.test_id)
        seterrormode("0");
        setScreen(1);
        setnumber(props.test_list.length)

        goToBack()

        

        // const db = getDatabase();
        // const id = props.userInfo.user.replace(".", "A");
        // set(ref(db, 'User_History/'+id), {
        //     test: props.test_list,
        //     name: props.name,
        //     test_id: props.test_id,
        //     number:screen+1
        //   });
        // seterrormode("0");
        // setScreen(1);
        // setnumber(test_list.length)
    }
    const setCount = ()=>{
        if(props.test_list.length == 0){
            return;
        }
        var correct = 0;
        var error_list = [];
        for(let i = 0 ; i < props.test_list.length; i++){
            if(props.test_list[i]["answer"] == props.test_list[i]["correct"]){
                correct = correct + 1;
            }else{
                error_list.push(props.test_list[i]);
            }
        }

        setgrade(correct);
        setScreen(11);
        seterror(error_list);
        setnumber(error_list.length)

        const db = getDatabase();
        const id = props.userInfo.user.replace(".", "A");
        if(props.score_history != null){
           if(props.score_history[id] != null){
            if(props.score_history[id].score != null){
                props.score_history[id].score.push({name:props.name, score:correct, time:new Date().toString()})
                set(ref(db, 'Score_History/'+id), {
                    score: props.score_history[id].score,
                    
                  });
            }else{
                //props.score_history[id].score = [];
            }
           }else{
            let score_list = [];
            score_list.push({name:props.name, score:correct, time:new Date().toString()})
            set(ref(db, 'Score_History/'+id), {
                score: score_list,
                
              });
            let tmp_score_history={};
            tmp_score_history[id]={score:score_list};

            props.setScoreHistory(tmp_score_history);
           }
            
        }else{
            //props.score_history[id]=[];
            let score_list = [];
            score_list.push({name:props.name, score:correct, time:new Date().toString()})
            set(ref(db, 'Score_History/'+id), {
                score: score_list,
                
              });
            let tmp_score_history={};
            tmp_score_history[id]={score:score_list};

            props.setScoreHistory(tmp_score_history);

        }
        //props.score_history[id].score.push({name:props.name, score:correct, time:new Date().toString()});
        
    }
    const goToNote1 = ()=>{
        seterrormode("1");
        setScreen(1);
    }
    const goToNote2 = ()=>{
        seterrormode("2");
        setScreen(1);
        setnumber(props.test_list.length)
    }
    let content2;
    let list;
    if(error_mode == "0" || error_mode == "2"){
       list = props.test_list;
    }else{
       list = error;
    }
    console.log("-----> list>",list)
    let test = list[screen-1];
    let top_label;
    // if(screen == 1){
    //     test = test_list[0];    
    // }
    // if(screen == 2){
    //     test = test_list[1];    
    // }
    // if(screen == 3){
    //     test = test_list[2];    
    // }
    // if(screen == 4){
    //     test = test_list[3];   
    // }
    // if(screen == 5){
    //     test = test_list[4];   
    // }

    //content2 = <OneProblem2 redraw={redraw} setredraw={setredraw} set_answer={set_answer} answer={test["answer"]} text={test["text"]} example1={test['example1']} example2={test['example2']} number={test["Number"]} problem={test["problem"]} one={test["1"]} two={test["2"]} three={test["3"]} four={test["4"]} />;
    if(screen == 11){
        content2 = <Result goToNote1={goToNote1} goToNote2={goToNote2} grade={grade} retry={retry} next={next}/>
        top_label =<View style={{justifyContent: 'flex-start', width:300, marginTop:0, alignItems:'center'}}><Text style={{ marginTop:15,fontSize:20, fontWeight: 'bold'}}>최종 점수</Text></View>
    }else{
      if(list.length == 0){
        top_label = <Text>데이터가 없어요 </Text>
        content2 = <Text></Text>
      }else{
        top_label = <Text style={{fontSize:15, fontWeight: 'bold'}}>문항 {screen}. </Text>
        if(error_mode == "1"){
            top_label = <Text style={{fontSize:15, fontWeight: 'bold'}}>틀린 문항 {test["Number"]}. </Text>
        }
        content2 = <OneProblem2 redraw={redraw} setredraw={setredraw} set_answer={set_answer} answer={test["answer"]} text={test["text"]} example1={test['example1']} example2={test['example2']} number={test["Number"]} problem={test["problem"]} one={test["1"]} two={test["2"]} three={test["3"]} four={test["4"]} />;
        if(error_mode == "1"){
            content2 = <Note1 goToNote2={goToNote2} retry={retry} redraw={redraw} setredraw={setredraw} set_answer={set_answer} answer={test["answer"]} text={test["text"]} example1={test['example1']} example2={test['example2']} number={test["Number"]} problem={test["problem"]} one={test["note1"][test["answer"]]}   />; 
        }else if(error_mode == "2"){
            content2 = <Note2 redraw={redraw} retry={retry} next={next} setredraw={setredraw} set_answer={set_answer} grade={grade} answer={test["answer"]} text={test["text"]} example1={test['example1']} example2={test['example2']} number={test["Number"]} problem={test["problem"]} note2={test["note2"]}   />; 
        }
      }
    }
    //content2 = <OneSlide theme={theme} text1={text1} text2={text2} text3={text3} text4={text4} text5={text5} text6={text6} test={test}/>
    return (
        <View style={styles.container}>
            
            
            
          
          <Text style={{fontSize:16,marginBottom:20, fontWeight: 'bold'}}>{props.name}</Text>
          <View style={{justifyContent: 'flex-start', width:300, marginTop:50, alignItems:'flex-start'}}>
          {top_label}
          </View>
          <View style={{justifyContent: 'flex-start', width:300, marginTop:0, alignItems:'center'}}>
          {content2}
          </View>
          
          <View style={{flexDirection:'row',width:300,marginTop:30,justifyContent: 'space-between'}}>
            <TouchableOpacity onPress={setPrev}><Text style={{fontSize:15, fontWeight: 'bold'}}>앞 문항</Text></TouchableOpacity>
            <TouchableOpacity onPress={setNext}><Text style={{fontSize:15, fontWeight: 'bold'}}>다음 문항</Text></TouchableOpacity>
          </View>
          <TouchableOpacity onPress={setCount}><Text style={{fontSize:20,marginTop:30, fontWeight: 'bold'}}>채점하기</Text></TouchableOpacity>
          <TouchableOpacity onPress={goToBack}><Text style={{fontSize:20,marginTop:20, fontWeight: 'bold'}}>테스트 목록</Text></TouchableOpacity>
        </View>
      );
}

const styles = StyleSheet.create({
    container: {
      marginTop:10,
      
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
  
export default Content2;

/*
<Text>[Problem] {screen} </Text>
*/