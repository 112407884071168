import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image } from 'react-native';

const Result_option1 = props =>{
   return(
       <View>
           <TouchableOpacity onPress={props.retry}><Text style={{fontWeight: 'bold', marginTop:50, fontSize:20}}>{props.option1}</Text></TouchableOpacity>
           <TouchableOpacity onPress={props.goToNote1}><Text style={{fontWeight: 'bold', marginTop:5, fontSize:20}}>{props.option2}</Text></TouchableOpacity>
       </View>
   )
}

const Result_option2 = props =>{
   return(
       <View>
           <TouchableOpacity onPress={props.goToNote2}><Text style={{fontWeight: 'bold', marginTop:50, fontSize:20}}>{props.option1}</Text></TouchableOpacity>
           <TouchableOpacity onPress={props.next}><Text style={{fontWeight: 'bold', marginTop:5, fontSize:20}}>{props.option2}</Text></TouchableOpacity>
       </View>
   )
}
const Result = props => {

    let content;
    if(props.grade < 10){
       content = <Result_option1 goToNote1={props.goToNote1} retry={props.retry} option1="다시 풀기" option2="오답 노트"/>
    }else{
       content = <Result_option2 goToNote2={props.goToNote2} next={props.next} option1="정답 해설" option2="다음 물음"/>
    }
    return(
        <View style={styles.container}>
           

           <Text style={{ marginTop:50, marginBottom:0,fontWeight: 'bold',color: 'red', fontSize:40}}>{props.grade}</Text>
           {content}
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
      justifyContent: 'flex-start',
      alignItems:"center",
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default Result;
/*
<Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>최종 점수</Text>
  */