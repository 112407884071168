import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList } from 'react-native';


//import { initializeApp } from "firebase/app";
//import { getDatabase } from "firebase/database";
import { getDatabase, ref, set,push,onValue } from "firebase/database";
//import database from "firebase/compat/database";
//import database from '@react-native-firebase/database';
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB0KZSGiW5MSuseDACnXhv3fBjLjYH6g0A",
//   authDomain: "emailverification-c32c5.firebaseapp.com",
//   databaseURL: "https://emailverification-c32c5.firebaseio.com",
//   projectId: "emailverification-c32c5",
//   storageBucket: "emailverification-c32c5.appspot.com",
//   messagingSenderId: "940289013912",
//   appId: "1:940289013912:web:5547b11a7c64cfe52ef8ed",
//   measurementId: "G-S0VPE37BPP"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP1D3r7nd_Ni5B0U47PtTbWG84wYlHxW0",
  authDomain: "logic-93c13.firebaseapp.com",
  databaseURL: "https://logic-93c13-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logic-93c13",
  storageBucket: "logic-93c13.appspot.com",
  messagingSenderId: "875422042858",
  appId: "1:875422042858:web:59118c540c88cdf989e593",
  measurementId: "G-T3BRD4NLWP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
/*
{
     "Number":1,
     "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
     "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
     "example1":"ㄱ. 이 문장 모임은 네 문장으로 이뤄졌다.",
     "example2":"ㄴ. 이 문장 모임은 추론이다. ",
     "1":"없음",
     "2":"ㄱ",
     "3":"ㄴ",
     "4":"ㄱ,ㄴ",
     "answer":"",
     "correct":"3",
     "note1":
     {
         "":"답을 선택하지 않았어요!!",
         "1":"➀ ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. ‘추론’이 무엇인지 두보계 100 제001절을 읽으며 다시 살펴보세요.",
         "2":"➁ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 안타깝게도 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 두보계 100 제001절을 읽으며 ‘추론’이 무엇인지 다시 살펴보세요.",
         "4":"➃ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다."
     },
     "note2":"ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 이 문장 모임은 세 문장으로 이뤄졌습니다. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 이 문장 모임에는 “따라서”가 들어 있습니다. 이 때문에 이 문장 모임은 추론입니다. “따라서” 뒤에 나오는 문장은 이 문장 모임의 결론입니다. 결론이 있다는 것은 전제가 있음을 말해줍니다. 전제와 결론이 있는 문장 모임은 추론입니다. 정답은 ➂입니다."
   }
*/
const DataItem = props =>{
    const goToDataView = ()=>{
        props.setdata300(props.content);
        //props.setID(props.id);
        props.setdata300name(props.name);
        props.setpublish(props.publish);
        props.onChange(45);
    }
    return(
        <View>
            <TouchableOpacity onPress={goToDataView}><Text style={{ marginTop:5, fontSize:13}}>{props.name}</Text></TouchableOpacity>
            
        </View>
    )
 }
const ViewList300 = props => {
    //const [redraw, setredraw] = useState("0");
    //const [test_all, setT] = useState([]);

    const goToBack = ()=>{

        props.onChange(18)//props.backNumber
        //props.onChange(props.backNumber)//props.backNumber

        // if(props.type1 == "TOLT010"){
        //   props.onChange(30)
        // }
        // if(props.type1 == "TOLT020"){
        //   props.onChange(31)
        // }
        // if(props.type1 == "TOLT030"){
        //   props.onChange(32)
        // }
        // if(props.type1 == "TOLT040"){
        //   props.onChange(33)
        // }
        // if(props.type1 == "TOLT100"){
        //   props.onChange(34)
        // }

    }

    // const goToUpload = ()=>{

    //     props.onChange(42)
    //     // props.onSetPre(8)
    //     // props.onChange(17)
    // }

    var test_all = []
    for(let i = 0 ; i < props.test_data["300"].length; i++){
       if(props.test_data["300"][i].publish == "YES"){
        test_all.push(props.test_data["300"][i])
       }
         
    
   }
    // if(redraw == "0"){
    //     const db = getDatabase();
        
    //     const starCountRef = ref(db, 'TOLT_EL1/');
    //     onValue(starCountRef, (snapshot) => {
    //       const data = snapshot.val();
    //       //test_all=data.slice(1,5);
    //       console.log(" data len >", data.length);
    //       console.log("=====>",data.slice(1,data.length));
    //       //setT(data.slice(1,5));
    //       let data2 = data.slice(1,data.length);
    //       for(let i = 0; i < data2.length; i++){
    //           if(data2[i]){
    //             test_all.push(data2[i])
    //           }
            
    //       }
    //       // test_all.push(data.slice(1,5)["0"])
    //       // test_all.push(data.slice(1,5)["1"])
    //       // test_all.push(data.slice(1,5)["2"])
    //       // test_all.push(data.slice(1,5)["3"])
    //       setT(test_all);
    //       setredraw("1");
    //       console.log("test all ===>",test_all);
    //       //props.setdatalength(test_all.length)
    //       //updateStarCount(postElement, data);
    //     });
    //   }


    return(
        <View style={styles.container}>
            
            <View style={{flexDirection:'row',width:300,justifyContent: 'space-between'}}>
            <TouchableOpacity onPress={goToBack}><Text style={{fontSize:16, fontWeight: 'bold'}}>Back</Text></TouchableOpacity>
            
          </View>
           <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>View Data List 300</Text>

           

           <FlatList
               
                    
                    data={test_all}
                    height={600}
                    
                    renderItem={({ item }) => <DataItem setpublish={props.setpublish} setdata300={props.setdata300} setdata300name={props.setdata300name} publish={item["publish"]}  name={item["name"]} content={item['content']} onChange={props.onChange} />
                    
                        
                    
                    }
                    keyExtractor={(item, index) => index.toString()}
                        />
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        alignItems: 'center',
        justifyContent: 'center',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default ViewList300;
/*
<TouchableOpacity onPress={goToUpload}><Text style={{fontSize:16, fontWeight: 'bold'}}>Data Upload 300</Text></TouchableOpacity>
  */