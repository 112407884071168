import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image, TextInput } from 'react-native';
import Upload from './upload'

//import { initializeApp } from "firebase/app";
//import { getDatabase } from "firebase/database";
import { getDatabase, ref, set,push,onValue } from "firebase/database";
//import database from "firebase/compat/database";
//import database from '@react-native-firebase/database';
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB0KZSGiW5MSuseDACnXhv3fBjLjYH6g0A",
//   authDomain: "emailverification-c32c5.firebaseapp.com",
//   databaseURL: "https://emailverification-c32c5.firebaseio.com",
//   projectId: "emailverification-c32c5",
//   storageBucket: "emailverification-c32c5.appspot.com",
//   messagingSenderId: "940289013912",
//   appId: "1:940289013912:web:5547b11a7c64cfe52ef8ed",
//   measurementId: "G-S0VPE37BPP"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP1D3r7nd_Ni5B0U47PtTbWG84wYlHxW0",
  authDomain: "logic-93c13.firebaseapp.com",
  databaseURL: "https://logic-93c13-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logic-93c13",
  storageBucket: "logic-93c13.appspot.com",
  messagingSenderId: "875422042858",
  appId: "1:875422042858:web:59118c540c88cdf989e593",
  measurementId: "G-T3BRD4NLWP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
/*
{
     "Number":1,
     "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
     "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
     "example1":"ㄱ. 이 문장 모임은 네 문장으로 이뤄졌다.",
     "example2":"ㄴ. 이 문장 모임은 추론이다. ",
     "1":"없음",
     "2":"ㄱ",
     "3":"ㄴ",
     "4":"ㄱ,ㄴ",
     "answer":"",
     "correct":"3",
     "note1":
     {
         "":"답을 선택하지 않았어요!!",
         "1":"➀ ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. ‘추론’이 무엇인지 두보계 100 제001절을 읽으며 다시 살펴보세요.",
         "2":"➁ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 안타깝게도 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 두보계 100 제001절을 읽으며 ‘추론’이 무엇인지 다시 살펴보세요.",
         "4":"➃ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다."
     },
     "note2":"ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 이 문장 모임은 세 문장으로 이뤄졌습니다. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 이 문장 모임에는 “따라서”가 들어 있습니다. 이 때문에 이 문장 모임은 추론입니다. “따라서” 뒤에 나오는 문장은 이 문장 모임의 결론입니다. 결론이 있다는 것은 전제가 있음을 말해줍니다. 전제와 결론이 있는 문장 모임은 추론입니다. 정답은 ➂입니다."
   }
*/

const Upload2 = props => {
    const [screen, setScreen] = useState(1);
    const [redraw, setredraw] = useState("0");
    const [redraw2, setredraw2] = useState("0");
    const [test_name, settest] = useState("");
    const [test_size, setsize] = useState(0);
    const [p1,setp1] = useState({});
    const [p2,setp2] = useState({});
    const [p3,setp3] = useState({});
    const [p4,setp4] = useState({});
    const [p5,setp5] = useState({});
    const [p6,setp6] = useState({});
    const [p7,setp7] = useState({});
    const [p8,setp8] = useState({});
    const [p9,setp9] = useState({});
    const [p10,setp10] = useState({});
    // const [problem, setproblem] = useState("");
    // const [text, settext] = useState("");
    // const [example1, setexample1] = useState("");
    // const [example2, setexample2] = useState("");
    // const [correct, setcorrect] = useState("");
    // const [note1_1, setnote1_1] = useState("");
    // const [note1_2, setnote1_2] = useState("");
    // const [note1_3, setnote1_3] = useState("");
    // const [note1_1_detail, setnote1_1_detail] = useState("");
    // const [note1_2_detail, setnote1_2_detail] = useState("");
    // const [note1_3_detail, setnote1_3_detail] = useState("");
    // const [note2, setnote2] = useState("");

    const goToBack = ()=>{

        props.onChange(8)
    }

    const finalstore = ()=>{

        //props.onChange(8)
        let final_test=[];

        final_test.push(p1);
        final_test.push(p2);
        final_test.push(p3);
        final_test.push(p4);
        final_test.push(p5);

        final_test.push(p6);
        final_test.push(p7);
        final_test.push(p8);
        final_test.push(p9);
        final_test.push(p10);

        console.log("final tst >", final_test);
        const db = getDatabase();
        let v = test_size + 1;
        set(ref(db, 'TOLT_EL1/'+v), {
            test: final_test,
            name: test_name,
            id: v
        });

        goToBack();
    }



    const store = (number, problem, text, example1, example2,correct,note1_1,note1_1_detail,note1_2,note1_2_detail,note1_3,note1_3_detail,note2)=>{
        let data = {
                "Number":number,
                "problem":problem,
                "text":text,
                "example1":example1,
                "example2":example2,
                "1":"없음",
                "2":"ㄱ",
                "3":"ㄴ",
                "4":"ㄱ,ㄴ",
                "answer":"-1",
                "correct":correct,
                "note1":
                {
                    "-1":"답을 선택하지 않았어요!!",
                    note1_1:note1_1_detail,
                    note1_2:note1_2_detail,
                    note1_3:note1_3_detail
                },
                "note2":note2
        }
        console.log("============================================data >", data)
        if(number == 1){
            console.log("1 problem,,,,,,");
            setp1(data)
        }
        if(number == 2){
            setp2(data)
        }
        if(number == 3){
            setp3(data)
        }
        if(number == 4){
            setp4(data)
        }
        if(number == 5){
            setp5(data)
        }
        if(number == 6){
            setp6(data)
        }
        if(number == 7){
            setp7(data)
        }
        if(number == 8){
            setp8(data)
        }
        if(number == 9){
            setp9(data)
        }
        if(number == 10){
            setp10(data)
        }
        //props.onChange(9)
    }

    if(redraw2 == "0"){
        const db = getDatabase();
        
        const starCountRef = ref(db, 'TOLT_EL1/');
        onValue(starCountRef, (snapshot) => {
          const data = snapshot.val();
          //test_all=data.slice(1,5);
          console.log(" data len >", data.length);
          console.log("=====>",data.slice(1,data.length));
          //setT(data.slice(1,5));
          let data2 = data.slice(1,data.length);
          setsize(data2[data2.length-1].id);
        //   for(let i = 0; i < data2.length; i++){
        //     test_all.push(data2[i])
        //   }
          // test_all.push(data.slice(1,5)["0"])
          // test_all.push(data.slice(1,5)["1"])
          // test_all.push(data.slice(1,5)["2"])
          // test_all.push(data.slice(1,5)["3"])
          //setT(test_all);
          setredraw2("1");
          //console.log("test all ===>",test_all);
          //props.setdatalength(test_all.length)
          //updateStarCount(postElement, data);
        });
      }
    let content;

    content = <Upload number={screen} store={store} setScreen={setScreen}/>
    return(
        <View style={styles.container}>
            <View style={{flexDirection:'row',width:300,justifyContent: 'space-between'}}>
            <TouchableOpacity onPress={goToBack}><Text style={{fontSize:16, fontWeight: 'bold'}}>Back</Text></TouchableOpacity>
            <TouchableOpacity onPress={finalstore}><Text style={{fontSize:16, fontWeight: 'bold'}}>저장</Text></TouchableOpacity>
          </View>
           <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>Data Upload</Text>

           <Text>Test Name</Text>
           <TextInput  onChangeText={v => settest(v)}
          value={test_name} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           
           {content}
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        alignItems: 'center',
        justifyContent: 'center',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
  export default Upload2;