import { StatusBar } from 'expo-status-bar';
import { useState } from 'react';
import { StyleSheet, Text, View, FlatList,ScrollView,TouchableOpacity } from 'react-native';
import OneProblem from './component/oneProblem';
import OneText from './component/oneText';
import OneSlide from './component/oneSlide';
import Main from './component/main'
import MainData from './component/maindata'
import Login from './component/login'
import SignUp from './component/signup'
//var ls = require('react-native-local-storage');
import AsyncStorage from '@react-native-async-storage/async-storage';
// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getDatabase } from "firebase/database";
import { getDatabase, ref, set,push,onValue } from "firebase/database";
//import database from "firebase/compat/database";
//import database from '@react-native-firebase/database';
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB0KZSGiW5MSuseDACnXhv3fBjLjYH6g0A",
//   authDomain: "emailverification-c32c5.firebaseapp.com",
//   databaseURL: "https://emailverification-c32c5.firebaseio.com",
//   projectId: "emailverification-c32c5",
//   storageBucket: "emailverification-c32c5.appspot.com",
//   messagingSenderId: "940289013912",
//   appId: "1:940289013912:web:5547b11a7c64cfe52ef8ed",
//   measurementId: "G-S0VPE37BPP"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP1D3r7nd_Ni5B0U47PtTbWG84wYlHxW0",
  authDomain: "logic-93c13.firebaseapp.com",
  databaseURL: "https://logic-93c13-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logic-93c13",
  storageBucket: "logic-93c13.appspot.com",
  messagingSenderId: "875422042858",
  appId: "1:875422042858:web:59118c540c88cdf989e593",
  measurementId: "G-T3BRD4NLWP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//const database = getDatabase(app);
//const analytics = getAnalytics(app);

const text1 = "철학은 세계와 인간에 대한 가장 근본적 문제들을 이성적으로 탐구하는 학문입니다. 철학은 우리가 일상적 삶에서 당연하고 자명한 것으로 믿고 있는 전제들을 비판적으로 검토함으로써 우리의 삶에 대한 근본적 반성을 추구합니다. 철학은 또한 각 분과 학문에서 전제하고 있는 기본 개념과 원리들을 비판적으로 검토함으로써 개별 학문들의 토대에 대한 근본적 반성을 추구합니다. 철학이 모든 학문의 토대를 이루는 ‘근본학(根本學)’으로 불리는 이유가 여기에 있습니다. 더 나아가 철학은 각 분과 학문이 서로 어떤 관계를 맺고 있으며 이러한 관계를 통해 드러나는 세계 전체의 모습이 어떤 것인가에 대한 총체적 이해를 추구합니다."

const text2 = "철학의 분야들을 어떻게 구분할 것인가에 대해 정확한 합의가 이루어져 있지는 않습니다. 그러나 일반적으로 철학의 주요 네 분야로 논리학과 형이상학, 인식론, 그리고 윤리학을 꼽습니다. 논리학은 이성적 탐구 일반을 행함에 있어서 우리의 사고가 따라야 하는 법칙들이 무엇인지를 탐구합니다. 형이상학은 진정으로 존재하는 것들이 무엇이며 이들의 가장 보편적인 특성이 무엇인지를 탐구합니다. 인식론은 지식의 본성과 범위, 그리고 그 한계가 무엇인지를 탐구합니다. 윤리학은 우리 행위의 옳고 그름 및 좋고 나쁨을 결정할 수 있는 기준이 무엇이며 우리가 어떻게 살아가야 하는지를 탐구합니다. 그밖에도 언어, 마음, 과학, 사회, 역사, 문화 등의 철학적 토대를 탐구하는 언어철학, 심리철학, 과학철학, 사회철학, 역사철학, 문화철학 등의 분야가 있습니다."

const text3 = "지금까지 열거한 철학의 분야들은 해당 분야가 다루는 철학적 문제들의 성격에 의해 그 구분이 이루어진 것입니다. 그러나 다른 한편으로 철학의 분야들은 시대와 지역, 그리고 사조에 의해 구분되기도 합니다. 시대에 따라 고대철학과 중세철학, 근대철학과 현대철학이 구분되고, 지역에 따라 동양철학과 서양철학, 인도철학과 중국철학, 영미철학과 독일철학 및 프랑스철학이 구분되며, 학파에 따라 불교철학, 유가철학, 도가철학, 송명대신유학, 경험주의 철학, 이성주의 철학, 분석철학, 현상학, 프랑스철학 등이 구분됩니다."

const text4 = "시대와 지역 및 사조에 따른 이러한 철학 분야의 구분은 철학적 문제들에 대해 서로 다른 관점에서 접근하는 다양한 철학적 입장들이 있음을 반영한 것입니다. 이 중 현대의 철학 사조들을 제외한 모든 철학 사조들은 넓은 의미에서 철학사 분야에 속하는 것으로 분류될 수 있습니다. 근본학으로서의 철학은 모든 학문들 중 가장 오래된 역사를 지니고 있으며 따라서 그 어떤 학문보다도 풍부한 역사적 유산을 갖고 있습니다. 이렇게 풍부한 철학의 역사적 유산을 학문적으로 탐구하는 철학사 분야는 다음과 같은 이유에서 단지 지성사(知性史)적 중요성 뿐 아니라 철학적인 중요성을 갖고 있습니다."

const text5 = "철학의 각 분야에서 제기되는 철학적 문제들은 수천 년간의 장구한 논의에도 불구하고 그 해답이 무엇인지에 대해 합의가 이루어지지 않고 있습니다. 더 나아가 도대체 이 문제들이 해결될 수 있는 종류의 것들인지, 그리고 만약 그럴 수 있다면 어떠한 탐구 방법을 채택해야 하는지에 대해서도 합의가 이루어지지 않고 있습니다. 이러한 상황은 어쩌면 근본학으로서의 철학이 갖고 있는 숙명인 것처럼 보이기도 합니다. 철학이 일상생활 및 학문적 활동에 있어서 자명하게 받아들여지는 모든 전제들에 대해 근본적으로 반성해보는 작업이라면, 이러한 반성적 작업으로서의 철학은 궁극적으로 자기 자신의 정체성, 즉 철학의 정체성마저도 늘 반성의 대상으로 삼을 것이기 때문입니다. 그러나 비록 철학적 문제들에 대해 합의된 답이 존재하지는 않는다고 하더라도, 이 문제들에 대한 답을 찾고자 하는 사유를 전개하는데 있어서 가장 탁월한 수준의 독창성과 통찰력, 그리고 엄밀성과 설득력을 보여준 철학적 문헌들이 존재합니다. 이들이 바로 철학의 고전들이라고 일컬어지는 것입니다."

const text6 = "다시 말해서, 철학의 고전들은 오늘날에도 여전히 우리가 그 해답을 찾기 위해 고민하는 철학적 문제들에 대해 역사상 가장 뛰어난 철학적 사유의 전개 과정을 담고 있습니다. 철학사 연구를 통해 철학의 고전들에 담긴 이러한 사유의 전개 과정을 심층적으로 이해하는 것은, 과거의 철학적 이론에 대한 역사적 이해를 넘어 오늘날의 철학적 논의에 기여하기 위해서도 반드시 필요한 작업입니다. 이에 따라 서울대학교 철학과에서는 <형이상학>과 <인식론>, <윤리학>, <언어철학>, <과학철학>, <사회철학> 등 철학 분야의 구분에 따른 과목과 더불어 <서양고대철학>, <서양중세철학>, <서양근대철학>, <중국고대철학>, <인도불교철학>,<한국철학사>등 다양한 철학사 관련 과목을 개설하여 가르치고 있습니다."

const test = [
{
  "Number":1,
  "problem":"세상에서 가장 중요한 가치는 무엇인가?",
  "1":"사랑",
  "2":"자비",
  "3":"용서",
  "4":"도전",
  "5":"해탈"
},
{
  "Number":2,
  "problem":"문제를 해결하는 가장 좋은 방법론은?",
  "1":"뭐라도 해 무의식 놀이, 선 행동, 후 수정",
  "2":"슬로 싱킹, 아주 천천히 오래오래 생각하기",
  "3":"여러 단계로 쪼개라, 데카르트 방법론",
  "4":"가설 검증 놀이",
  "5":"운에 맡긴다."
},
{
  "Number":3,
  "problem":"마음 공부를 수행하는 가장 좋은 전략은?",
  "1":"아무런 제약 조건 없이 삶의 모든 것을 해석한다.",
  "2":"무조건 용서 전략",
  "3":"보살 놀이로 인간 관계를 살아간다.",
  "4":"모든 것을 사랑한다.",
  "5":"몰입/문제 놀이로 삶을 살아간다."
},
{
  "Number":4,
  "problem":"행복의 본질은 무엇인가?",
  "1":"있는 그대로 만족하는 것",
  "2":"몰입이 진정한 행복이다.",
  "3":"고통을 견디면서, 문제를 해결하는 삶이 행복이다.",
  "4":"조화와 균형이다.",
  "5":"매 순간 공자의 덕을 추구한다."
},
{
  "Number":5,
  "problem":"최고의 성공 전략은 무엇인가?",
  "1":"견딜 수 있는 고통을 견디면서, 꾸준히 노력하는 것",
  "2":"몰입을 선택하고, 그 몰입을 즐기는 것",
  "3":"행복을 통해서 성공을 성취한다.",
  "4":"꿈을 올바르게 선택하고, 할 수 있는 모든 노력 다 한다.",
  "5":"데카르트 쪼개라 전략으로 문제 해결에 도전한다."
}
]

const test_list1 = [
  {
    "Number":1,
    "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
    "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
    "example1":"ㄱ. 이 문장 모임은 네 문장으로 이뤄졌다.",
    "example2":"ㄴ. 이 문장 모임은 추론이다. ",
    "1":"없음",
    "2":"ㄱ",
    "3":"ㄴ",
    "4":"ㄱ,ㄴ",
    "answer":"-1",
    "correct":"3",
    "note1":
    {
        "-1":"답을 선택하지 않았어요!!",
        "1":"➀ ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. ‘추론’이 무엇인지 두보계 100 제001절을 읽으며 다시 살펴보세요.",
        "2":"➁ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 안타깝게도 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 두보계 100 제001절을 읽으며 ‘추론’이 무엇인지 다시 살펴보세요.",
        "4":"➃ ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 문장의 개수를 잘 헤아리지 못했네요. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다."
    },
    "note2":"ㄱ은 이 문장 모임에 관해 그르게 말한 진술입니다. 이 문장 모임은 세 문장으로 이뤄졌습니다. 마침표는 문장의 개수를 헤아리는 데 큰 도움을 줍니다. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 이 문장 모임에는 “따라서”가 들어 있습니다. 이 때문에 이 문장 모임은 추론입니다. “따라서” 뒤에 나오는 문장은 이 문장 모임의 결론입니다. 결론이 있다는 것은 전제가 있음을 말해줍니다. 전제와 결론이 있는 문장 모임은 추론입니다. 정답은 ➂입니다."
  },
  {
    "Number":2,
    "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
    "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
    "example1":"ㄱ. 이 추론의 전제는 2개다.",
    "example2":"ㄴ. 이 추론의 결론은 2개다.",
    "1":"없음",
    "2":"ㄱ",
    "3":"ㄴ",
    "4":"ㄱ,ㄴ",
    "answer":"-1",
    "correct":"2",
    "note1":{
      "-1":"답을 선택하지 않았어요!!",
        "1":"➀ ㄱ은 이 문장 모임에 관해 옳게 말한 진술입니다. 전제가 무엇이고 결론이 무엇인지 가릴 수 없었나요? 추론에서 전제와 결론을 가리는 방법은 두보계 100 제001절을 읽어보세요.",
        "3":"➂ ㄱ은 이 문장 모임에 관해 옳게 말한 진술입니다. 추론에서 전제가 무엇이고 결론이 무엇인지 가릴 수 없었나요? ㄴ은 이 문장 모임에 관해 그르게 말한 진술입니다. 추론에서 전제와 결론을 찾는 방법은 두보계 100 제001절을 읽어보세요.",
        "4":"➃ ㄴ은 이 문장 모임에 관해 그르게 말한 진술입니다. 추론에서 전제와 결론을 찾는 방법은 두보계 100 제001절을 읽어보세요."
    },
    "note2":"ㄱ은 이 문장 모임에 관해 옳게 말한 진술입니다. 이 문장 모임은 세 문장인데 세 문장 가운데 하나는 결론이고 나머지 둘은 전제입니다. ㄴ은 이 문장 모임에 관해 그르게 말한 진술입니다. 이 문장 모임에는 추론 하나가 들어 있습니다. 한 추론에서 결론은 하나입니다. 정답은 ➁입니다."
  },
  {
    "Number":3,
    "problem":"다음 문장 모임에 관해 옳게 말한 것만 모두 모으면?",
    "text":"우리 세계는 실현된 세계며 생각할 수 있는 세계다. 우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다. 따라서 우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다.",
    "example1":"ㄱ. 이 추론의 결론은 “우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다”다.",
    "example2":"ㄴ. “우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다”는 이 추론의 전제다.",
    "1":"없음",
    "2":"ㄱ",
    "3":"ㄴ",
    "4":"ㄱ,ㄴ",
    "answer":"-1",
    "correct":"4",
    "note1":{
      "-1":"답을 선택하지 않았어요!!",
        "1":"➀ ㄱ과 ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 추론에서 전제가 무엇이고 결론이 무엇인지 가릴 수 없었나요? 추론에서 전제와 결론을 찾는 방법은 두보계 100 제001절을 읽어보세요.",
        "2":"➁ ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 추론에서 전제가 무엇인지 가릴 수 없었나요? 추론에서 전제를 찾는 방법은 두보계 100 제001절을 읽어보세요.",
        "3":"➂ ㄱ은 이 문장 모임에 관해 옳게 말한 진술입니다. 추론에서 결론이 무엇인지 가릴 수 없었나요? 추론에서 결론을 찾는 방법은 두보계 100 제001절을 읽어보세요."
    },
    "note2":"ㄱ은 이 문장 모임에 관해 옳게 말한 진술입니다. “따라서” 뒤에 나오는 문장은 이 추론의 결론입니다. “따라서” 뒤에 “우리는 적어도 두 세계를 생각할 수 있고 그 가운데 적어도 하나는 실현된 세계다”가 나오네요. ㄴ은 이 문장 모임에 관해 옳게 말한 진술입니다. 추론에서 결론을 뺀 나머지 문장은 전제입니다. 이 추론에서 전제는 두 개입니다. 하나는 “우리 세계는 실현된 세계며 생각할 수 있는 세계다”입니다. 다른 하나는 “우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다”입니다. 그래서 “‘우리 세계는 생각될 수 있는 세계고 생각될 수 있는 다른 세계가 있다’는 이 추론의 전제다”는 옳은 진술입니다. 정답은 ➃입니다."
  },
  {
    "Number":4,
    "problem":"다음 문장 짝들 가운데 같은 뜻으로 묶인 짝을 모두 모으면?",
    "text":"",
    "example1":"ㄱ. 알코올은 물보다 빨리 끓는다. “‘알코올은 물보다 빨리 끓는다’는 참이다”는 참이다.",
    "example2":"ㄴ. “샛별은 행성이다”는 참이다는 참이다는 참이다. 샛별은 행성이다.",
    "1":"없음",
    "2":"ㄱ",
    "3":"ㄴ",
    "4":"ㄱ,ㄴ",
    "answer":"-1",
    "correct":"4",
    "note1":{
      "-1":"답을 선택하지 않았어요!!",
        "1":"➀ ㄱ의 두 문장은 서로 뜻이 같습니다. ㄴ의 두 문장도 서로 뜻이 같습니다. 문장 “X는 참이다”와 문장 X가 뜻이 같다는 점을 몰랐나요? 두보계 100 제002절을 다시 읽어보세요.",
        "2":"➁ ㄴ의 두 문장은 서로 뜻이 같습니다. 문장 “X는 참이다”와 문장 X가 뜻이 같다는 점을 몰랐나요? 두보계 100 제002절을 다시 읽어보세요.",
        "3":"➂ ㄱ의 두 문장은 서로 뜻이 같습니다. 문장 “X는 참이다”와 문장 X가 뜻이 같다는 점을 몰랐나요? 두보계 100 제002절을 다시 읽어보세요."
    },
    "note2":"ㄱ의 두 문장은 서로 뜻이 같습니다. 문장 “X는 참이다는 참이다”는 문장 “X는 참이다”와 뜻이 같고, 문장 “X는 참이다”는 문장 X와 뜻이 같습니다. ㄴ의 두 문장은 서로 뜻이 같습니다. 문장 “X는 참이다는 참이다는 참이다”는 문장 “X는 참이다는 참이다”와 뜻이 같습니다. 나아가 문장 “X는 참이다는 참이다”는 문장 X와 뜻이 같습니다. 정답은 ➃입니다."
  },
  {
    "Number":5,
    "problem":"다음 문장 짝들 가운데 같은 뜻으로 묶인 짝을 모두 모으면?",
    "text":"",
    "example1":"ㄱ. “우주는 무한하다”는 거짓이다. “‘우주는 무한하다’는 거짓이다”는 참이다.",
    "example2":"ㄴ. “명왕성은 태양계의 행성이다”는 거짓이다는 참이다. “명왕성은 태양계의 행성이다”는 참이다는 거짓이다.",
    "1":"없음",
    "2":"ㄱ",
    "3":"ㄴ",
    "4":"ㄱ,ㄴ",
    "answer":"-1",
    "correct":"4",
    "note1":{
      "-1":"답을 선택하지 않았어요!!",
       "1":"➀ ㄱ의 두 문장은 서로 뜻이 같습니다. ㄴ의 두 문장도 서로 뜻이 같습니다. 한 자리 문장 바꾸개 “는 참이다”를 문장에서 없애거나 넣어도 문장의 뜻은 달라지지 않습니다. 두보계 100 제002절을 다시 읽어보세요.",
       "2":"➁ ㄴ의 두 문장은 서로 뜻이 같습니다. 한 자리 문장 바꾸개 “는 참이다”를 문장에서 없애거나 넣어도 문장의 뜻은 달라지지 않습니다. 두보계 100 제002절을 다시 읽어보세요.",
       "3":"➂ ㄱ의 두 문장은 서로 뜻이 같습니다. 한 자리 문장 바꾸개 “는 참이다”를 문장에서 없애거나 넣어도 문장의 뜻은 달라지지 않습니다. 두보계 100 제002절을 다시 읽어보세요."
    },
    "note2":"ㄱ의 두 문장은 서로 뜻이 같습니다. 한 자리 문장 바꾸개 “는 참이다”를 문장에서 없애도 뜻이 달라지지 않습니다. 왜냐하면 문장 “X는 참이다”는 문장 X와 뜻이 같기 때문입니다. “우주는 무한하다는 거짓이다는 참이다”에서 “는 참이다”를 없애도 되는데 이것은 “우주는 무한하다는 거짓이다”와 뜻이 같습니다. ㄴ의 두 문장은 서로 뜻이 같습니다. “명왕성은 태양계의 행성이다는 거짓이다는 참이다”는 “명왕성은 태양계의 행성이다는 거짓이다”와 뜻이 같습니다. “명왕성은 태양계의 행성이다는 거짓이다”는 “명왕성은 태양계의 행성이다는 참이다는 거짓이다”와 뜻이 같습니다. 한 자리 문장 바꾸개 “는 참이다”를 문장에 넣어도 뜻이 달라지지 않습니다. 왜냐하면 문장 X는 문장 “X는 참이다”와 뜻이 같기 때문입니다. 정답은 ➃입니다."
  },
  {
      "Number":6,
      "problem":"다음 문장 짝들 가운데 같은 뜻으로 묶인 짝을 모두 모으면?",
      "text":"",
      "example1":"ㄱ. “세종은 언어학자거나 음악학자다”는 거짓이다는 거짓이다. 세종은 언어학자거나 음악학자다.",
      "example2":"ㄴ. “모든 예술품은 상품이 아니다”는 거짓이다. 모든 예술품은 상품이다.",
      "1":"없음",
      "2":"ㄱ",
      "3":"ㄴ",
      "4":"ㄱ,ㄴ",
      "answer":"-1",
      "correct":"2",
      "note1":{
          "-1":"답을 선택하지 않았어요!!",
          "1":"➀ ㄱ의 두 문장은 서로 뜻이 같습니다. “X는 거짓이다는 거짓이다”와 문장 X가 뜻이 같음을 몰랐나요? 두보계 100 제003절을 다시 읽어보세요.",
          "3":"➂ ㄱ의 두 문장은 서로 뜻이 같습니다. “X는 거짓이다는 거짓이다”와 문장 X가 뜻이 같음을 몰랐나요? 두보계 100 제003절을 다시 읽어보세요. ㄴ의 두 문장은 뜻이 다릅니다. “모든”, “어느”, “어떠한”, “어떤”, “몇몇” 따위가 들어간 문장에서 “아니다”는 “거짓이다”와 뜻이 다릅니다. 이는 나중에 두보계 100 제054절 이후에 배웁니다.",
          "4":"➃ ㄴ의 두 문장은 뜻이 다릅니다. “모든”, “어느”, “어떠한”, “어떤”, “몇몇” 따위가 들어간 문장에서 “아니다”는 “거짓이다”와 뜻이 다릅니다. 이는 나중에 두보계 100 제054절 이후에 배웁니다."
      },
      "note2":"ㄱ의 두 문장은 서로 뜻이 같습니다. 한 자리 문장 바꾸개 “는 거짓이다”가 곧이어 두 번 나오면 그것은 “는 참이다”와 같게 됩니다. 다시 말해 문장 “X는 거짓이다는 거짓이다”는 문장 “X는 참이다”와 뜻이 같고 이는 다시 문장 X와 뜻이 같습니다. ㄴ의 두 문장은 뜻이 다릅니다. “아니다”와 “거짓이다”는 뜻이 같을 때가 많지만 다를 때도 있습니다. “모든 예술품은 상품이 아니다”는 “‘모든 예술품은 상품이다’는 거짓이다”와 뜻이 다릅니다. “‘모든 예술품은 상품이다’는 거짓이다는 거짓이다”는 “모든 예술품은 상품이다”와 뜻이 같습니다. 하지만 “‘모든 예술품은 상품이 아니다’는 거짓이다”와 “모든 예술품은 상품이다”는 뜻이 다릅니다. “모든”, “어느”, “어떠한”, “어떤”, “몇몇” 따위가 들어간 문장에서 “아니다”는 “거짓이다”와 뜻이 다르니 조심해야 합니다. 정답은 ➁입니다."
    },
    {
      "Number":7,
      "problem":"다음 주장들 가운데 참인 것들만 모두 모으면?",
      "text":"",
      "example1":"ㄱ. “우리 세계는 있고 다른 세계는 생각될 수 있다”는 두 개의 문장이다.",
      "example2":"ㄴ. “우리 세계는 있고 다른 세계는 생각될 수 있다”는 이고문장이다.",
      "1":"없음",
      "2":"ㄱ",
      "3":"ㄴ",
      "4":"ㄱ,ㄴ",
      "answer":"-1",
      "correct":"3",
      "note1":{
          "-1":"답을 선택하지 않았어요!!",
          "1":"➀ ㄴ은 참입니다. “이고문장”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
          "2":"➁ ㄱ은 거짓입니다. 문장의 개수를 어떻게 헤아려야 하는지 다시 생각해 보세요. ㄴ은 참입니다. “이고문장”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
          "4":"➃ ㄱ은 거짓입니다. 문장의 개수를 어떻게 헤아려야 하는지 다시 생각해 보세요."
      },
      "note2":"ㄱ은 거짓입니다. “우리 세계는 있고 다른 세계는 생각될 수 있다”는 한 문장입니다. ㄴ은 참입니다. “우리 세계는 있고 다른 세계는 생각될 수 있다”는 이고문장입니다. 여기서 “이고문장”은 다른 말로 “연언문” 또는 “연언문장”입니다. 정답은 ➂입니다."
    },
    {
      "Number":8,
      "problem":"다음 주장들 가운데 참인 것들만 모두 모으면?",
      "text":"",
      "example1":"ㄱ. “오리너구리는 알을 낳고 젖을 먹인다”에서 이고 뒷말은 “젖을 먹인다”다.",
      "example2":"ㄴ. “실현된 세계는 하나밖에 없거나 우리 세계는 유일한 실현 세계다”에서 이거나 앞말은 “실현된 세계는 하나밖에 없다”다.",
      "1":"없음",
      "2":"ㄱ",
      "3":"ㄴ",
      "4":"ㄱ,ㄴ",
      "answer":"-1",
      "correct":"3",
      "note1":{
          "-1":"답을 선택하지 않았어요!!",
          "1":"➀ ㄴ은 참입니다. “이거나 앞말”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
          "2":"➁ ㄱ은 거짓입니다. 이고 뒷말의 임자말이 이고 앞말의 임자말과 같을 때 이고 뒷말의 임자말을 안 쓸 때가 많습니다. 보기를 들어 “칸트는 똑똑하고 칸트는 착하다”를 짧게 “칸트는 똑똑하고 착하다”로 씁니다. ㄴ은 참입니다. “이고 뒷말”이나 “이거나 앞말”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
          "4":"➃ ㄱ은 거짓입니다. 이고 뒷말의 임자말이 이고 앞말의 임자말과 같을 때 이고 뒷말의 임자말을 안 쓸 때가 많습니다. 보기를 들어 “칸트는 똑똑하고 칸트는 착하다”를 짧게 “칸트는 똑똑하고 착하다”로 씁니다. “이고 뒷말”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요."
      },
      "note2":"ㄱ은 거짓입니다. “오리너구리는 알을 낳고 젖을 먹인다”는 “오리너구리는 알을 낳고 오리너구리는 젖을 먹인다”를 짧게 쓴 문장입니다. “오리너구리는 알을 낳고 젖을 먹인다”는 이고문장이고 이 문장에서 이고 뒷말은 “오리너구리는 젖을 먹인다”입니다. 여기서 “이고문장”은 다른 말로 “연언문” 또는 “연언문장”입니다. ㄴ은 참입니다. “실현된 세계는 하나밖에 없거나 우리 세계는 유일한 실현 세계다”는 이거나문장이고 이 문장에서 이거나 앞말은 “실현된 세계는 하나밖에 없다”입니다. 여기서 “이거나문장”은 다른 말로 “선언문” 또는 “선언문장”입니다. 정답은 ➂입니다."
    },  

    {
      "Number":9,
      "problem":"다음 주장들 가운데 참인 것들만 모두 모으면?",
      "text":"",
      "example1":"ㄱ. “철수나 영희는 착하다”는 두 문장이 “이거나”로 이어진 이거나문장이다.",
      "example2":"ㄴ. “만일 내가 다른 세계들을 생각할 수 있다면 가능 세계는 여럿이다”에 쓰인 문장 바꾸개는 “이면”이다.",
      "1":"없음",
      "2":"ㄱ",
      "3":"ㄴ",
      "4":"ㄱ,ㄴ",
      "answer":"-1",
      "correct":"4",
      "note1":{
          "-1":"답을 선택하지 않았어요!!",
          "1":"➀ ㄱ은 참입니다. 이거나 앞말과 이거나 뒷말의 풀이말이 같다면 두 임자말 사이에 “이거나”를 넣기도 합니다. ㄴ은 참입니다. “이거나문장”의 뜻을 몰랐나요? “문장 바꾸개”의 뜻을 몰랐나요? 두보계 100 제004절을 다시 읽어보세요.",
          "2":"➁ ㄴ은 참입니다. “문장 바꾸개”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
          "3":"➂ ㄱ은 참입니다. 이거나 앞말과 이거나 뒷말의 풀이말이 같다면 두 임자말 사이에 “이거나”를 넣기도 합니다. “이거나문장”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요."
      },
      "note2":"ㄱ은 참입니다. “철수나 영희는 착하다”는 “철수는 착하거나 영희는 착하다”를 짧게 쓴 문장입니다. 이 문장은 “이거나”로 이어진 이거나문장입니다. 여기서 “이거나문장”은 다른 말로 “선언문” 또는 “선언문장”입니다. ㄴ은 참입니다. “만일 내가 다른 세계들을 생각할 수 있다면 가능 세계는 여럿이다”는 이면문장입니다. 이 문장에 쓰인 문장 이음씨는 “이면”입니다. “이면”은 두 자리 문장 바꾸개입니다. 여기서 “이면문장”은 다른 말로 “조건문” 또는 “조건문장”입니다. “문장 이음씨”는 다른 말로 “문장연결사”고 “문장 바꾸개”는 다른 말로 “문장연산자”입니다. 정답은 ➃입니다."
    },
    {
      "Number":10,
      "problem":"다음 주장들 가운데 참인 것들만 모두 모으면?",
      "text":"",
      "example1":"ㄱ. “내가 다른 세계들을 생각할 수 있다면 가능 세계는 여럿이다”의 이면 앞말과 뒷말을 바꾸어 만든 문장은 이면문장이다.",
      "example2":"ㄴ. “‘실현된 세계는 하나밖에 없다’는 거짓이다”에서 “는 거짓이다”는 두 자리 문장 바꾸개다.",
      "1":"없음",
      "2":"ㄱ",
      "3":"ㄴ",
      "4":"ㄱ,ㄴ",
      "answer":"-1",
      "correct":"2",
      "note1":{
          "-1":"답을 선택하지 않았어요!!",
          "1":"➀ ㄱ은 참입니다. “이고 앞말”, “이고 뒷말”, “이고문장”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
          "3":"➂ ㄱ은 참입니다. “이고 앞말”, “이고 뒷말”, “이고문장”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요. ㄴ은 거짓입니다. “두 자리 문장 바꾸개”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요.",
          "4":"➃ ㄴ은 거짓입니다. “두 자리 문장 바꾸개”의 뜻을 몰랐다면 두보계 100 제004절을 다시 읽어보세요."
      },
      "note2":"ㄱ은 참입니다. “내가 다른 세계들을 생각할 수 있다면 가능 세계는 여럿이다”의 이면 앞말과 뒷말을 바꾸어 만든 문장은 “가능 세계는 여럿이면 나는 다른 세계들을 생각할 수 있다”입니다. 이 문장은 이면문장입니다. 여기서 “이면문장”은 다른 말로 “조건문” 또는 “조건문장”입니다. ㄴ은 거짓입니다. “는 거짓이다”는 한 자리 문장 바꾸개입니다. 여기서 “문장 바꾸개”는 다른 말로 “문장연산자”입니다. 정답은 ➁입니다."
    },
]
const test_data={
  "EL": [
      {
          type1:"TOLT010",
          type2:"Q001",
          type3:"A410",
          test: test_list1,
          name:"test1", 
          id:1
      },
      {
        type1:"TOLT010",
        type2:"Q001",
        type3:"A410",
        test: test_list1,
        name:"test2", 
        id:2
    },
    {
      type1:"TOLT010",
      type2:"Q001",
      type3:"A410",
      test: test_list1,
      name:"test3", 
      id:3
    },
    {
      type1:"TOLT010",
      type2:"Q002",
      type3:"A410",
      test: test_list1,
      name:"test4", 
      id:4
  },
  {
    type1:"TOLT010",
    type2:"Q002",
    type3:"A410",
    test: test_list1,
    name:"test5", 
    id:5
},
{
  type1:"TOLT010",
  type2:"Q002",
  type3:"A410",
  test: test_list1,
  name:"test6", 
  id:6
}
  ],
  "RC": [],
  "CA": [],
  "100": [],
  "100E": [],
  "200": [],
  "300": [],
}
const data_300=[
  {
    name:"test1",
    content:"<h1>My First Heading</h1><p>My first paragraph.</p>"
  },
  {
    name:"test2",
    content:"<h1>My Second Heading</h1><p>My second paragraph.</p>"
  }
]
const ff = ()=>{
  
  const db = getDatabase();

  // for(let i = 0 ; i < test_list1.length; i++){
  //   let number = i + 1;
  //   set(ref(db, 'test_1/'+number), {
  //     //test: test_list1,
  //     name: "test1",
  //     id: 1
  //   });
  // }

  //const db = getDatabase();
  // set(ref(db, 'TOLT_EL1/1'), {
  //   test: test_list1,
  //   name: "test1",
  //   id: 1
  // });

  // set(ref(db, 'TOLT_EL1/2'), {
  //   test: test_list1,
  //   name: "test2",
  //   id: 2
  // });

  // set(ref(db, 'TOLT_EL1/3'), {
  //   test: test_list1,
  //   name: "test3",
  //   id: 3
  // });

  // set(ref(db, 'TOLT_EL1/4'), {
  //   test: test_list1,
  //   name: "test4",
  //   id: 4
  // });

  set(ref(db, 'DATA'), test_data);
  // const postListRef = ref(db, 'test');
  // const newPostRef = push(postListRef);
  // set(newPostRef, {
  //   //test: test_list1,
  //   name: "test1",
  //   id: 1
  // });

  // const db = getDatabase();
  // const starCountRef = ref(db, 'test/');
  // onValue(starCountRef, (snapshot) => {
  //   const data = snapshot.val();
  //   console.log(data);
  //   //updateStarCount(postElement, data);
  // });

  // database()
  // .ref('test/1')
  // .set({
  //   test: test_list1,
  //   name: "test1",
  //   id: 1
  // })
  // .then(() => console.log('Data set.'))
}
//let test_all = [];
export default function App() {
  const [screen, setScreen] = useState(0);
  const [test_id, settest_id] = useState(1);
  const [redraw, setredraw] = useState("0");
  const [test_all, setT] = useState([]);
  const [test_data, settest_data] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [user_history, setUserHistory] = useState({});
  const [score_history, setScoreHistory] = useState({});
  const [test_name,settest_name] = useState("");
  const [TEST_INFO, settest_info] = useState({});
  const [mode, setmode]=useState("");

//   let login_info={
//     user:"",
//     pw:"",
//     nick:"",
//     id:"",
//     state:""
    
//  };
  // if(test_all == []){
     
  // }else{
  //   setredraw("1");
  // }
  //let test_all = [];
  const logout = async ()=>{
    var login_info = await AsyncStorage.getItem('login')
    login_info = login_info != null ? JSON.parse(login_info) : null;
    console.log("login info ==>",login_info)

    if(login_info){
      login_info.state = "not_login";

      const jsonValue = JSON.stringify(login_info)
      await AsyncStorage.setItem('login', jsonValue)
    }

    //setScreen(0);
    load();
  }
  const get_load_test_info = (t_name)=>{
    const db = getDatabase();
    
    const starCountRef = ref(db, t_name +'/');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      //test_all=data.slice(1,5);
      if(!data){
        let test_info = [];
        setT(test_info);
        settest_name(t_name)
        return
      }
      console.log(" data len >", data.length);
      let test_info = [];
      console.log("=====>",data.slice(0,data.length));
      //setT(data.slice(1,5));
      let data2 = data.slice(0,data.length);
      for(let i = 0; i < data2.length; i++){
        if(data2[i]){
          test_info.push(data2[i])
        }
        
      }
      // test_all.push(data.slice(1,5)["0"])
      // test_all.push(data.slice(1,5)["1"])
      // test_all.push(data.slice(1,5)["2"])
      // test_all.push(data.slice(1,5)["3"])
      setT(test_info);
      settest_id(0)
      //setredraw("1");
      console.log("test all ===>",test_all);
      //load();
      //updateStarCount(postElement, data);
    });
  }
  const load = async ()=>{
    //const login_info = await ls.get(login_info_name);

    // const login_info = await ls.get("login");
    //   if(login_info != null){
    //     if(login_info.state === "login"){
    //       // setnick(login_info.nick);
    //       // setid(login_info.id);
    //       // setemail(login_info.user);
    //       // setpw(login_info.pw);
    //       setScreen(2);
    //     }else{
    //       setScreen(0);
    //     }
    //   }else{
    //     setScreen(0);
    //   }

      try {
        var login_info = await AsyncStorage.getItem('login')
        login_info = login_info != null ? JSON.parse(login_info) : null;
        console.log("login info ==>",login_info)
        if(login_info != null){
          if(login_info.state === "login"){
            // setnick(login_info.nick);
            // setid(login_info.id);
            // setemail(login_info.user);
            // setpw(login_info.pw);
            setUserInfo(login_info);
            setmode(login_info.mode);
            // const id = userInfo.user.replace(".", "A");
            // //let test_id;
            // if(user_history != null){
            //   if(user_history[id] != null){
            //     settest_id(user_history[id].test_id);
            //     console.log("------------->test id >>", test_id);
            //   }else{
            //     //test_id = 1;
            //     console.log("------------->111 test id >>", test_id);
            //   }
            // }else{
            //   //test_id = 1;
            //   console.log("------------->2222 test id >>", test_id);
            // }
            setScreen(2);
            
          }else{
            setScreen(0);
          }
        }else{
          setScreen(0);
        }
      } catch(e) {
        // error reading value
      }
  }

  if(redraw == "0"){
    //ff();
    const db = getDatabase();
    
    //const starCountRef = ref(db, 'TEST_INFO/');//Q002
    //const starCountRef = ref(db, 'Q002/');//Q002
    //const starCountRef = ref(db, 'TOLT_EL1/');//Q002
    const starCountRef = ref(db, 'DATA');//Q002
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      let final_test_data = data.EL;
      let tmp_list = [];
        for(let i = 0 ; i < final_test_data.length; i++){
          if(final_test_data[i] == null){
            continue;
          }

          //if(final_test_data[i].id != v){
          tmp_list.push(final_test_data[i])
          //}
        }

        let tmp_final_data = data;
        tmp_final_data["EL"] = tmp_list;
        //props.settest_data(tmp_final_data);
        set(ref(db, 'DATA'),tmp_final_data);
      settest_data(data);
      // data["300"] = data_300;
      // set(ref(db, 'DATA'), data);

      // if(!data){
      //   settest_info(data)
      //   setredraw("1");
      //   return;
      // }
      // //test_all=data.slice(1,5);
      // //settest_info(data)
      // console.log(" data len >", data.length);
      // console.log("=====>",data.slice(1,data.length));
      // //setT(data.slice(1,5));
      // let data2 = data.slice(1,data.length);
      // for(let i = 0; i < data2.length; i++){
      //   if(data2[i]){
      //     test_all.push(data2[i])
      //   }
        
      // }
      // // test_all.push(data.slice(1,5)["0"])
      // // test_all.push(data.slice(1,5)["1"])
      // // test_all.push(data.slice(1,5)["2"])
      // // test_all.push(data.slice(1,5)["3"])
      // setT(test_all);
      setredraw("1");
      // console.log("test all ===>",test_all);
      //load();
      //updateStarCount(postElement, data);
    });
  }

  if(redraw == "1"){
    const db = getDatabase();
    
    const starCountRef = ref(db, 'User_History/');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setUserHistory(data);
      console.log("========>data ", data)
      //test_all=data.slice(1,5);
      // console.log(" data len >", data.length);
      // console.log("=====>",data.slice(1,data.length));
      // //setT(data.slice(1,5));
      // let data2 = data.slice(1,data.length);
      // for(let i = 0; i < data2.length; i++){
      //     if(data2[i]){
      //       user_list.push(data2[i])
      //     }
        
      // }
      // // test_all.push(data.slice(1,5)["0"])
      // // test_all.push(data.slice(1,5)["1"])
      // // test_all.push(data.slice(1,5)["2"])
      // // test_all.push(data.slice(1,5)["3"])
      // setUserlist(user_list);
      setredraw("2");
      //console.log("test all ===>",user_list);
      //load();
      //props.setdatalength(test_all.length)
      //updateStarCount(postElement, data);
    });
  }

  if(redraw == "2"){
    const db = getDatabase();
    
    const starCountRef = ref(db, 'Score_History/');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setScoreHistory(data);
      console.log("========>data ", data)
      //test_all=data.slice(1,5);
      // console.log(" data len >", data.length);
      // console.log("=====>",data.slice(1,data.length));
      // //setT(data.slice(1,5));
      // let data2 = data.slice(1,data.length);
      // for(let i = 0; i < data2.length; i++){
      //     if(data2[i]){
      //       user_list.push(data2[i])
      //     }
        
      // }
      // // test_all.push(data.slice(1,5)["0"])
      // // test_all.push(data.slice(1,5)["1"])
      // // test_all.push(data.slice(1,5)["2"])
      // // test_all.push(data.slice(1,5)["3"])
      // setUserlist(user_list);
      setredraw("3");
      //console.log("test all ===>",user_list);
      load();
      //props.setdatalength(test_all.length)
      //updateStarCount(postElement, data);
    });
  }
  
  //const test_all = ff();
  console.log("test al >", test_all);
  //ff();
  //const [screen, setScreen] = useState(1);
  //let number = 0;
  // const setPrev = ()=>{
  //   if(screen > 1){
  //     //number = number - 1;
  //     setScreen(screen-1);
  //   }
  //   //setScreen(number);
    
  // }

  // const setNext = ()=>{
  //   if(screen < 5){
  //     //number = number + 1
  //     setScreen(screen+1);
  //   }
  //   //setScreen(screen);
    
  // }
  // console.log("test 0 >", test[0])
  // console.log("test 1 >", test[1]["problem"])
  // let theme;
  // if(screen == 1){
  //   theme = "문제: "+screen + " 철학이란 무엇인가?";
  // }
  // if(screen == 2){
  //   theme = "문제: "+screen + " 과학이란 무엇인가?";
  // }
  // if(screen == 3){
  //   theme = "문제: "+screen + " 논리학이란 무엇인가?";
  // }
  // if(screen == 4){
  //   theme = "문제: "+screen + " 예술이란 무엇인가?";
  // }
  // if(screen == 5){
  //   theme = "문제: "+screen + " 논증이란 무엇인가?";
  // }
  // <Main test_all={test_all} />
  // //let theme = "문제: "+screen + " 철학이란 무엇인가?";
  // let content = <OneSlide theme={theme} text1={text1} text2={text2} text3={text3} text4={text4} text5={text5} text6={text6} test={test}/>
      
  //const login_info = await ls.get(login_info_name);

  let content;

  
  if(screen == 0){
    content = <Login onChange={setScreen} setUserInfo={setUserInfo} setmode={setmode}/>
  }

  if(screen == 1){
    content = <SignUp onChange={setScreen} />
  }

  if(screen == 2){
    if(mode == "test"){
      content = <Main test_data={test_data} TEST_INFO={TEST_INFO} test_name={test_name} test_len={test_all.length} get_load_test_info={get_load_test_info} test_id={test_id} score_history={score_history} user_history={user_history} userInfo={userInfo} logout={logout} test_all={test_all} />
    }else{
      content = <MainData test_data={test_data} TEST_INFO={TEST_INFO} test_name={test_name} test_len={test_all.length} get_load_test_info={get_load_test_info} test_id={test_id} score_history={score_history} user_history={user_history} userInfo={userInfo} logout={logout} test_all={test_all} />
    }
    //content = <Main TEST_INFO={TEST_INFO} test_name={test_name} test_len={test_all.length} get_load_test_info={get_load_test_info} test_id={test_id} score_history={score_history} user_history={user_history} userInfo={userInfo} logout={logout} test_all={test_all} />
  }
  
  return (
    <View style={styles.container}>
        {content}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop:10,
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});
/*
<View style={{flexDirection:'row',width:"80%",justifyContent: 'space-between'}}>
        <TouchableOpacity onPress={setPrev}><Text style={{fontSize:16, fontWeight: 'bold'}}>prev</Text></TouchableOpacity>
        <TouchableOpacity onPress={setNext}><Text style={{fontSize:16, fontWeight: 'bold'}}>next</Text></TouchableOpacity>
      </View>
      <Text>[Problem] {screen} </Text>
      {content}

<ScrollView  style={{width:"80%", height:"60%"}}>
      <OneText theme="철학이란 무엇인가?" text1={text1} text2={text2} text3={text3} text4={text4} text5={text5} text6={text6}/>
      <FlatList
        data={test}
        height={600}
        
        renderItem={({ item }) => <OneProblem number={item["Number"]} problem={item["problem"]} one={item["1"]} two={item["2"]} three={item["3"]} four={item["4"]} five={item["5"]}  />
          
            
        
          }
        keyExtractor={(item, index) => index.toString()}
            />
            </ScrollView>
*/
