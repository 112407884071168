import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';
import Agenda from './agendadata'
import Content from './content'
import Content2 from './content2'
import Basic from './basic'
import ElBasic from './elbasiclogic'
import Medium from './medium'
import High from './high'
import Top from './top'
import DataList from './datalist'
import Upload2 from './upload2'
import DataView from './dataview';
import Modify from './modify';
import ModifyName from './modifyname';
import Alert from "react-native-awesome-alerts";
import UserInfo from './userinfo';
import ScoreList from './scorelist';
import DataManager from './datamanager';
import BasicData from './basicdata';
import ElbasicData from './elbasicdata';
import UploadManager from './uploadmanager';
import TopDataManager from './topdatamanager';
import Upload4102 from './upload4102';
import Upload4102Add from './upload4102add';
import Upload4052 from './upload4052';
import Upload5102 from './upload5102';
import Upload5052 from './upload5052';
import Upload8102 from './upload8102';
import Upload8052 from './upload8052';
import Tolt010 from './tolt010';
import Tolt020 from './tolt020';
import Tolt030 from './tolt030';
import Tolt040 from './tolt040';
import Tolt100 from './tolt100';
import Tolt010data from './tolt010data';
import Tolt020data from './tolt020data';
import Tolt030data from './tolt030data';
import Tolt040data from './tolt040data';
import Tolt100data from './tolt100data';
import Test from './test';
import Pretest from './pretest';
import PQR from './pqr';
import Top300 from './top300';
import WebView from './webview';
import UploadTop300 from './uploadtop300';
import ModifyTop300 from './modify300';
import View300 from './view300';
import WebViewP from './webviewpublish';

const MainData = props => { 
    const [screen, setScreen] = useState(0);
    const [prescreen, setPreScreen] = useState(0);
    const [test_id, setTestId] = useState(props.test_id);
    const [redraw, setredraw] = useState("0");
    const [data, setData] = useState([]);
    const [ID, setID] = useState(0);
    const [Name, setName] = useState("");
    const [p_name, setP_name] = useState("");


    const [number, setnumber] = useState("");
    const [problem, setproblem] = useState("");
    const [text, settext] = useState("");
    const [example1, setexample1] = useState("");
    const [example2, setexample2] = useState("");
    const [correct, setcorrect] = useState("");
    const [note1_1, setnote1_1] = useState("");
    const [note1_2, setnote1_2] = useState("");
    const [note1_3, setnote1_3] = useState("");
    const [note1_1_detail, setnote1_1_detail] = useState("");
    const [note1_2_detail, setnote1_2_detail] = useState("");
    const [note1_3_detail, setnote1_3_detail] = useState("");
    const [note2, setnote2] = useState("");

    const [one, setone] = useState("");
    const [two, settwo] = useState("");
    const [three, setthree] = useState("");
    const [four, setfour] = useState("");


    const [msg, setMsg]=useState("");
    const [showAlert, setShowAlert]=useState(false);

    const [score_history, setScoreHistory] = useState(props.score_history);

    const [type1, settype1] = useState("");
    const [type2, settype2] = useState("");
    const [type3, settype3] = useState("A410");
    const [test_data, settest_data] = useState(props.test_data);

    const [data300, setdata300] = useState("");
    const [data300name, setdata300name] = useState("");
    const [publish, setpublish] = useState("NO");

    const logout = ()=>{
        props.logout();
      }

      let content;

      if (screen == 0){
         content = <Agenda logout={logout} onChange={setScreen} onSetPre={setPreScreen}/>
      }
  
      if (screen == 1){
         
         content = <Content onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen}/>
      }
  
      if (screen == 2){
         content = <Basic onChange={setScreen} onSetPre={setPreScreen}/>
      }
  
      if (screen == 3){
          content = <Medium onChange={setScreen} onSetPre={setPreScreen}/>
       }
  
       if (screen == 4){
          content = <High onChange={setScreen} onSetPre={setPreScreen}/>
       }
  
       if (screen == 5){
          content = <Top onChange={setScreen} onSetPre={setPreScreen}/>
       }
  
       if (screen == 6){
          content = <Content2 setScoreHistory={setScoreHistory} score_history={score_history} next_id_store={next_id_store} problem_number={problem_number} userInfo={props.userInfo} redraw={redraw} setredraw={setredraw} name={name} test_id={test_tmp_id} setTestId={setTestId} test_list={test} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen}/>
       }
  
       if (screen == 7){
        content = <ElBasic onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen}/>
       }
  
       if (screen == 8){
         //if(props.userInfo.role=="admin"){
          content = <DataList setName={setName} test_data={props.test_data} type1={type1} type2={type2} type3={type3} setID={setID} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} setData={setData}/>
        //  }else{
        //   setShowAlert(true)
        //   setMsg("관리자만 접근할 수 있어요!!")
        //   setScreen(0);
        //  }
        
       }
  
       if (screen == 9){
        content = <Upload2 onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen}/>
       }
  
       if (screen == 10){
        content = <DataView setone={setone} settwo={settwo} setthree={setthree} setfour={setfour} Name={Name}  setnumber={setnumber} setproblem={setproblem} settest_data={settest_data} test_data={test_data} type1={type1} type2={type2} type3={type3}
        settext={settext}
        setexample1={setexample1}
        setexample2={setexample2}
        setcorrect={setcorrect}
        setnote1_1={setnote1_1}
        setnote1_2={setnote1_2}
        setnote1_3={setnote1_3}
        setnote1_1_detail={setnote1_1_detail}
        setnote1_2_detail={setnote1_2_detail}
        setnote1_3_detail={setnote1_3_detail}
        setnote2={setnote2} id={ID}  onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} test_all={data}/>
       }
  
       if (screen == 11){
        content = <Modify number={number} settest_data={settest_data} test_data={test_data} type1={type1} type2={type2} type3={type3}
        problem={problem}
        text={text}
        example1={example1}
        example2={example2}
        one={one}
        two={two}
        three={three}
        four={four}
        correct={correct}
        note1_1={note1_1}
        note1_2={note1_2}
        note1_3={note1_3}
        note1_1_detail={note1_1_detail}
        note1_2_detail={note1_2_detail}
        note1_3_detail={note1_3_detail}
        note2={note2} id={ID} setData={setData}   onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} test_all={data}/>
       }
  
       if (screen == 12){
         content = <UserInfo userID={props.userInfo.user} role={props.userInfo.role} onChange={setScreen} onSetPre={setPreScreen} />
       }
  
       if (screen == 13){
         content = <ScoreList userInfo={props.userInfo} score_history={score_history} onChange={setScreen} onSetPre={setPreScreen} />
       }
  
       if(screen == 14){
         content = <DataManager onChange={setScreen} onSetPre={setPreScreen} setP_name={setP_name} />
       }
  
       if(screen == 15){
        content = <BasicData onChange={setScreen} onSetPre={setPreScreen} setP_name={setP_name} />
       }
  
       if(screen == 16){
        content = <ElbasicData settype1={settype1} p_name={p_name} onChange={setScreen} onSetPre={setPreScreen} setP_name={setP_name} />
       }
       if(screen == 17){
         content = <UploadManager p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 18){
         content = <TopDataManager onChange={setScreen} onSetPre={setPreScreen} setP_name={setP_name} />
       }
  
       if(screen == 19){
         content = <Upload4102 settest_data={settest_data} test_data={test_data} type1={type1} type2={type2} type3={type3} test_name={props.test_name} test_len={props.test_len}  p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 20){
         content = <Upload4052 p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 21){
         content = <Upload5102 p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 22){
         content =<Upload5052 p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 23){
         content = <Upload8102 p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 24){
         content = <Upload8052 p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 25){
        content = <Tolt010 get_load_test_info={props.get_load_test_info} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 26){
        content = <Tolt020 onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 27){
        content = <Tolt030 onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
       if(screen == 28){
        content = <Tolt040 onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 29){
        content = <Tolt100 onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 30){
        content = <Tolt010data settype2={settype2} p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 31){
        content = <Tolt020data settype2={settype2} p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 32){
        content = <Tolt030data settype2={settype2} p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 33){
        content = <Tolt040data settype2={settype2} p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 34){
        content = <Tolt100data settype2={settype2} p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 35){
        content = <Test p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 36){
        content = <Pretest p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }
  
       if(screen == 37){
        content = <PQR p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }

       if (screen == 38){
        content = <ModifyName setName={setName} Name={Name} number={number} settest_data={settest_data} test_data={test_data} type1={type1} type2={type2} type3={type3}
        problem={problem}
        text={text}
        example1={example1}
        example2={example2}
        correct={correct}
        note1_1={note1_1}
        note1_2={note1_2}
        note1_3={note1_3}
        note1_1_detail={note1_1_detail}
        note1_2_detail={note1_2_detail}
        note1_3_detail={note1_3_detail}
        note2={note2} id={ID} setData={setData}   onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} test_all={data}/>
       }

       if(screen == 39){
        content = <Upload4102Add id={ID} setData={setData} setName={setName} Name={Name} test_all={data} settest_data={settest_data} test_data={test_data} type1={type1} type2={type2} type3={type3} test_name={props.test_name} test_len={props.test_len}  p_name={p_name} setP_name={setP_name} onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }

       if(screen == 40){
         content = <Top300 setpublish={setpublish} setdata300={setdata300} setdata300name={setdata300name} test_data={test_data} onChange={setScreen} onSetPre={setPreScreen} />
       }

       if(screen == 41){
        content = <WebView setpublish={setpublish} publish={publish} settest_data={settest_data} test_data={test_data} data300={data300} data300name={data300name} onChange={setScreen} onSetPre={setPreScreen} />
       }
       
       if(screen == 42){
        content = <UploadTop300 settest_data={settest_data} test_data={test_data}    onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }

       if(screen == 43){
        content = <ModifyTop300 setpublish={setpublish} publish={publish} setdata300={setdata300} setdata300name={setdata300name} settest_data={settest_data} test_data={test_data} data300={data300} data300name={data300name}   onChange={setScreen} onSetPre={setPreScreen} backNumber={prescreen} />
       }

       if(screen == 44){
        content = <View300 setpublish={setpublish} setdata300={setdata300} setdata300name={setdata300name} test_data={test_data} onChange={setScreen} onSetPre={setPreScreen} />
       }

       if(screen == 45){
        content = <WebViewP setpublish={setpublish} publish={publish} settest_data={settest_data} test_data={test_data} data300={data300} data300name={data300name} onChange={setScreen} onSetPre={setPreScreen} />
       }
  

    return(
        <View style={styles.container}>
           {content}

           <Alert
          show={showAlert}
          message={msg}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="cancel"
          confirmText="Yes"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowAlert(false);
          }}
          onConfirmPressed={() => {
            setShowAlert(false);
            //goToCancel();
          }}
        />    
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
      justifyContent: 'flex-start',
      alignItems:'center',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
export default MainData;

