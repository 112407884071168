import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const TopDataManager = props => {
    const goToAgenda = ()=>{
        props.onSetPre(18)
        props.onChange(14)
    }
    const goToContent = ()=>{
        // props.onSetPre(2)
        // props.onChange(1)
    }

    const goToContent300 = ()=>{
        props.onSetPre(18)
        props.onChange(40)
    }

    const goToView300 = ()=>{
        props.onSetPre(18)
        props.onChange(44)
    }

    // const goToContent2 = ()=>{
    //     props.onSetPre(15)
    //     props.onChange(6)
    // }

    const goToEL = ()=>{
        props.onSetPre(18)
        props.onChange(16)
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToAgenda}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>두뇌 보완 계획 데이터 관리</Text>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:10, fontSize:13}}>100(서비스 예정)</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>100 에센스(서비스 예정)</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent}><Text style={{ marginTop:5, fontSize:13}}>200(서비스 예정)</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent300}><Text style={{ marginTop:5, fontSize:13}}>300</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToView300}><Text style={{ marginTop:5, fontSize:13}}>300 View</Text></TouchableOpacity>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
  export default TopDataManager;