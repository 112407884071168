import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const Tolt010data = props => {
    const goToAgenda = ()=>{
        props.onSetPre(7)
        props.onChange(0)
    }
    const goToContent = ()=>{
        // props.onSetPre(7)
        // props.onChange(1)
    }

    const goToContent2 = ()=>{
        props.onSetPre(30)
        //props.onChange(6)
        props.settype2("Q001")
        props.setP_name("Q001 기본 개념")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent3 = ()=>{
        props.onSetPre(30)
        //props.onChange(6)

        props.setP_name("Q002 기본 추론 규칙")
        props.settype2("Q002")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent4 = ()=>{
        props.onSetPre(30)
        //props.onChange(6)

        props.setP_name("Q003 차근차근 이끌기")
        props.settype2("Q003")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent5 = ()=>{
        props.onSetPre(30)
        //props.onChange(6)

        props.setP_name("Q004 이거나 넣기와 없애기")
        props.settype2("Q004")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent6 = ()=>{
        props.onSetPre(30)
        //props.onChange(6)

        props.setP_name("Q005 기본 개념")
        props.settype2("Q005")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent7 = ()=>{
        props.onSetPre(30)
        //props.onChange(6)

        props.setP_name("Q006 이면 없애기")
        props.settype2("Q006")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent8 = ()=>{
        props.onSetPre(30)
        //props.onChange(6)

        props.setP_name("Q007 이면 넣기")
        props.settype2("Q007")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent9 = ()=>{
        props.onSetPre(30)
        //props.onChange(6)

        props.setP_name("Q008 거짓이다 넣기")
        props.settype2("Q008")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent10 = ()=>{
        props.onSetPre(30)
        //props.onChange(6)

        props.setP_name("Q009 파생 추론 규칙")
        props.settype2("Q009")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent11 = ()=>{
        props.onSetPre(30)
        //props.onChange(6)

        props.setP_name("Q010 추론 규칙")
        props.settype2("Q010")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToBack = ()=>{
        props.onSetPre(30)
        props.onChange(16)
        //props.onChange(props.backNumber)
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>TOLT 010(데이터 관리)</Text>
            <Text>TOLT 010은 문장 논리의 추론 규칙을 배우고 배운 바를 점검하는 시험입니다. TOLT 010는 10개의 시험 세트로 이뤄졌습니다.</Text>
            <TouchableOpacity onPress={goToContent2}><Text style={{ marginTop:10, fontSize:13}}>Q001 기본 개념</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent3}><Text style={{ marginTop:5, fontSize:13}}>Q002 기본 추론 규칙</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent4}><Text style={{ marginTop:5, fontSize:13}}>Q003 차근차근 이끌기</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent5}><Text style={{ marginTop:5, fontSize:13}}>Q004 이거나 넣기와 없애기</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent6}><Text style={{ marginTop:5, fontSize:13}}>Q005 기본 개념</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent7}><Text style={{ marginTop:5, fontSize:13}}>Q006 이면 없애기</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent8}><Text style={{ marginTop:5, fontSize:13}}>Q007 이면 넣기</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent9}><Text style={{ marginTop:5, fontSize:13}}>Q008 거짓이다 넣기</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent10}><Text style={{ marginTop:5, fontSize:13}}>Q009 파생 추론 규칙</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent11}><Text style={{ marginTop:5, fontSize:13}}>Q010 추론 규칙</Text></TouchableOpacity>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default Tolt010data;