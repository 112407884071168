import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,Image,TouchableOpacity,TextInput,Dimensions,BackHandler,Linking } from 'react-native';

import { CheckBox, Button } from 'react-native-elements';
import Alert from "react-native-awesome-alerts";

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

var validator = require("email-validator");

const auth = getAuth();
//import { initializeApp } from "firebase/app";
//import { getDatabase } from "firebase/database";
import { getDatabase, ref, set,push,onValue } from "firebase/database";
//import database from "firebase/compat/database";
//import database from '@react-native-firebase/database';
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB0KZSGiW5MSuseDACnXhv3fBjLjYH6g0A",
//   authDomain: "emailverification-c32c5.firebaseapp.com",
//   databaseURL: "https://emailverification-c32c5.firebaseio.com",
//   projectId: "emailverification-c32c5",
//   storageBucket: "emailverification-c32c5.appspot.com",
//   messagingSenderId: "940289013912",
//   appId: "1:940289013912:web:5547b11a7c64cfe52ef8ed",
//   measurementId: "G-S0VPE37BPP"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP1D3r7nd_Ni5B0U47PtTbWG84wYlHxW0",
  authDomain: "logic-93c13.firebaseapp.com",
  databaseURL: "https://logic-93c13-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logic-93c13",
  storageBucket: "logic-93c13.appspot.com",
  messagingSenderId: "875422042858",
  appId: "1:875422042858:web:59118c540c88cdf989e593",
  measurementId: "G-T3BRD4NLWP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const SignUp = props => {
    const [value, setText]=useState("");
    const [value2, setText2]=useState("");
    const [value3, setText3]=useState("");
    const [checked, setChecked]=useState(false);
    const [msg, setMsg]=useState("");
    const [showAlert, setShowAlert]=useState(false);
    //const [email_p, setEmail_p]=useState(false);

    const signProcess = ()=>{
        // props.onSetPre(4)
        // props.onChange(1)
        //auth()
    createUserWithEmailAndPassword(auth,value, value2)
    .then(() => {
      console.log('User account created & signed in!');

        const db = getDatabase();

  
        const id = value.replace(".", "A");
        set(ref(db, 'UserInfo/'+id), {
            role: "user",
            id: id
        });

        

      setShowAlert(true)
      setMsg("Success")
      /*
      Alert.alert(
        "User account created & signed in!"
      )*/
    //   const id = value.replace(".", "A");
    //   database()
    //       .ref('/nickname/'+id)
    //       .set({
    //         name:value4
            
    //       })
    //       .then(() => console.log('Data set.'));
    //   //props.onChange(0);
      //setmodal6(true);

    })
    .catch(error => {
      if (error.code === 'auth/email-already-in-use') {
        console.log('That email address is already in use!');

        setShowAlert(true)
        setMsg("Fail That email address is already in use!")
        /*
        Alert.alert(
          "That email address is already in use!"
        )*/
        // setmodal7(true);
        return;
      }
  
      if (error.code === 'auth/invalid-email') {
        console.log('That email address is invalid!');
        setShowAlert(true)
        setMsg("hat email address is invalid!")
        // Alert.alert(
        //   "That email address is invalid!"
        // )
        return;
      }
  
      console.error(error);
    });


    }
    const goToCancel = ()=>{
        // props.onSetPre(4)
        props.onChange(0)
    }

    const email_check = () => {
        console.log("value >>",value)
        if(validator.validate(value)){
          console.log("ttt")
          setShowAlert(true)
          setMsg("your email id is proper id!!")
        //   Alert.alert(
        //       "your email id is proper id!!"
        //     );
            
        }else{
          console.log("fff");
          setShowAlert(true)
          setMsg("your email id is not proper id!!")
        //   Alert.alert(
        //       "your email id is not proper id!!"
        //     );
            
        }
      }

    return(
        <View style={styles.container}>
             
             <View style={{ marginTop:40}}></View>
             <View style={styles.top2}>
             <View style={{ 
                        width:320,}}>
             <Text style={styles.t1}>User ID</Text>
             </View>
             </View>

             <View style={styles.top2}>
            <View style={styles.row}>
            
            <TextInput  onChangeText={text => setText(text)}
          value={value} autoCapitalize = 'none'
           placeholder = "Enter Email ID" placeholderTextColor = "#263757" style={{ marginLeft:10,marginTop:0,height: 40,
             fontSize:13,textAlign: 'left',width:230,color:'black',paddingRight:0, borderColor: 'white',backgroundColor:'white', borderWidth: 1 }}></TextInput>
               <TouchableOpacity onPress={email_check}>
               <View style={styles.card4}>
                   <Text style={styles.t2}>Check</Text>
               </View></TouchableOpacity>                 
            </View></View>
            <View style={styles.top2}>
            <View style={{width: 320, height: 0.5,backgroundColor:'#c9d3e5'}}></View>
            </View>

            <View style={{ marginTop:20}}></View>
            <View style={styles.top2}>
             <View style={{ 
                        width:320,}}>
            <Text style={styles.t1}>Password</Text>
            </View>
             </View>
            
            <View style={styles.top2}>
            <View style={styles.row}>
            
            <TextInput  onChangeText={text => setText2(text)}
          value={value2} secureTextEntry={true} autoCapitalize = 'none'
           placeholder = "More than 8 characters (digit + characters)" placeholderTextColor = "#c9d3e5" style={{ marginLeft:10,marginTop:0,height: 40,
             fontSize:13,textAlign: 'left',width:270,color:'black',paddingRight:0, borderColor: 'white',backgroundColor:'white', borderWidth: 1 }}></TextInput>
                         <View style={{ width:46}}></View>       
            </View></View>
            <View style={styles.top2}>
            <View style={{width: 320, height: 0.5,backgroundColor:'#c9d3e5'}}></View>
            </View>

            <View style={{ marginTop:20}}></View>
            <View style={styles.top2}>
             <View style={{ 
                        width:320,}}>
            <Text style={styles.t1}>Confirm Password</Text>
            </View>
             </View>
            
            <View style={styles.top2}>
            <View style={styles.row}>
            
            <TextInput  onChangeText={text => setText3(text)}
          value={value3} secureTextEntry={true} autoCapitalize = 'none'
           placeholder = "Confirm Password" placeholderTextColor = "#c9d3e5" style={{ marginLeft:10,marginTop:0,height: 40,
             fontSize:13,textAlign: 'left',width:270,color:'black',paddingRight:0, borderColor: 'white',backgroundColor:'white', borderWidth: 1 }}></TextInput>
                         <View style={{ width:46}}></View>       
            </View></View>
            <View style={styles.top2}>
            <View style={{width: 320, height: 0.5,backgroundColor:'#c9d3e5'}}></View>
            </View>

            <View style={styles.top2}>
             <View style={{ 
                        width:370,}}>
            <View style={styles.row4}>

                 <CheckBox
                  checked={checked}
                  onPress={() => setChecked(!checked)}
                />
                  
                  <Text style={styles.t3}>I agreed with <Text onPress={ ()=> Linking.openURL('http://upay.company/service-terms.html') } style={{textDecorationLine:'underline'}}>Terms of Service</Text> and <Text onPress={ ()=> Linking.openURL('http://upay.company/privacy-policy.html') } style={{textDecorationLine:'underline'}}>Privacy Policy</Text></Text>

                 </View>

                 </View>
             </View>

                 <View style={{ marginTop:100}}></View> 
                 <View style={styles.top2}>
                     <TouchableOpacity onPress={signProcess}>
                 <View style={styles.card3}>
                     <Text style={styles.t4}>OK</Text>
                 </View></TouchableOpacity></View>

                 <View style={{ marginTop:10}}></View> 
                 <View style={styles.top2}>
                     <TouchableOpacity onPress={goToCancel} >
                 <View style={styles.card33}>
                     <Text style={styles.t4}>Cancel</Text>
                 </View></TouchableOpacity></View>



          <Alert
          show={showAlert}
          message={msg}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="cancel"
          confirmText="Yes, go to login"
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setShowAlert(false);
          }}
          onConfirmPressed={() => {
            setShowAlert(false);
            goToCancel();
          }}
        />       
        </View>
    );
 }

const styles = StyleSheet.create({
    container: {
        width:'80%',
        backgroundColor: 'white',
        
        justifyContent:'flex-start',
        
        flex:1,
      }, 

      t1:{
        
          fontSize:11,
          color:'#7989a8',
          marginLeft:0
      },
      t2:{
        fontSize:11,
        color:'white'
      },
      top2:{
        justifyContent:'center',
        alignItems:'center',
    },
    top3:{
        marginTop:30,
        justifyContent:'center',
        alignItems:'center',
    },
    row:{
        marginLeft:10,
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center'
    },
    card3:{
        marginTop:3,
      marginBottom:10,
      borderWidth:2,
      borderColor:'#384867',
      borderBottomStartRadius:27,
      borderBottomRightRadius:27,
      borderBottomLeftRadius:27,
      borderBottomEndRadius:27,
      borderTopLeftRadius:27,
      borderTopRightRadius:27,
      width:320,
      height:54,
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      padding:0,
      backgroundColor:'#384867'
    },
    card33:{
      marginTop:3,
    marginBottom:10,
    borderWidth:2,
    borderColor:'#b6b9be',
    borderBottomStartRadius:27,
    borderBottomRightRadius:27,
    borderBottomLeftRadius:27,
    borderBottomEndRadius:27,
    borderTopLeftRadius:27,
    borderTopRightRadius:27,
    width:320,
    height:54,
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    padding:0,
    backgroundColor:'#b6b9be'
  },
    card4:{
        marginTop:0,
      marginBottom:0,
      borderWidth:2,
      borderColor:'#384867',
      borderBottomStartRadius:15,
      borderBottomRightRadius:15,
      borderBottomLeftRadius:15,
      borderBottomEndRadius:15,
      borderTopLeftRadius:15,
      borderTopRightRadius:15,
      width:76,
      height:30,
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      padding:0,
      backgroundColor:'#384867'
    },
    card5:{
       
      
        borderWidth:2,
        borderColor:'#707070',
        
        width:300,
        height:220,
        flexDirection:'column',
        justifyContent:'flex-start',
        
        padding:0,
        backgroundColor:'white'
      },
      card6:{
        marginTop:36,
        
      borderWidth:2,
      borderColor:'#384867',
      borderBottomStartRadius:27,
      borderBottomRightRadius:27,
      borderBottomLeftRadius:27,
      borderBottomEndRadius:27,
      borderTopLeftRadius:27,
      borderTopRightRadius:27,
      width:154,
      height:46,
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      padding:0,
      backgroundColor:'#384867'
    },
    row4:{
        padding:0,
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center'
    },
    t3:{
        fontSize:12,
        color:'#263757'
    },
    t4:{
        color:'white',
        fontSize:17
    },
    t5:{
        fontSize:17,
        color:'white'
      },
      t6:{
         color:'#384867',
         fontSize:14, 
         textAlign:'center'
      }
});

export default SignUp;

