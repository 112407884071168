import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image } from 'react-native';

const OneText = props => {
    return(
        <View style={styles.container}>
           <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>{props.theme}</Text>

           <Text style={{ marginTop:10, fontSize:13}}>{props.text1}</Text>
           <Text style={{ marginTop:10,fontSize:13}}>{props.text2}</Text>
           <Text style={{ marginTop:10,fontSize:13}}>{props.text3}</Text>
           <Text style={{ marginTop:10,fontSize:13}}>{props.text4}</Text>
           <Text style={{ marginTop:10,fontSize:13}}>{props.text5}</Text>
           <Text style={{ marginTop:10,fontSize:13}}>{props.text6}</Text>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
      justifyContent: 'flex-start',
      
      width:'80%',
      
      paddingBottom:10
    },
  })
  
  export default OneText;