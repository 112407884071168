import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const Tolt100data = props => {
    const goToAgenda = ()=>{
        props.onSetPre(7)
        props.onChange(0)
    }
    const goToContent1 = ()=>{
        props.onSetPre(34)
        props.setP_name("EL01 추론 규칙")
        //props.onChange(17)
        props.settype2("EL01")
        props.onChange(8)
    }

    const goToContent2 = ()=>{
        props.onSetPre(34)
        props.setP_name("EL02 문장사이관계")
        props.settype2("EL02")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent3 = ()=>{
        props.onSetPre(34)
        props.setP_name("EL03 양화논리,논리퍼즐")
        props.settype2("EL03")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToContent4 = ()=>{
        props.onSetPre(34)
        props.setP_name("EL04 귀납, 과학, 비판, 전략")
        props.settype2("EL04")
        //props.onChange(17)
        props.onChange(8)
    }

    const goToBack = ()=>{
        props.onSetPre(34)
        props.onChange(16)
        //props.onChange(props.backNumber)
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>TOLT 100(데이터 관리)</Text>
            <Text>TOLT 100은 문장 논리의 비판과 전략을 배우고 배운 바를 점검하는 시험입니다. TOLT 100는 4개의 시험 세트로 이뤄졌습니다.</Text>
            <TouchableOpacity onPress={goToContent1}><Text style={{ marginTop:10, fontSize:13}}>EL01 추론 규칙</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent2}><Text style={{ marginTop:5, fontSize:13}}>EL02 문장사이관계</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent3}><Text style={{ marginTop:5, fontSize:13}}>EL03 양화논리,논리퍼즐</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent4}><Text style={{ marginTop:5, fontSize:13}}>EL04 귀납, 과학, 비판, 전략</Text></TouchableOpacity>
            
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default Tolt100data;