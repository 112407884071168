import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const Tolt100 = props => {
    const goToAgenda = ()=>{
        props.onSetPre(7)
        props.onChange(0)
    }
    const goToContent1 = ()=>{
        // props.onSetPre(7)
        // props.onChange(1)
        props.onSetPre(29)
        props.settype2("EL01")
        props.onChange(38)
    }

    const goToContent2 = ()=>{
        props.onSetPre(29)
        props.settype2("EL02")
        props.onChange(38)
    }

    const goToContent3 = ()=>{
        props.onSetPre(29)
        props.settype2("EL03")
        props.onChange(38)
    }

    const goToContent4 = ()=>{
        props.onSetPre(29)
        props.settype2("EL04")
        props.onChange(38)
    }

    const goToBack = ()=>{
        props.onSetPre(29)
        props.onChange(0)
        //props.onChange(props.backNumber)
    }
    return(
        <View style={styles.container}>
            
            <View style={styles.top3}>
            <Text style={{ marginTop:15,marginBottom:30 ,fontSize:40, fontWeight: 'bold'}}>TOLT 100</Text>
            
            <TouchableOpacity onPress={goToContent1}><Text style={{ marginTop:10, fontSize:20}}>EL01 추론 규칙</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent2}><Text style={{ marginTop:5, fontSize:20}}>EL02 문장사이관계</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent3}><Text style={{ marginTop:5, fontSize:20}}>EL03 양화논리,논리퍼즐</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent4}><Text style={{ marginTop:5, fontSize:20}}>EL04 귀납, 과학, 비판, 전략</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToBack}><Text style={{ marginTop:100,fontWeight: 'bold', fontSize:20}}>처음으로</Text></TouchableOpacity>
            </View>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
    top3:{
        justifyContent:'center',
        alignItems:'flex-start',
        marginTop:5,
        marginLeft:40,
      },
  })
  
  export default Tolt100;
/*
<TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
<Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>TOLT 100</Text>
            <Text>TOLT 100은 문장 논리의 비판과 전략을 배우고 배운 바를 점검하는 시험입니다. TOLT 100는 4개의 시험 세트로 이뤄졌습니다.</Text>
  */