import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image, TextInput } from 'react-native';
import Upload410 from './upload410'

//import { initializeApp } from "firebase/app";
//import { getDatabase } from "firebase/database";
import { getDatabase, ref, set,push,onValue } from "firebase/database";
//import database from "firebase/compat/database";
//import database from '@react-native-firebase/database';
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB0KZSGiW5MSuseDACnXhv3fBjLjYH6g0A",
//   authDomain: "emailverification-c32c5.firebaseapp.com",
//   databaseURL: "https://emailverification-c32c5.firebaseio.com",
//   projectId: "emailverification-c32c5",
//   storageBucket: "emailverification-c32c5.appspot.com",
//   messagingSenderId: "940289013912",
//   appId: "1:940289013912:web:5547b11a7c64cfe52ef8ed",
//   measurementId: "G-S0VPE37BPP"
// };

/*
Test={

    "EL":{
        "TOLT010":{
            "Q001":{
                "A410":[
                    { test: [], name:"", id:1}
                ]

                
            }
        }
    }

}

test={
    "EL": [
        {
            type1:"TOLT010",
            type2:"Q001",
            type3:"A410",
            test:[{},{}],
            name:"", 
            id:1
        }
    ],
    "RC": [],
    "CA": [],
    "100": [],
    "100E": [],
    "200": [],
    "300": [],
}
*/

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP1D3r7nd_Ni5B0U47PtTbWG84wYlHxW0",
  authDomain: "logic-93c13.firebaseapp.com",
  databaseURL: "https://logic-93c13-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logic-93c13",
  storageBucket: "logic-93c13.appspot.com",
  messagingSenderId: "875422042858",
  appId: "1:875422042858:web:59118c540c88cdf989e593",
  measurementId: "G-T3BRD4NLWP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const ModifyTop300 = props => { 
    const [name, settest] = useState(props.data300name);
    const [content, setcontent] = useState(props.data300);
    const [orgname, settestorg] = useState(props.data300name);
    const [pp,setpp] = useState(props.publish);

    const goToBack = ()=>{

        props.onChange(41)
    }

    const finalstore = ()=>{
        let final_test_data = props.test_data["300"];
        //let count = final_test_data.length+1+Math.random();
        // const add_data = {      
        //     content:content,
        //     name:name, 
        // }
        // final_test_data.push(add_data);

        for(let i = 0 ; i < final_test_data.length; i++){
            if(final_test_data[i].name == orgname){
              //tmp_list.push(final_test_data[i])
              final_test_data[i].name=name;
              final_test_data[i].content = content;
              final_test_data[i].publish = pp;
              break;
            }
          }


        let tmp_final_data = props.test_data;
        tmp_final_data["300"] = final_test_data;
        props.settest_data(tmp_final_data);
        props.setdata300(content);
        props.setdata300name(name);
        props.setpublish(pp);

        const db = getDatabase();
        //let v = test_size + 1;
        //let v = props.test_len;
        set(ref(db, 'DATA'),tmp_final_data);

        goToBack();
    }

    return(
        <View style={styles.container}>
            <View style={{flexDirection:'row',width:300,justifyContent: 'space-between'}}>
            <TouchableOpacity onPress={goToBack}><Text style={{fontSize:16, fontWeight: 'bold'}}>Back</Text></TouchableOpacity>
            <TouchableOpacity onPress={finalstore}><Text style={{fontSize:16, fontWeight: 'bold'}}>저장</Text></TouchableOpacity>
          </View>
           <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>Data 300 Modify</Text>

           <Text>Name</Text>
           <TextInput  onChangeText={v => settest(v)}
          value={name} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           <Text>출판 유무</Text>
           <TextInput  onChangeText={v => setpp(v)}
          value={pp} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
           
           <Text>Content</Text>
           <TextInput  onChangeText={v => setcontent(v)}
          value={content} multiline={true}
           placeholder = "" placeholderTextColor = "black" style={{ marginLeft:0,marginTop:0,height: 50,
             fontSize:14,textAlignVertical: 'top',paddingHorizontal: 20,textAlign: 'left',width:300,color:'#595667',paddingRight:0, borderColor: '#1000000',backgroundColor:'#f2f2f2', borderWidth: 0 }}></TextInput>
 
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        alignItems: 'center',
        justifyContent: 'center',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default ModifyTop300;