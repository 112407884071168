import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,useWindowDimensions,ImageBackground,Button,Image,FlatList,ScrollView} from 'react-native';
import RenderHtml from 'react-native-render-html';
//import { WebView } from 'react-native-webview';

import { getDatabase, ref, set,push,onValue } from "firebase/database";

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCP1D3r7nd_Ni5B0U47PtTbWG84wYlHxW0",
  authDomain: "logic-93c13.firebaseapp.com",
  databaseURL: "https://logic-93c13-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "logic-93c13",
  storageBucket: "logic-93c13.appspot.com",
  messagingSenderId: "875422042858",
  appId: "1:875422042858:web:59118c540c88cdf989e593",
  measurementId: "G-T3BRD4NLWP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const DataWebViewPP = props => {
    const goToBack = ()=>{

        props.onChange(44)//props.backNumber
        

    }
    // const modify = ()=>{
    //   props.onChange(43)
    // }
    // const remove = ()=>{
    //   const db = getDatabase();
    //   //let v =props.id;// test_size + 1;
    //   //console.log("-----> id >",v)
    //   //set(ref(db, 'TOLT_EL1/'+v), null);
    //   ////////////
    //   //let final_test_data = props.test_data.EL;
    //   let final_test_data = props.test_data["300"];
    //   let tmp_list = [];
    //   for(let i = 0 ; i < final_test_data.length; i++){
    //     if(final_test_data[i].name != props.data300name){
    //       tmp_list.push(final_test_data[i])
    //     }
    //   }
    //   // let count = final_test_data.length+1;
    //   // const add_data = {
    //   //     type1:props.type1,
    //   //     type2:props.type2,
    //   //     type3:props.type3,
    //   //     test: final_test,
    //   //     name:test_name, 
    //   //     id:count
    //   // }
    //   // final_test_data.push(add_data);
    //   let tmp_final_data = props.test_data;
    //   tmp_final_data["300"] = tmp_list;
    //   props.settest_data(tmp_final_data);
    //   set(ref(db, 'DATA'),tmp_final_data);
    //   goToBack();
    // }
    const source = {
        html: props.data300
      };
      const { width } = useWindowDimensions();
      console.log("-->",props.data300name.substring(0,3));
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToBack}><Text style={{fontSize:16,marginBottom:10, fontWeight: 'bold'}}>Back</Text></TouchableOpacity>
            
            <Text>제목: {props.data300name}</Text>
            
            <RenderHtml
                contentWidth={width}
                source={source}
            />
            
            </View>
    );
 }
/*
<Text>출판 유무: {props.publish}</Text>
<TouchableOpacity onPress={modify}><Text style={{fontSize:16,marginBottom:10, fontWeight: 'bold'}}>수정</Text></TouchableOpacity>
<TouchableOpacity onPress={remove}><Text style={{fontSize:16,marginBottom:10, fontWeight: 'bold'}}>삭제</Text></TouchableOpacity>
<WebView
                originWhitelist={['*']}
                source={source}
            />
<RenderHtml
                contentWidth={width}
                source={source}
            />
 */
const styles = StyleSheet.create({
    container: {
        
     
      
        alignItems: 'center',
        justifyContent: 'center',
      
      width:'100%',
      
      paddingBottom:10,

    },
  })
  
export default DataWebViewPP;