import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const Pretest = props => {
    const goToAgenda = ()=>{
        props.onSetPre(16)
        props.onChange(0)
    }
    const goToContent1 = ()=>{
        props.onSetPre(16)
        //props.setP_name("TOLT EL1 기초논리 하양: 기본 개념, 추론 규칙")
        props.onChange(30)
    }

    const goToContent2 = ()=>{
        props.onSetPre(16)
        //props.setP_name("TOLT EL2 기초논리 노랑: 달리 쓰기, 문장 사이 관계")
        props.onChange(31)
    }

    const goToContent3 = ()=>{
        props.onSetPre(16)
        //props.setP_name("TOLT EL3 기초논리 파랑: 양화 논리, 논리 퍼즐")
        props.onChange(32)
    }

    const goToContent4 = ()=>{
        props.onSetPre(16)
        //props.setP_name("TOLT EL4 기초논리 빨강: 아마도 추론, 논증의 분석 및 평가")
        props.onChange(33)
    }

    const goToContent5 = ()=>{
        props.onSetPre(16)
        //props.setP_name("TOLT EL5 기초논리 검정: 믿음, 행위, 전략, 과학추론, 역설")
        props.onChange(34)
    }

    const goToContent6 = ()=>{
        props.onSetPre(16)
        //props.setP_name("TOLT EL6 기초논리 보라: 기초논리 전체")
        props.onChange(17)
    }

    const goToBack = ()=>{
        props.onSetPre(36)
        props.onChange(0)
        //props.onChange(props.backNumber)
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>모의 평가</Text>
            <TouchableOpacity><Text style={{ marginTop:10, fontSize:13}}>7급 언어 논리</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5, fontSize:13}}>5급 언어 논리</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5, fontSize:13}}>LEET 언어이해</Text></TouchableOpacity>
            <TouchableOpacity><Text style={{ marginTop:5, fontSize:13}}>LEET 추리논증</Text></TouchableOpacity>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default Pretest;