import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const Tolt040 = props => {
    const goToAgenda = ()=>{
        props.onSetPre(7)
        props.onChange(0)
    }
    const goToContent1 = ()=>{
        // props.onSetPre(7)
        // props.onChange(1)
        props.onSetPre(28)
        props.settype2("Q031")
        props.onChange(38)
    }

    const goToContent2 = ()=>{
        props.onSetPre(28)
        props.settype2("Q032")
        props.onChange(38)
    }

    const goToContent3 = ()=>{
        props.onSetPre(28)
        props.settype2("Q033")
        props.onChange(38)
    }

    const goToContent4 = ()=>{
        props.onSetPre(28)
        props.settype2("Q034")
        props.onChange(38)
    }

    const goToContent5 = ()=>{
        props.onSetPre(28)
        props.settype2("Q035")
        props.onChange(38)
    }

    const goToContent6 = ()=>{
        props.onSetPre(28)
        props.settype2("Q036")
        props.onChange(38)
    }

    const goToContent7 = ()=>{
        props.onSetPre(28)
        props.settype2("Q037")
        props.onChange(38)
    }

    const goToContent8 = ()=>{
        props.onSetPre(28)
        props.settype2("Q038")
        props.onChange(38)
    }

    const goToContent9 = ()=>{
        props.onSetPre(28)
        props.settype2("Q039")
        props.onChange(38)
    }

    const goToContent10 = ()=>{
        props.onSetPre(28)
        props.settype2("Q040")
        props.onChange(38)
    }

    const goToBack = ()=>{
        props.onSetPre(28)
        props.onChange(0)
        //props.onChange(props.backNumber)
    }
    return(
        <View style={styles.container}>
            
            <View style={styles.top3}>
            <Text style={{ marginTop:15,marginBottom:30 ,fontSize:40, fontWeight: 'bold'}}>TOLT 030</Text>
            
            <TouchableOpacity onPress={goToContent1}><Text style={{ marginTop:10, fontSize:20}}>Q031 아마도 추론</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent2}><Text style={{ marginTop:5, fontSize:20}}>Q032 믿음직함, 거짓 양성</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent3}><Text style={{ marginTop:5, fontSize:20}}>Q033 과학추론</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent4}><Text style={{ marginTop:5, fontSize:20}}>Q034 가설, 입증, 설명, 인과</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent5}><Text style={{ marginTop:5, fontSize:20}}>Q035 귀납, 믿음, 과학</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent6}><Text style={{ marginTop:5, fontSize:20}}>Q036 논증그림,논증분석</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent7}><Text style={{ marginTop:5, fontSize:20}}>Q037 논증 설계, 보완, 강화, 약화, 오류</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent8}><Text style={{ marginTop:5, fontSize:20}}>Q038 행위와 전략</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent9}><Text style={{ marginTop:5, fontSize:20}}>Q039 역설과 수수께끼</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent10}><Text style={{ marginTop:5, fontSize:20}}>Q040 비판과 전략</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToBack}><Text style={{ marginTop:100,fontWeight: 'bold', fontSize:20}}>처음으로</Text></TouchableOpacity>
            </View>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
    top3:{
        justifyContent:'center',
        alignItems:'flex-start',
        marginTop:5,
        marginLeft:40,
      },
  })
  
  export default Tolt040;
/*
<TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
<Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>TOLT 040</Text>
            <Text>TOLT 040은 문장 논리의 비판과 전략을 배우고 배운 바를 점검하는 시험입니다. TOLT 040는 10개의 시험 세트로 이뤄졌습니다.</Text>
  */