import { StatusBar } from 'expo-status-bar';
import { useState } from 'react';
import { StyleSheet, Text, View, FlatList,ScrollView,TouchableOpacity } from 'react-native';
import OneProblem from './oneProblem';
import OneText from './oneText';
import OneSlide from './oneSlide';

const text1 = "철학은 세계와 인간에 대한 가장 근본적 문제들을 이성적으로 탐구하는 학문입니다. 철학은 우리가 일상적 삶에서 당연하고 자명한 것으로 믿고 있는 전제들을 비판적으로 검토함으로써 우리의 삶에 대한 근본적 반성을 추구합니다. 철학은 또한 각 분과 학문에서 전제하고 있는 기본 개념과 원리들을 비판적으로 검토함으로써 개별 학문들의 토대에 대한 근본적 반성을 추구합니다. 철학이 모든 학문의 토대를 이루는 ‘근본학(根本學)’으로 불리는 이유가 여기에 있습니다. 더 나아가 철학은 각 분과 학문이 서로 어떤 관계를 맺고 있으며 이러한 관계를 통해 드러나는 세계 전체의 모습이 어떤 것인가에 대한 총체적 이해를 추구합니다."

const text2 = "철학의 분야들을 어떻게 구분할 것인가에 대해 정확한 합의가 이루어져 있지는 않습니다. 그러나 일반적으로 철학의 주요 네 분야로 논리학과 형이상학, 인식론, 그리고 윤리학을 꼽습니다. 논리학은 이성적 탐구 일반을 행함에 있어서 우리의 사고가 따라야 하는 법칙들이 무엇인지를 탐구합니다. 형이상학은 진정으로 존재하는 것들이 무엇이며 이들의 가장 보편적인 특성이 무엇인지를 탐구합니다. 인식론은 지식의 본성과 범위, 그리고 그 한계가 무엇인지를 탐구합니다. 윤리학은 우리 행위의 옳고 그름 및 좋고 나쁨을 결정할 수 있는 기준이 무엇이며 우리가 어떻게 살아가야 하는지를 탐구합니다. 그밖에도 언어, 마음, 과학, 사회, 역사, 문화 등의 철학적 토대를 탐구하는 언어철학, 심리철학, 과학철학, 사회철학, 역사철학, 문화철학 등의 분야가 있습니다."

const text3 = "지금까지 열거한 철학의 분야들은 해당 분야가 다루는 철학적 문제들의 성격에 의해 그 구분이 이루어진 것입니다. 그러나 다른 한편으로 철학의 분야들은 시대와 지역, 그리고 사조에 의해 구분되기도 합니다. 시대에 따라 고대철학과 중세철학, 근대철학과 현대철학이 구분되고, 지역에 따라 동양철학과 서양철학, 인도철학과 중국철학, 영미철학과 독일철학 및 프랑스철학이 구분되며, 학파에 따라 불교철학, 유가철학, 도가철학, 송명대신유학, 경험주의 철학, 이성주의 철학, 분석철학, 현상학, 프랑스철학 등이 구분됩니다."

const text4 = "시대와 지역 및 사조에 따른 이러한 철학 분야의 구분은 철학적 문제들에 대해 서로 다른 관점에서 접근하는 다양한 철학적 입장들이 있음을 반영한 것입니다. 이 중 현대의 철학 사조들을 제외한 모든 철학 사조들은 넓은 의미에서 철학사 분야에 속하는 것으로 분류될 수 있습니다. 근본학으로서의 철학은 모든 학문들 중 가장 오래된 역사를 지니고 있으며 따라서 그 어떤 학문보다도 풍부한 역사적 유산을 갖고 있습니다. 이렇게 풍부한 철학의 역사적 유산을 학문적으로 탐구하는 철학사 분야는 다음과 같은 이유에서 단지 지성사(知性史)적 중요성 뿐 아니라 철학적인 중요성을 갖고 있습니다."

const text5 = "철학의 각 분야에서 제기되는 철학적 문제들은 수천 년간의 장구한 논의에도 불구하고 그 해답이 무엇인지에 대해 합의가 이루어지지 않고 있습니다. 더 나아가 도대체 이 문제들이 해결될 수 있는 종류의 것들인지, 그리고 만약 그럴 수 있다면 어떠한 탐구 방법을 채택해야 하는지에 대해서도 합의가 이루어지지 않고 있습니다. 이러한 상황은 어쩌면 근본학으로서의 철학이 갖고 있는 숙명인 것처럼 보이기도 합니다. 철학이 일상생활 및 학문적 활동에 있어서 자명하게 받아들여지는 모든 전제들에 대해 근본적으로 반성해보는 작업이라면, 이러한 반성적 작업으로서의 철학은 궁극적으로 자기 자신의 정체성, 즉 철학의 정체성마저도 늘 반성의 대상으로 삼을 것이기 때문입니다. 그러나 비록 철학적 문제들에 대해 합의된 답이 존재하지는 않는다고 하더라도, 이 문제들에 대한 답을 찾고자 하는 사유를 전개하는데 있어서 가장 탁월한 수준의 독창성과 통찰력, 그리고 엄밀성과 설득력을 보여준 철학적 문헌들이 존재합니다. 이들이 바로 철학의 고전들이라고 일컬어지는 것입니다."

const text6 = "다시 말해서, 철학의 고전들은 오늘날에도 여전히 우리가 그 해답을 찾기 위해 고민하는 철학적 문제들에 대해 역사상 가장 뛰어난 철학적 사유의 전개 과정을 담고 있습니다. 철학사 연구를 통해 철학의 고전들에 담긴 이러한 사유의 전개 과정을 심층적으로 이해하는 것은, 과거의 철학적 이론에 대한 역사적 이해를 넘어 오늘날의 철학적 논의에 기여하기 위해서도 반드시 필요한 작업입니다. 이에 따라 서울대학교 철학과에서는 <형이상학>과 <인식론>, <윤리학>, <언어철학>, <과학철학>, <사회철학> 등 철학 분야의 구분에 따른 과목과 더불어 <서양고대철학>, <서양중세철학>, <서양근대철학>, <중국고대철학>, <인도불교철학>,<한국철학사>등 다양한 철학사 관련 과목을 개설하여 가르치고 있습니다."

const test = [
{
  "Number":1,
  "problem":"세상에서 가장 중요한 가치는 무엇인가?",
  "1":"사랑",
  "2":"자비",
  "3":"용서",
  "4":"도전",
  "5":"해탈"
},
{
  "Number":2,
  "problem":"문제를 해결하는 가장 좋은 방법론은?",
  "1":"뭐라도 해 무의식 놀이, 선 행동, 후 수정",
  "2":"슬로 싱킹, 아주 천천히 오래오래 생각하기",
  "3":"여러 단계로 쪼개라, 데카르트 방법론",
  "4":"가설 검증 놀이",
  "5":"운에 맡긴다."
},
{
  "Number":3,
  "problem":"마음 공부를 수행하는 가장 좋은 전략은?",
  "1":"아무런 제약 조건 없이 삶의 모든 것을 해석한다.",
  "2":"무조건 용서 전략",
  "3":"보살 놀이로 인간 관계를 살아간다.",
  "4":"모든 것을 사랑한다.",
  "5":"몰입/문제 놀이로 삶을 살아간다."
},
{
  "Number":4,
  "problem":"행복의 본질은 무엇인가?",
  "1":"있는 그대로 만족하는 것",
  "2":"몰입이 진정한 행복이다.",
  "3":"고통을 견디면서, 문제를 해결하는 삶이 행복이다.",
  "4":"조화와 균형이다.",
  "5":"매 순간 공자의 덕을 추구한다."
},
{
  "Number":5,
  "problem":"최고의 성공 전략은 무엇인가?",
  "1":"견딜 수 있는 고통을 견디면서, 꾸준히 노력하는 것",
  "2":"몰입을 선택하고, 그 몰입을 즐기는 것",
  "3":"행복을 통해서 성공을 성취한다.",
  "4":"꿈을 올바르게 선택하고, 할 수 있는 모든 노력 다 한다.",
  "5":"데카르트 쪼개라 전략으로 문제 해결에 도전한다."
}
]

const Content = props => {
  const [screen, setScreen] = useState(1);
  //let number = 0;
  const goToBack = ()=>{

    props.onChange(props.backNumber)
  }
  const setPrev = ()=>{
    if(screen > 1){
      //number = number - 1;
      setScreen(screen-1);
    }
    //setScreen(number);
    
  }

  const setNext = ()=>{
    if(screen < 5){
      //number = number + 1
      setScreen(screen+1);
    }
    //setScreen(screen);
    
  }
  console.log("test 0 >", test[0])
  console.log("test 1 >", test[1]["problem"])
  let theme;
  if(screen == 1){
    theme = "문제: "+screen + " 철학이란 무엇인가?";
  }
  if(screen == 2){
    theme = "문제: "+screen + " 과학이란 무엇인가?";
  }
  if(screen == 3){
    theme = "문제: "+screen + " 논리학이란 무엇인가?";
  }
  if(screen == 4){
    theme = "문제: "+screen + " 예술이란 무엇인가?";
  }
  if(screen == 5){
    theme = "문제: "+screen + " 논증이란 무엇인가?";
  }
  //theme = "문제: "+1 + " 논증이란 무엇인가?";
  //let theme = "문제: "+screen + " 철학이란 무엇인가?";
  let content2 = <OneSlide theme={theme} text1={text1} text2={text2} text3={text3} text4={text4} text5={text5} text6={text6} test={test}/>
      
  return (
    <View style={styles.container}>
        <TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
        
        <View style={{flexDirection:'row',width:300,justifyContent: 'space-between'}}>
        <TouchableOpacity onPress={setPrev}><Text style={{fontSize:16, fontWeight: 'bold'}}>prev</Text></TouchableOpacity>
        <TouchableOpacity onPress={setNext}><Text style={{fontSize:16, fontWeight: 'bold'}}>next</Text></TouchableOpacity>
      </View>
      
      <Text>[Problem] {screen} </Text>
      {content2}
      
      
      
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop:10,
    
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Content;
/*
<TouchableOpacity onPress={goToAgenda}><Text>Back</Text></TouchableOpacity>

<View style={{flexDirection:'row',width:"80%",justifyContent: 'space-between'}}>
        <TouchableOpacity onPress={()=>{}}><Text style={{fontSize:16, fontWeight: 'bold'}}>prev</Text></TouchableOpacity>
        <TouchableOpacity onPress={()=>{}}><Text style={{fontSize:16, fontWeight: 'bold'}}>next</Text></TouchableOpacity>
      </View>
      <Text>[Problem] 1 </Text>
      {content2}


<ScrollView  style={{width:"80%", height:"60%"}}>
      <OneText theme="철학이란 무엇인가?" text1={text1} text2={text2} text3={text3} text4={text4} text5={text5} text6={text6}/>
      <FlatList
        data={test}
        height={600}
        
        renderItem={({ item }) => <OneProblem number={item["Number"]} problem={item["problem"]} one={item["1"]} two={item["2"]} three={item["3"]} four={item["4"]} five={item["5"]}  />
          
            
        
          }
        keyExtractor={(item, index) => index.toString()}
            />
            </ScrollView>
*/
