import React from 'react';
import { useState,useEffect } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image } from 'react-native';

// const PP = (problem, answer) =>{
//     const [state, setState] = useState(answer);


//     useEffect(() => {
//         setState(answer);
//     }, [answer]);
//     return(
//         <View>
//             <Text>{problem} [{state}]</Text>
//         </View>
//     )
// }


const Note2 = props => {
    // const [answer, setanswer] = useState(props.answer);
    // useEffect(() => {
    //     if(props.answer != ""){
    //         setanswer(props.answer)
    //     } 
        
    // }, 
    // [props.answer] );
    //setInterval(()=>setValue(value+1),1000);
    // useEffect(() => {
    //     //setanswer(props.answer);
    //     if(props.answer != ""){
    //         //setInterval(() => {
    //         //setInitialValue("Changed value");
    //         setanswer(props.answer)
    //         //}, 1000);
    //    }
    //   }, [props.answer]);
    // useEffect(() => {
    //     setNum(count);
    //   }, [count]);

    // const set1 = ()=>{
    //     setanswer("1");
    //     props.set_answer(props.number-1,"1")
    //     if(props.redraw == "0"){
    //        props.setredraw("1");
    //     }else{
    //         props.setredraw("0");
    //     }
    //     //props.answer = "1";
    //     //this.setState({ answer: "1" });
    // }

    // const set2 = ()=>{
    //     setanswer("2");
    //     props.set_answer(props.number-1,"2")
    //     if(props.redraw == "0"){
    //         props.setredraw("1");
    //      }else{
    //          props.setredraw("0");
    //      }
    //     //props.answer = "2";
    //     //this.setState({ answer: "2" });
    // }

    // const set3 = ()=>{
    //     setanswer("3");
    //     props.set_answer(props.number-1,"3")
    //     if(props.redraw == "0"){
    //         props.setredraw("1");
    //      }else{
    //          props.setredraw("0");
    //      }
    //     //props.answer = "3";
    //     //this.setState({ answer: "3" });
    // }

    // const set4 = ()=>{
    //     setanswer("4");
    //     props.set_answer(props.number-1,"4")
    //     if(props.redraw == "0"){
    //         props.setredraw("1");
    //      }else{
    //          props.setredraw("0");
    //      }
    //     //props.answer = "4";
    //     //this.setState({ answer: "4" });
    // }

    
    // console.log("answer >", answer)
    let content;
    if(props.grade == 10){
         content = <TouchableOpacity onPress={props.next} ><Text style={{ marginTop:10,fontWeight: 'bold', fontSize:20}}>다음 물음</Text></TouchableOpacity>
    }else{
         content = <TouchableOpacity onPress={props.retry}><Text style={{ marginTop:10,fontWeight: 'bold', fontSize:20}}>다시 풀기</Text></TouchableOpacity>
    }
    return(
      <View style={styles.container}>
          <Text style={{fontSize:15, fontWeight: 'bold'}}>{props.problem}</Text>
          
          
          <View style={{ marginTop:10, marginBottom:30}}>
           <View style={styles.container2}>
           <Text style={{ marginTop:10, fontSize:15}}>{props.text}</Text>
           <Text style={{ marginTop:10,fontSize:15}}>{props.example1}</Text>
           <Text style={{ marginTop:2,fontSize:15}}>{props.example2}</Text>
          </View>
          </View>
          <Text style={{ marginTop:2, marginBottom:20,fontWeight: 'bold', color:'green', fontSize:15}} >정답 해설</Text>
         <TouchableOpacity ><Text style={{fontSize:15}}>{props.note2}</Text></TouchableOpacity>
         
         <View style={{justifyContent: 'flex-start', width:300, marginTop:50, alignItems:'center'}}>
          {content}
          </View>
         
      </View>
    )
  }
  
  const styles = StyleSheet.create({
    container: {
        
     
      
      justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
    container2: {
        
     
      
        justifyContent: 'flex-start',
        
        width:'100%',
        
        paddingBottom:10
      },
  })
  
  export default Note2;

/*
<Text>[{answer}], 최종 선택:[{props.answer}]</Text>
<Text>{props.problem} [{answer}]</Text>


<PP problem={props.problem} answer={props.answer}/>
  */