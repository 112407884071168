import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View,TouchableOpacity,ImageBackground,Button,Image,FlatList,ScrollView } from 'react-native';


const Tolt020data = props => {
    const goToAgenda = ()=>{
        props.onSetPre(7)
        props.onChange(0)
    }
    const goToContent1 = ()=>{
        // props.onSetPre(7)
        // props.onChange(1)
        props.onSetPre(31)
        props.setP_name("Q011 이고와 이거나의 뜻")
        //props.onChange(17)
        props.settype2("Q011")
        props.onChange(8)
    }

    const goToContent2 = ()=>{
        props.onSetPre(31)
        //props.onChange(6)
        props.setP_name("Q012 따라 나온다")
        //props.onChange(17)
        props.settype2("Q012")
        props.onChange(8)
    }

    const goToContent3 = ()=>{
        props.onSetPre(31)
        //props.onChange(6)
        props.setP_name("Q013 이면의 뜻")
        //props.onChange(17)
        props.settype2("Q013")
        props.onChange(8)
    }

    const goToContent4 = ()=>{
        props.onSetPre(31)
        //props.onChange(6)
        props.setP_name("Q014 달리 쓰기")
        //props.onChange(17)
        props.settype2("Q014")
        props.onChange(8)
    }

    const goToContent5 = ()=>{
        props.onSetPre(31)
        //props.onChange(6)
        props.setP_name("Q015 참값모눈과 달리 쓰기")
        //props.onChange(17)
        props.settype2("Q015")
        props.onChange(8)
    }

    const goToContent6 = ()=>{
        props.onSetPre(31)
        //props.onChange(6)
        props.setP_name("Q016 모순과 비일관 관계")
        //props.onChange(17)
        props.settype2("Q016")
        props.onChange(8)
    }

    const goToContent7 = ()=>{
        props.onSetPre(31)
        //props.onChange(6)
        props.setP_name("Q017 일관 관계")
        //props.onChange(17)
        props.settype2("Q017")
        props.onChange(8)
    }

    const goToContent8 = ()=>{
        props.onSetPre(31)
        //props.onChange(6)
        props.setP_name("Q018 형식 오류")
        //props.onChange(17)
        props.settype2("Q018")
        props.onChange(8)
    }

    const goToContent9 = ()=>{
        props.onSetPre(31)
        //props.onChange(6)
        props.setP_name("Q019 마땅함과 튼튼함")
        //props.onChange(17)
        props.settype2("Q019")
        props.onChange(8)
    }

    const goToContent10 = ()=>{
        props.onSetPre(31)
        //props.onChange(6)
        props.setP_name("Q020 문장사이관계")
        //props.onChange(17)
        props.settype2("Q020")
        props.onChange(8)
    }

    const goToBack = ()=>{
        props.onSetPre(31)
        props.onChange(16)
        //props.onChange(props.backNumber)
    }
    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={goToBack}><Text>Back</Text></TouchableOpacity>
            <Text style={{ marginTop:15,fontSize:16, fontWeight: 'bold'}}>TOLT 020(데이터 관리)</Text>
            <Text>TOLT 020은 문장 논리의 문장 규칙을 배우고 배운 바를 점검하는 시험입니다. TOLT 020는 10개의 시험 세트로 이뤄졌습니다.</Text>
            <TouchableOpacity onPress={goToContent1}><Text style={{ marginTop:10, fontSize:13}}>Q011 이고와 이거나의 뜻</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent2}><Text style={{ marginTop:5, fontSize:13}}>Q012 따라 나온다</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent3}><Text style={{ marginTop:5, fontSize:13}}>Q013 이면의 뜻</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent4}><Text style={{ marginTop:5, fontSize:13}}>Q014 달리 쓰기</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent5}><Text style={{ marginTop:5, fontSize:13}}>Q015 참값모눈과 달리 쓰기</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent6}><Text style={{ marginTop:5, fontSize:13}}>Q016 모순과 비일관 관계</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent7}><Text style={{ marginTop:5, fontSize:13}}>Q017 일관 관계</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent8}><Text style={{ marginTop:5, fontSize:13}}>Q018 형식 오류</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent9}><Text style={{ marginTop:5, fontSize:13}}>Q019 마땅함과 튼튼함</Text></TouchableOpacity>
            <TouchableOpacity onPress={goToContent10}><Text style={{ marginTop:5, fontSize:13}}>Q020 문장사이관계</Text></TouchableOpacity>
        </View>
      )
}

const styles = StyleSheet.create({
    container: {
        
     
      
        marginTop:10,
    
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
      
      width:'100%',
      
      paddingBottom:10
    },
  })
  
  export default Tolt020data;